import React from "react"
import { connect } from "react-redux"
import tw from "tailwind.macro"
import styled from "@emotion/styled"
import ReactTable from "react-table";
import "react-table/react-table.css";
import { css } from "@emotion/core"
import { fetchPaymentsForBusiness } from "../actions/userAction"
import SliderModal from "./SliderModal"
import Overlay from "../components/Overlay"
import Moment from "moment"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FileDownload from 'js-file-download';
import { parse } from 'json2csv';

const Wrapper = tw.div`
  hidden md:flex lg:flex relative w-full flex-col bg-white p-4 md:px-8 lg:px-8
`

class PaymentsPortal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showDetails: false,
            showOverlay: false,
            selectedPayment: {},
            startDate: Moment().subtract(1, "month"),
            endDate: Moment(),
            payments: []
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.payments != this.props.payments){
            this.setState({ payments: this.props.payments})
        }
    }

    filterCaseInsensitive = (filter, row) => {
        console.log(filter);
        const id = filter.pivotId || filter.id;
        let value = filter.value;
        console.log(value);
        if (typeof row[id] == 'string') {
            console.log(id);
            if (row[id] !== undefined) {
                return String(row[id].toLowerCase()).includes(value.toLowerCase());
            }
            else {
                return true;
            }
        }
        else {

            if (row[id] !== undefined) {
                return String(row[id]).includes(value.toString());
            }
            else {
                return true
            }
        }
    }

    // handleRowClicked = (rowInfo) => {
    //     window.document.getElementsByTagName("body")[0].style.overflow = "hidden"
    //     this.setState({ selectedPayment: rowInfo.original, showDetails: true, showOverlay: true })
    // }

    handleClose = () => {
        window.document.getElementsByTagName("body")[0].style.overflow = "auto"
        this.setState({ showOverlay: false, showDetails: false })
    }

    handleStartDateChange = (date) => {
        console.log(date)
        this.setState({ startDate: Moment(date) })
    }

    handleEndDateChange = (date) => {
        console.log(date)
        this.setState({ endDate: Moment(date) })
    }

    handleFetch = () => {
        let data = {
            startDate: Moment(this.state.startDate, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD 00:00:00"),
            endDate: Moment(this.state.endDate, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD 00:00:00")
        }
        this.props.dispatch(fetchPaymentsForBusiness(data));
    }

    handleDownload = () => {
        if(this.state.payments.length > 0){
            let paymentsData = [];
            this.state.payments.map( payment => {
                let data = {
                    "Booking Id": Number(payment.bidId),
                    "Ride End Date": Moment(payment.rideEndDate).format("DD MMM, YYYY hh:mm A"),
                    "Amount": "₹" + Math.round(payment.totalBillAmount).toLocaleString(),
                    "Traveller Name": payment.travelerName,
                    "Traveller Contact": payment.travelerContactNo,
                    "From": payment.fromCity,
                    "To": payment.toCity,
                    "Ride Status": payment.hasOwnProperty("rideStatusDescription") ? payment.rideStatusDescription : ""
                }
                paymentsData.push(data);
            })
            FileDownload(parse(paymentsData), `Payments${"_" + Moment(this.state.startDate).format("DD MMM, YYYY") + "-" + Moment(this.state.endDate).format("DD MMM, YYYY")}.csv`)
        }
        else{
            alert("No payments available for the given period")
        }
    }

    render() {
        return (
            <Wrapper>
                <h1 css={css`${tw`font-bold text-black text-lg py-4`}`}>Payments</h1>
                <div css={css`${tw`flex w-full font-normal text-black text-base py-4 items-center`}`}>
                <p css={css`${tw`font-medium`}`}>From</p>
                <DatePicker
                        selected={Moment(this.state.startDate).toDate()}
                        onChange={date => this.handleStartDateChange(date)}
                        selectsStart
                        startDate={Moment(this.state.startDate).toDate()}
                        endDate={Moment(this.state.endDate).toDate()}
                        dateFormat="MMMM d, yyyy"
                        customInput={<CalendarInput />}
                        tabIndex={1}
                        css={css`${tw`shadow-lg p-2`}`}
                    />
                    <p css={css`${tw`font-medium ml-4`}`}>To</p>
                    <DatePicker
                            selected={Moment(this.state.endDate).toDate()}
                            onChange={date => this.handleEndDateChange(date)}
                            selectsEnd
                            startDate={Moment(this.state.startDate).toDate()}
                            endDate={Moment(this.state.endDate).toDate()}
                            minDate={Moment(this.state.startDate).toDate()}
                            dateFormat="MMMM d, yyyy"
                            customInput={<CalendarInput />}
                            tabIndex={1}
                            css={css`${tw`shadow-lg p-2`}`}
                        />
                        <div css={css`${tw`font-medium ml-4 text-white py-2 px-4 shadow-lg cursor-pointer`} background-color: #8a3df6;`} onClick={this.handleFetch}>Search</div>
                        <img src={require("../images/download-icon.png")} css={css`${tw`absolute h-8 w-8 cursor-pointer opacity-50`} right: 2rem;`} onClick={this.handleDownload} />
                </div>
                <ReactTable
                    data={this.state.payments}
                    style={{ cursor: 'pointer', width: '100%' }}
                    filterable
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: (e, handleOriginal) => {
                                if (rowInfo != undefined) {
                                    // this.handleRowClicked(rowInfo)
                                }
                                console.log("A Td Element was clicked!");
                                console.log("it produced this event:", e);
                                console.log("It was in this column:", column);
                                console.log("It was in this row:", rowInfo);
                                console.log("It was in this table instance:", instance);

                                // IMPORTANT! React-Table uses onClick internally to trigger
                                // events like expanding SubComponents and pivots.
                                // By default a custom 'onClick' handler will override this functionality.
                                // If you want to fire the original onClick handler, call the
                                // 'handleOriginal' function.
                                if (handleOriginal) {
                                    handleOriginal();
                                }
                            }
                        };
                    }}
                    columns={[
                        {
                            Header: "Booking Id",
                            accessor: "bidId",
                            style: {textAlign: "center"},
                        },
                        {
                            Header: "Ride Date",
                            id:2,
                            accessor: (data) => (Moment(data.rideStartDate,"YYYY-MM-DD HH:mm:ss").format("DD MMM") + " - " + Moment(data.rideEndDate,"YYYY-MM-DD HH:mm:ss").format("DD MMM")),
                            style: {textAlign: "center"},
                        },
                        // {
                        //     Header: "Transaction Id",
                        //     accessor: "dummy",
                        // },
                        {
                            Header: "Traveller Name",
                            accessor: "travelerName",
                            style: {textAlign: "center"},
                        },
                        {
                            Header: "Traveller Contact",
                            accessor: "travelerContactNo",
                            style: {textAlign: "center"},
                        },
                        {
                            Header: "From",
                            accessor: "fromCity",
                            style: {textAlign: "center"},
                        },
                        // {
                        //     Header: "To",
                        //     accessor: "toCity",
                        //     style: {textAlign: "center"},
                        // },
                        {
                            Header: "Amount",
                            id: 1,
                            accessor: (data) => (data.hasOwnProperty("totalBillAmount") ? "₹" + Math.round(data.totalBillAmount).toLocaleString() : "₹0"),
                            style: {textAlign: "center"},
                        },
                        {
                            Header: "Status",
                            accessor: "rideStatusDescription",
                            style: {textAlign: "center"},
                        },
                    ]}
                    className="-striped -highlight"
                    defaultPageSize={20}
                    defaultFilterMethod={this.filterCaseInsensitive}
                />
                {/* DO NOT MOVE OVERLAY COMPONENT */}
                <Overlay active={this.state.showOverlay} overlayClicked={this.handleClose} />
                {this.state.showDetails && <SliderModal active={this.state.showDetails} heading={"#" + "PaymentId"} close={this.handleClose}>
                    {/* <div css={css`${tw`flex flex-wrap w-full items-start`}`}>
                        <div css={css`${tw`lg:w-1/2 md:w-1/2 w-full mt-2`}`}>
                            <h4 css={css`${tw`mt-4 text-lg font-bold`}`}>Ride Details</h4>
                            <ul css={css`${tw`leading-loose text-black mt-4`}`}>
                                <li css={css`${tw`font-medium text-base`}`}>Ride Status : Completed</li>
                                <li css={css`${tw`text-base opacity-75`}`}>Trip Type: {this.state.selectedRide.tripType} </li>
                                <li css={css`${tw`text-base opacity-75`}`}>From: {this.state.selectedRide.fromCity}</li>
                                <li css={css`${tw`text-base opacity-75`}`}>To: Bangalore </li>
                                <li css={css`${tw`text-base opacity-75`}`}>Start Date: {Moment(this.state.selectedRide.startDate, "DD-MM-YYYY HH:mm:ss").format("DD MMM, YYYY hh:mm A")} </li>
                                <li css={css`${tw`text-base opacity-75`}`}>End Date: 24 May, 2000 03:00 AM </li>
                                <li css={css`${tw`text-base opacity-75`}`}>Vehicle: {this.state.selectedRide.carType} </li>
                            </ul>
                        </div>
                        <div css={css`${tw`lg:w-1/2 md:w-1/2 w-full mt-2`}`}>
                            <h4 css={css`${tw`mt-4 text-lg font-bold`}`}>Traveller Details </h4>
                            <ul css={css`${tw`leading-loose text-black mt-4`}`}>
                                <li css={css`${tw`font-medium text-base`}`}>Name: Syed</li>
                                <li css={css`${tw`font-medium text-base`}`}>Phone: <a href={"tel:+91" + "9573606036"}>9573606036</a></li>
                                <li css={css`${tw`font-medium text-base`}`}>Email: <a href={"mailto:" + "syed@zyppys.com"}>syed@zyppys.com</a></li>
                            </ul>
                        </div>
                    </div> */}
                </SliderModal>}
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    payments: state.user.payments
})

export default connect(mapStateToProps)(PaymentsPortal)

const CalendarInput = ({ value, onClick }) => (
    <div css={css`${tw`ml-4 shadow-lg py-2 px-4 text-center border border-gray-200`}`} className="example-custom-input" onClick={onClick}>
      {value}
    </div>
  );