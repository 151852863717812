import tw from "tailwind.macro"
import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { css } from '@emotion/core'
import Moment from "moment"

const Wrapper = styled.div`
 ${tw`content-center w-full bg-white border border-gray-500 border-solid rounded-lg shadow-2xl cursor-pointer p-4`}
`

class PaymentCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <Wrapper>
            <p css={css`${tw`font-medium text-center text-base py-2 bg-purple-500 text-white`}`}>Booking Id - 0000</p>
            <p css={css`${tw`font-medium text-sm py-1 mt-2`}`}>Status: </p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Base Fare: </p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Driver Charges: </p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Service Tax: </p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Night Charges: </p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Final Pay: </p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Vendor Pay: </p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Zyppys Margin: </p>
            {/* <p css={css`${tw`font-normal text-sm py-1`}`}>To: </p> */}
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(PaymentCard);
