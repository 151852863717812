import React from "react"
import { connect } from "react-redux"
import tw from "tailwind.macro"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { fetchPaymentsForBusiness } from "../actions/userAction"
import SliderModal from "./SliderModal"
import Overlay from "../components/Overlay"
import Moment from "moment"
import PaymentCard from "../components/PaymentCard"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FileDownload from 'js-file-download';
import { parse } from 'json2csv';

const Wrapper = styled.div`
  ${tw`flex md:hidden lg:hidden relative w-screen h-screen flex-col p-8`}
  ${'' /* background: #e7ebef; */}
`
const SearchImage = styled.img`
${tw`absolute h-5 opacity-50`}
right: 1rem;
top: 1rem;
`

class PaymentsMobile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showDetails: false,
            showOverlay: false,
            payments: [],
            startDate: Moment().subtract(1, "month"),
            endDate: Moment(),
            isPaymentsLoading: true,
            isPaymentsAvailable: false
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.payments != this.props.payments){
            this.setState({ payments: this.props.payments})
        }
        if(prevProps.isPaymentsAvailable != this.props.isPaymentsAvailable){
            this.setState({ isPaymentsAvailable: this.props.isPaymentsAvailable })
        }
        if(prevProps.isPaymentsLoading != this.props.isPaymentsLoading){
            this.setState({ isPaymentsLoading: this.props.isPaymentsLoading })
        }
    }

    filterCaseInsensitive = (filter, row) => {
        console.log(filter);
        const id = filter.pivotId || filter.id;
        let value = filter.value;
        console.log(value);
        if (typeof row[id] == 'string') {
            console.log(id);
            if (row[id] !== undefined) {
                return String(row[id].toLowerCase()).includes(value.toLowerCase());
            }
            else {
                return true;
            }
        }
        else {

            if (row[id] !== undefined) {
                return String(row[id]).includes(value.toString());
            }
            else {
                return true
            }
        }
    }

    handleCardClicked = (payment) => {
        window.document.getElementsByTagName("body")[0].style.overflow = "hidden"
        this.setState({ selectedRide: payment, showDetails: true, showOverlay: true })
    }

    handleClose = () => {
        window.document.getElementsByTagName("body")[0].style.overflow = "auto"
        this.setState({ showOverlay: false, showDetails: false })
    }
    
    handleSearch = (value) => {
        console.log(value);
        if(isNaN(value)){
            window.document.getElementById("search").style.border = "thin solid #FF0000"
        }
        else{
            window.document.getElementById("search").style.border = "thin solid #00FF00"
            this.setState({ payments: this.props.payments.filter( b => (b.bidId.toString().includes(value)))})
        }
    }

    handleStartDateChange = (date) => {
        console.log(date)
        this.setState({ startDate: Moment(date) })
    }

    handleEndDateChange = (date) => {
        console.log(date)
        this.setState({ endDate: Moment(date) })
    }

    handleFetch = () => {
        let data = {
            startDate: Moment(this.state.startDate, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD 00:00:00"),
            endDate: Moment(this.state.endDate, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD 00:00:00")
        }
        this.props.dispatch(fetchPaymentsForBusiness(data));
    }

    handleDownload = () => {
        if(this.state.payments.length > 0){
            let paymentsData = [];
            this.state.payments.map( payment => {
                let data = {
                    "Booking Id": Number(payment.bidId),
                    "Ride End Date": Moment(payment.rideEndDate).format("DD MMM, YYYY hh:mm A"),
                    "Amount": "₹" + Math.round(payment.totalBillAmount).toLocaleString(),
                    "Traveller Name": payment.travelerName,
                    "Traveller Contact": payment.travelerContactNo,
                    "From": payment.fromCity,
                    "To": payment.toCity,
                    "Ride Status": payment.hasOwnProperty("rideStatusDescription") ? payment.rideStatusDescription : ""
                }
                paymentsData.push(data);
            })
            FileDownload(parse(paymentsData), `Payments${"_" + Moment(this.state.startDate).format("DD MMM, YYYY") + "-" + Moment(this.state.endDate).format("DD MMM, YYYY")}.csv`)
        }
        else{
            alert("No payments available for the given period")
        }
    }

    render() {
        return (
            <Wrapper>
                <h1 css={css`${tw`font-bold text-black text-lg py-2`}`}>Payments</h1>
                <div css={css`${tw`block w-full font-normal text-black text-sm py-4`}`}>
                <div css={css`${tw`flex`}`}>
                <div css={css`${tw`block items-center py-2`}`}>
                <p css={css`${tw`font-medium`}`}>From</p>
                <DatePicker
                        selected={Moment(this.state.startDate).toDate()}
                        onChange={date => this.handleStartDateChange(date)}
                        selectsStart
                        startDate={Moment(this.state.startDate).toDate()}
                        endDate={Moment(this.state.endDate).toDate()}
                        dateFormat="MMMM d, yyyy"
                        customInput={<CalendarInput />}
                        tabIndex={1}
                        css={css`${tw`shadow-lg p-2`}`}
                    />
                    </div>
                    <div css={css`${tw`block items-center py-2 ml-4`}`}>
                    <p css={css`${tw`font-medium`}`}>To</p>
                    <DatePicker
                            selected={Moment(this.state.endDate).toDate()}
                            onChange={date => this.handleEndDateChange(date)}
                            selectsEnd
                            startDate={Moment(this.state.startDate).toDate()}
                            endDate={Moment(this.state.endDate).toDate()}
                            minDate={Moment(this.state.startDate).toDate()}
                            dateFormat="MMMM d, yyyy"
                            customInput={<CalendarInput />}
                            tabIndex={1}
                            css={css`${tw`shadow-lg p-2`}`}
                        />
                        </div>
                        </div>
                        <div css={css`${tw`flex items-center py-2`}`}>
                        <div css={css`${tw`font-medium text-white py-2 px-2 shadow-lg cursor-pointer`} background-color: #8a3df6;`} onClick={this.handleFetch}>Search</div>
                        <img src={require("../images/download-icon.png")} css={css`${tw`absolute h-8 w-8 cursor-pointer opacity-50`} right: 2rem`} onClick={this.handleDownload}/>
                        </div>
                </div>
                {/* <div css={css`${tw`flex w-full py-2 relative`}`}>
                <input type="text" placeholder="#booking id" id="search" onChange={(e)=>this.handleSearch(e.target.value)} css={css`${tw`flex w-full p-2 rounded-full bg-white border-gray-500 border appearance-none outline-none shadow-2xl`}`} />
                <SearchImage src={require("../images/search.png")} />
                </div> */}
                {!this.state.isPaymentsAvailable && !this.state.isPaymentsLoading && <p css={css`${tw`font-medium text-center self-center text-black text-lg py-4`}`}>No payments available for the selected period</p>}
                {this.state.isPaymentsAvailable && !this.state.isPaymentsLoading && this.state.payments.map(payment => (<div css={css`${tw`py-4`}`}>
                    <PaymentCard payment={payment} cardPressed={(payment)=>{this.handleCardClicked(payment)}} />
                    </div>
                ))}
                {/* DO NOT MOVE OVERLAY COMPONENT */}
                <Overlay active={this.state.showOverlay} overlayClicked={this.handleClose} />
                {this.state.showDetails && <SliderModal active={this.state.showDetails} heading={"#" + "PaymentId"} close={this.handleClose}>
                    {/* <div css={css`${tw`flex flex-wrap w-full items-start`}`}>
                        <div css={css`${tw`lg:w-1/2 md:w-1/2 w-full mt-2`}`}>
                            <h4 css={css`${tw`mt-4 text-lg font-bold`}`}>Ride Details</h4>
                            <ul css={css`${tw`leading-loose text-black mt-4`}`}>
                                <li css={css`${tw`font-medium text-base`}`}>Ride Status : Completed</li>
                                <li css={css`${tw`text-base opacity-75`}`}>Trip Type: {this.state.selectedRide.tripType} </li>
                                <li css={css`${tw`text-base opacity-75`}`}>From: {this.state.selectedRide.fromCity}</li>
                                <li css={css`${tw`text-base opacity-75`}`}>To: Bangalore </li>
                                <li css={css`${tw`text-base opacity-75`}`}>Start Date: {Moment(this.state.selectedRide.startDate, "DD-MM-YYYY HH:mm:ss").format("DD MMM, YYYY hh:mm A")} </li>
                                <li css={css`${tw`text-base opacity-75`}`}>End Date: 24 May, 2000 03:00 AM </li>
                                <li css={css`${tw`text-base opacity-75`}`}>Vehicle: {this.state.selectedRide.carType} </li>
                            </ul>
                        </div>
                        <div css={css`${tw`lg:w-1/2 md:w-1/2 w-full mt-2`}`}>
                            <h4 css={css`${tw`mt-4 text-lg font-bold`}`}>Traveller Details </h4>
                            <ul css={css`${tw`leading-loose text-black mt-4`}`}>
                                <li css={css`${tw`font-medium text-base`}`}>Name: Syed</li>
                                <li css={css`${tw`font-medium text-base`}`}>Phone: <a href={"tel:+91" + "9573606036"}>9573606036</a></li>
                                <li css={css`${tw`font-medium text-base`}`}>Email: <a href={"mailto:" + "syed@zyppys.com"}>syed@zyppys.com</a></li>
                            </ul>
                        </div>
                    </div> */}
                </SliderModal>}
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    payments: state.user.payments,
    isPaymentsAvailable: state.user.isPaymentsAvailable,
    isPaymentsLoading: state.user.isPaymentsLoading
})

export default connect(mapStateToProps)(PaymentsMobile)

const CalendarInput = ({ value, onClick }) => (
    <div css={css`${tw`flex w-full flex-row shadow-lg py-2 px-2 text-center border border-gray-200`}`} className="example-custom-input" onClick={onClick}>
      {value}
    </div>
  );