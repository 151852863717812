import React from 'react';
import { css, Global } from '@emotion/core'
import { Spring, Transition, animated } from 'react-spring/renderprops';
import tw from "tailwind.macro"
import styled from "@emotion/styled"

const styles = css`
.wrapper{
    ${tw`fixed bottom-0 mx-auto z-50 overflow-hidden`}
        background: white;
        border-radius: 2rem 2rem 0 0;
        @media(min-width: 768px){
            border-radius: 2rem 2rem 0 0;
            min-width: 60%;
            max-width: 60%;
            left: 3%;
            
        }
        width: 90%;
        max-height: 85vh;
        height: 85vh;
        left: 5%;
        opacity: 1;
}
`;

const HeaderWrapper = styled.div`
${tw`flex items-center justify-between p-8 w-full border-b border-gray-300`}
`
const Heading = styled.h1`
${tw`text-lg font-bold text-gray-500`}
`
const CloseSVG = styled.div`
${tw`cursor-pointer`}
`
const ChildrenWrapper = styled.div`
${tw`flex flex-wrap p-4 overflow-y-scroll pb-10`}
height: 55vh;
max-height: 55vh;
`

const ReviewSlider = (props) => (
    <Spring native
        onStart={props.onStart}
        from={{ y: 25 }}
        to={{ y: props.active ? 0 : 100 }}>
        {({ y }) =>

            <animated.div
                className="wrapper"
                style={{
                    transform: y.interpolate(y => `translateY(${y}vh)`),
                    marginBottom: props.placesModal ? 0 : 0
                }}
                onClick={e => e.stopPropagation()}>
                <Global styles={styles} />
                <div css={css`${tw`h-full w-full relative z-0 overflow-hidden`};`}>
                    <HeaderWrapper>
                        <Heading>{props.vendor.sub_category}</Heading>
                        <CloseSVG onClick={props.close}><img src={require("../svgs/collapse.svg")} alt="collapse" /></CloseSVG>
                    </HeaderWrapper>
                    <ChildrenWrapper>
                        {props.children}
                    </ChildrenWrapper>
                    {props.hasDone &&
                        <div
                            onClick={props.onDone}
                            css={css`${tw`inline-block w-4/5 mx-auto text-center rounded-full absolute z-10 py-4 px-4 text-base text-white font-medium cursor-pointer overflow-hidden truncate shadow-2xl`}; background-image: linear-gradient(115deg, #AA0BCA 0%, #7D0EDE 39%); bottom: 5%; left:10%;`}>

                            &#8377; {Math.round(props.vendor.ride_amt).toLocaleString()} | Continue with {props.vendor.sub_category}
                        </div>
                    }
                </div>
            </animated.div>
        }
    </Spring>
);

export default ReviewSlider;