import tw from "tailwind.macro"
import React from "react"
import SEO from "../components/seo"
import LoginForm from "../components/LoginForm"
import Footer from "../components/Footer"
import Header from "../components/Header"
import { connect } from "react-redux"

const Wrapper = tw.div`
  flex items-center relative justify-center w-full flex-col bg-white
`

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {
    return (
      <Wrapper>
        <SEO title="Login" />
        <Header showRegister />
        <LoginForm />
        <Footer addMargin />
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(Login);