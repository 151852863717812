import tw from "tailwind.macro"
import React from "react"
import styled from "@emotion/styled"
import fancyCurves from "../svgs/fancy-curves-divider-down.svg"
import { css } from '@emotion/core'

const Wrapper = styled.div`
  ${tw`flex items-center relative justify-between flex-col w-full h-auto sm:h-auto md:h-auto py-16 sm:py-16 md:py-16 lg:py-0 px-5 sm:px-10 md:px-10 lg:px-20 xl:px-20`}
  background: black;
        background-size: cover;
        background-repeat: no-repeat;
        ::before{
            content: "";
            display: block;
            position: absolute;
            top: -9.9rem;
            height: 10rem;
            width: 100%;
            background-image: url(${fancyCurves});
            background-size: cover;
            background-repeat: no-repeat;
            transform: rotate(180deg);
        }
`
const ContainerRow = tw.div`
  block md:flex lg:flex items-center justify-start w-full px-2 md:px-10 lg:px-10 py-5 pb-2 sm:pb-2 md:pb-20 lg:pb-20
`
const ContainerColumn = tw.div`
  block items-center justify-start w-full sm:w-full md:w-1/2 lg:w-1/2 px-5 lg:px-10 pb-10 sm:pb-10 md:pb-10 lg:pb-20
`
const LogoContainer = tw.div`
  flex items-center justify-start w-full
`
const Text = tw.p`
  ml-1 mb-0 sm:mb-0 md:mb-0 lg:mb-1 text-gray-600 text-lg lg:text-3xl font-medium
`
const SocialMedia = tw.div`
  flex items-center justify-between self-start w-full sm:w-full md:w-1/2 lg:w-1/2 py-5 opacity-75
`
const SocialMediaLogo = tw.img`
h-10 
`
const Link = styled.a`
  ${tw`flex items-center no-underline text-white text-xs sm:text-sm md:text-base cursor-pointer lg:text-base font-medium py-2`}
`
const FooterBottom = styled.div`
${tw`flex absolute items-center text-center justify-center w-full px-2 lg:px-10 `}
bottom:1rem;
`
const SmallText = tw.div`
flex text-xs sm:text-xs md:text-sm lg:text-sm text-gray-500
`
const DetailsTab = tw.div`
flex justify-between opacity-75
`
const Head = tw.h1`
text-white text-sm sm:text-base md:text-lg lg:text-lg font-bold py-2
`
const Column = tw.div`
block
`

export default (props) => (
  <Wrapper css={css`${props.addMargin || props.mobileView ? tw`mt-0` : tw`mt-48` }`}>
  <ContainerRow>
    <ContainerColumn>
    <LogoContainer>
      <img
        css={css`${tw`h-12 lg:h-16`}`}
        src={require('../svgs/logo-with-light-text.svg')}
        alt="Zyppys Logo" />
      <Text>business</Text>
      </LogoContainer>
      <SocialMedia>
      <Link href="https://www.instagram.com/zyppyscarrental" target="_blank">
        <SocialMediaLogo src={require('../svgs/instagram.svg')} alt="Instagram Logo" />
      </Link>
      <Link href="https://www.facebook.com/zyppys/" target="_blank">
        <SocialMediaLogo src={require('../svgs/facebook.svg')} alt="Facebook Logo" />
      </Link>
      <Link href="https://www.twitter.com/zyppys" target="_blank">
        <SocialMediaLogo src={require('../svgs/twitter.svg')} alt="Twitter Logo" />
      </Link>
    </SocialMedia>
    </ContainerColumn>
    <ContainerColumn>
    <DetailsTab>
        <Column>
          <Head>Products</Head>
          <Link href="https://www.zyppys.com/">Consumer</Link>
          <Link href="https://business.zyppys.com/">Business</Link>
          <Link href="https://www.zyppys.com/partnerWithUs">Partners</Link>
        </Column>
        <Column>
          <Head>Company</Head>
          <Link href="https://www.zyppys.com/aboutus">About Us</Link>
          <Link href="https://www.zyppys.com/blogs">Blog</Link>
          {/* <Link>Press</Link> */}
        </Column>
        <Column>
          <Head>Support</Head>
          <Link href="https://www.zyppys.com/faq">FAQs</Link>
          <Link href="tel:+918688901902">Contact Us</Link>
          <Link href="mailto:support@zyppys.com">Feedback</Link>
        </Column>
    </DetailsTab>
    </ContainerColumn>
    </ContainerRow>
    <FooterBottom>
      <SmallText>Copyrights &copy; 2020 Twilight IT Professionals Pvt Ltd</SmallText>
</FooterBottom>
  </Wrapper>
)
