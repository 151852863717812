import tw from "tailwind.macro"
import React from "react"
import SEO from "../../components/seo"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import BookingsPortal from "../../components/PartnerComponents/BookingsPortal"
import BookingsMobile from "../../components/PartnerComponents/BookingsMobile"
import { connect } from "react-redux"
import Menu from "../../components/Menu"
import Overlay from "../../components/Overlay"
import styled from "@emotion/styled"
import { isMobile } from "react-device-detect"
import Loader from 'react-loader-spinner'
import { css } from '@emotion/core'
import { fetchPartnerBookings, fetchDriversForBusiness, fetchProductBusiness } from "../../actions/userAction"

const Wrapper = styled.div`
  ${tw`flex items-center relative justify-center w-full flex-col bg-white`}
`
const LoaderContainer = styled.div`
  ${tw`justify-center h-full w-full bg-black left-0 fixed opacity-50`}
  top: 5.0rem;
  @media (min-width: 992px){
    top: 0rem;
  }
`

class PartnerBookings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
      showOverlay: false,
      isPartnerBookingsLoading: true
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.isPartnerBookingsLoading != this.props.isPartnerBookingsLoading){
      this.setState({ isPartnerBookingsLoading: this.props.isPartnerBookingsLoading})
    }
    if (prevProps.userDetails != this.props.userDetails) {
      this.props.dispatch(fetchPartnerBookings());
      this.props.dispatch(fetchProductBusiness())
      this.props.dispatch(fetchDriversForBusiness())
    }
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu, showOverlay: !this.state.showOverlay })
  }

  render() {
    if (typeof window != `undefined`) {
      if (this.state.isPartnerBookingsLoading) {
        window.document.getElementsByTagName("body")[0].style.overflow = "hidden";
      }
      else {
        window.document.getElementsByTagName("body")[0].style.overflow = "auto";
      }
    }
    return (
      <Wrapper>
        <SEO title="Partner Booking" />
        <Header shadow partner dark openMenu={this.toggleMenu} />
        {this.state.isPartnerBookingsLoading && <LoaderContainer style={{ zIndex: 1000 }} css={css`${tw``} display: ${this.state.isPartnerBookingsLoading ? "flex !important" : "none !important"};`}>
          <div css={css`${tw`self-center`}`}>
            <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
            />
          </div>
        </LoaderContainer>}
        <BookingsPortal />
        <BookingsMobile />
        {/* <Footer /> */}
        {/* DO NOT MOVE OVERLAY COMPONENT */}
        <Overlay active={this.state.showOverlay} overlayClicked={() => { this.setState({ showOverlay: false, showMenu: false }) }} />
        {this.state.showMenu && <Menu partner openMenu={this.toggleMenu} />}
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  isPartnerBookingsLoading: state.user.isPartnerBookingsLoading,
  userDetails: state.user.userDetails
})

export default connect(mapStateToProps)(PartnerBookings);