import tw from "tailwind.macro"
import React from "react"
import styled from "@emotion/styled"

const Wrapper = styled.div`
${tw`fixed w-full h-full z-50 bg-gray-800 opacity-75 top-0 left-0`}
`
export default (props) => (
    <Wrapper style={{ display:(props.active ? 'block' : 'none')}} onClick={props.overlayClicked}>

    </Wrapper>
)