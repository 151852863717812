import tw from "tailwind.macro"
import React from "react"
import { connect } from "react-redux"
import { css } from '@emotion/core'
import CarCard from "../components/CarCard"

const Wrapper = tw.div`
 block p-4 w-full pb-10
`
const SoldOutWrapper = tw.div`
flex w-full md:w-2/3 lg:w-2/3 h-screen absolute top-0 left-0 px-8 items-center justify-center content-center self-center text-center
`

class CarsList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {

        let ride = this.props.ride;
        let app = this.props.app;
        return (
            <Wrapper>
            {!ride.carsLoading && app.isCarsAvailable && app.cars.length > 0 && app.cars.map((car, i) => {
                return(<div css={css`${tw`mt-8`}`}>
                <CarCard car={car} vendor={car.vendors[0]} selectedCard={(vendor) => {this.props.showSlider(vendor)}} />
                </div>)
            })}
            {!ride.carsLoading && !app.isCarsAvailable && <SoldOutWrapper>
            <p css={css`${tw`text-xl text-black font-bold`}`}>Oops, Looks like we are sold out!</p>
            </SoldOutWrapper>}
            {!ride.carsLoading && !app.isCarsAvailable && <SoldOutWrapper>
            <p css={css`${tw`text-xl text-black font-bold`}`}>Oops, Looks like we are sold out!</p>
            </SoldOutWrapper>}
            {ride.carsLoading && <SoldOutWrapper>
            <p css={css`${tw`text-xl text-black font-bold`}`}>Loading Cars...</p>
            </SoldOutWrapper>}
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    ride: state.ride,
    app: state.app
})

export default connect(mapStateToProps)(CarsList);
