import Moment from 'moment';



let date = Moment();
let minutes = 30 - (date.minute() % 30);
let chauffeurStartDate = Moment().add(minutes,'minutes').seconds(0).format("DD-MM-YYYY HH:mm:ss");
let chauffeurEndDate = Moment().add(minutes+30,'minutes').seconds(0).format("DD-MM-YYYY HH:mm:ss");

let selfDriveStartDate = Moment().add(1,'d').add(minutes,'minutes').seconds(0).format("DD-MM-YYYY HH:mm:ss");
let selfDriveEndDate = Moment(selfDriveStartDate,["DD-MM-YYYY HH:mm:ss"]).add(10,'h').format("DD-MM-YYYY HH:mm:ss");


let initialState = {
  pickup:{},
  dest:{},
  chauffeurStartDate,
  chauffeurEndDate,
  selfDriveStartDate,
  selfDriveEndDate,
  startTime:"",
  endTime:"",
  measureLookupId:'100010011',
  rentalType:1,
  noOfDays:1,
  stops:[],
  rideDistance:0,
  removedStops : {},
  carsLoading : false,
}
let newStops,count;


export default function rideReducer(state=initialState,action){
  debugger
  switch(action.type) {
    case 'ADD_FIRST_STOP':
    if(state.stops.length === 0){
      return {
        ...state,
        stops:[
          {
            seqId:1,
            city:action.data.city,
            name:action.data.name,
            latitude:action.data.latitude,
            longitude:action.data.longitude
          }
        ]
      }
    }
    else{
      return {
       ...state,
       stops: state.stops.map(
           (stop, i) => i === 0 ? {seqId:1,city:action.data.city,name:action.data.name,latitude:action.data.latitude,longitude:action.data.longitude}
                                   : stop
       )
    }
    }

    case 'ADD_STOP':
    if(state.measureLookupId === '100010001') {
      //if roundTrip true
      debugger
      return{
        ...state,
        stops:[
          ...state.stops.slice(0,state.stops.length-1),
          {
            seqId:state.stops.length,
            name:action.data.name,
            latitude:action.data.latitude,
            longitude:action.data.longitude
          },
          ...state.stops.slice(state.stops.length-1).map((stop) => ({seqId:stop.seqId+1,name:stop.name,latitude:stop.latitude,longitude:stop.longitude}))
        ]
      }
    }

    return {
      ...state,
      stops:[
        ...state.stops,
        {
          seqId:state.stops.length + 1,
          city:action.data.city,
          name:action.data.name,
          latitude:action.data.latitude,
          longitude:action.data.longitude
        }
      ]
    }

    case 'ADD_STOP_AT_INDEX':
    debugger
    return{
      ...state,
      stops:[
        ...state.stops.slice(0,action.index),
        {
          seqId:action.index+1,
          city:action.data.city,
          name:action.data.name,
          latitude:action.data.latitude,
          longitude:action.data.longitude
        },
        ...state.stops.slice(action.index).map((stop) => ({seqId:stop.seqId+1,city:stop.city,name:stop.name,latitude:stop.latitude,longitude:stop.longitude}))
      ]
    }

    case 'EDIT_STOP':
      return {
        ...state,
        stops:state.stops.map(function(stop){
          debugger
          if(stop.seqId === action.id){
            return {seqId:stop.seqId,city:action.data.city,name:action.data.name,latitude:action.data.latitude,longitude:action.data.longitude};
          }
          else{
            return stop;
          }
        }
        )
      }

    case 'REMOVE_STOP':
      return{
        ...state,
        stops:[
          ...state.stops.slice(0, action.id-1),
          ...state.stops.slice(action.id).map((stop) => ({seqId:stop.seqId-1,city:stop.city,name:stop.name,latitude:stop.latitude,longitude:stop.longitude}))
        ]
      }

    case 'REMOVE_LAST_STOP':
      return {
        ...state,
        stops:[
          ...state.stops.slice(0,state.stops.length-1)
        ]
      }
    case 'ADD_HOTEL_STOP':
      newStops = [...state.stops];
      for(let i=0;i < newStops.length;i++){
        if(action.id === newStops[i].seqId){
          if(newStops[i].hasOwnProperty("hotels")){
            newStops[i].hotels[action.hotelId] = action.data
          }
          else{
            newStops[i].hotels = {};
            newStops[i].hotels[action.hotelId] = action.data
          }
          break;
        }
      }
      return {
        ...state,
        stops:newStops
      };
    case 'REMOVE_HOTEL_STOP' :
      count = 0;
      newStops = [...state.stops];
      for(let  i=0;i<newStops.length;i++){
        if(newStops[i].hasOwnProperty("hotels")) {
          for(let k in newStops[i].hotels){
            if(k === action.hotelId){
              for(let j in newStops[i].hotels) {
                count++;
              }
              if(count === 1){
                delete newStops[i].hotels;
                break;
              }
              delete newStops[i].hotels[action.hotelId];
              break;
            }
          }
        }
      }
      return {
        ...state,
        stops:newStops
      };

    case 'ADD_RECREATION_STOP':
      newStops = [...state.stops];
      for(let i=0;i < newStops.length;i++){
        if(action.id === newStops[i].seqId){
          if(newStops[i].hasOwnProperty("recreations")){
            newStops[i].recreations[action.recId] = action.data
          }
          else{
            newStops[i].recreations = {};
            newStops[i].recreations[action.recId] = action.data
          }
          break;
        }
      }
      return {
        ...state,
        stops:newStops
      };

    case 'REMOVE_RECREATION_STOP' :
      count = 0;
      newStops = [...state.stops];
      for(let  i=0;i<newStops.length;i++){
        if(newStops[i].hasOwnProperty("recreations")) {
          for(let k in newStops[i].recreations){
            if(k === action.recId){
              for(let j in newStops[i].recreations) {
                count++;
              }
              if(count === 1){
                delete newStops[i].recreations;
                break;
              }
              delete newStops[i].recreations[action.recId];
              break;
            }
          }
        }
      }
      return {
        ...state,
        stops:newStops
      };
    case 'UPDATE_PICKUP_CITY' :
      return {
        ...state,
        pickup:{
          ...state.pickup,
          city : action.data.city,
          cityLat : action.data.cityLat,
          cityLng : action.data.cityLng,
          leadTime:action.data.cityLeadTime
        }
      }
    case 'UPDATE_PICKUP_LOCATION' :
      return {
        ...state,
        pickup: {
          ...state.pickup,
          loc:action.data.loc,
          locLat:action.data.locLat,
          locLng:action.data.locLng
        }
      }
      case 'UPDATE_DEST_CITY' :
        return {
          ...state,
          dest:{
            ...state.dest,
            city : action.data.city,
            cityLat : action.data.cityLat,
            cityLng : action.data.cityLng
          }
        }
      case 'UPDATE_DEST_LOCATION' :
        return {
          ...state,
          dest: {
            ...state.dest,
            loc:action.data.loc,
            locLat:action.data.locLat,
            locLng:action.data.locLng
          }
        }
    case 'UPDATE_CHAUFFEUR_STARTDATE' :
      return Object.assign({},state,{chauffeurStartDate : action.date});
    case 'UPDATE_CHAUFFEUR_ENDDATE' :
      return Object.assign({},state,{chauffeurEndDate : action.date});
    case 'UPDATE_SELFDRIVE_STARTDATE' :
      return Object.assign({},state,{selfDriveStartDate : action.date});
    case 'UPDATE_SELFDRIVE_ENDDATE' :
      return Object.assign({},state,{selfDriveEndDate : action.date});
    case 'UPDATE_STARTTIME' :
      return Object.assign({},state,{startTime : action.data});
    case 'UPDATE_ENDTIME' :
      return Object.assign({},state,{endTime : action.data});
    case 'UPDATE_NOOF_DAYS':
      return Object.assign({},state,{noOfDays:action.data});
    case 'UPDATE_RIDE_DISTANCE':
      return Object.assign({},state,{rideDistance:action.data});
      case 'UPDATE_RIDE_DURATION':
        return Object.assign({},state,{rideDuration:action.data});
    case 'UPDATE_TRIP_TYPE':
     return Object.assign({},state,{measureLookupId : action.id});
    case 'UPDATE_RENTAL_TYPE':
            return {
                ...state,
                rentalType: action.data
            }
    case 'CARS_LOADING_TRUE':
      return Object.assign({},state,{carsLoading : true});
    case 'CARS_LOADING_FALSE':
      return Object.assign({},state,{carsLoading : false});
    default:
        return state
  }
}
