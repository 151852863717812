let initialState = {
    locations: [],
    bookings: [],
    payments: [],
    profile: {},
    isLocationsLoading: false,
    isLocationsAvailable: false,
    isBookingsLoading: false,
    isBookingsAvailable: false,
    isPaymentsLoading: false,
    isPaymentsAvailable: false,
    isProfileLoading: false,
    isProfileAvailable: false,
    partnerBookings: [],
    isPartnerBookingsLoading: false,
    isPartnerBookingsAvailable: false,
    carsData: { makers: [], models: [], categories: [], subCategories: [] },
    cars: [],
    isProductLoading: false,
    isProductAvailable: false,
    drivers: [],
    isDriversLoading: false,
    isDriversAvailable: false,
    productPricing: [],
    vendorPayments: [],
    userDetails: {}
}

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_LOCATIONS':
            return {
                ...state,
                locations: action.data
            }
        case 'UPDATE_BOOKINGS':
            return {
                ...state,
                bookings: action.data
            }
        case 'UPDATE_PAYMENTS':
            return {
                ...state,
                payments: action.data
            }
        case 'UPDATE_PROFILE':
            return {
                ...state,
                profile: action.data
            }
        case 'UPDATE_LOCATIONS_LOADING':
            return {
                ...state,
                isLocationsLoading: action.data
            }
        case 'UPDATE_LOCATIONS_AVAILABLE':
            return {
                ...state,
                isLocationsAvailable: action.data
            }
        case 'UPDATE_BOOKINGS_LOADING':
            return {
                ...state,
                isBookingsLoading: action.data
            }
        case 'UPDATE_BOOKINGS_AVAILABLE':
            return {
                ...state,
                isBookingsAvailable: action.data
            }
        case 'UPDATE_PAYMENTS_LOADING':
            return {
                ...state,
                isPaymentsLoading: action.data
            }
        case 'UPDATE_PAYMENTS_AVAILABLE':
            return {
                ...state,
                isPaymentsAvailable: action.data
            }
        case 'UPDATE_PROFILE_LOADING':
            return {
                ...state,
                isProfileLoading: action.data
            }
        case 'UPDATE_PROFILE_AVAILABLE':
            return {
                ...state,
                isProfileAvailable: action.data
            }
        case 'UPDATE_PARTNER_BOOKINGS':
            return {
                ...state,
                partnerBookings: action.data
            }
        case 'UPDATE_PARTNER_BOOKINGS_LOADING':
            return {
                ...state,
                isPartnerBookingsLoading: action.data
            }
        case 'UPDATE_PARTNER_BOOKINGS_AVAILABLE':
            return {
                ...state,
                isPartnerBookingsAvailable: action.data
            }
        case 'UPDATE_CARS_DATA':
            return {
                ...state,
                carsData: action.data
            }
        case 'UPDATE_CARS':
            return {
                ...state,
                cars: action.data
            }
        case 'UPDATE_PRODUCT_LOADING':
            return {
                ...state,
                isProductLoading: action.data
            }
        case 'UPDATE_PRODUCT_AVAILABLE':
            return {
                ...state,
                isProductAvailable: action.data
            }
        case 'UPDATE_DRIVERS':
            return {
                ...state,
                drivers: action.data
            }
        case 'UPDATE_DRIVERS_LOADING':
            return {
                ...state,
                isDriversLoading: action.data
            }
        case 'UPDATE_DRIVERS_AVAILABLE':
            return {
                ...state,
                isDriversAvailable: action.data
            }
        case 'UPDATE_PRODUCT_PRICING':
            return {
                ...state,
                productPricing: action.data
            }
        case 'UPDATE_VENDOR_PAYMENTS':
            return {
                ...state,
                vendorPayments: action.data
            }
        case 'UPDATE_USER_DETAILS':
            return {
                ...state,
                userDetails: action.data
            }
        default:
            return state
    }
}