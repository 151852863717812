import { combineReducers } from "redux"
import user from "./userReducer"
import app from "./appReducer"
import ride from "./rideReducer"
import partner from "./partnerReducer"

const rootReducer = combineReducers({
    user,
    app,
    ride,
    partner
});

export default rootReducer;
