export const categories = [
    {
        "value": 100090047,
        "label": "MINI",
        "id": 100090047,
        "lookupCategory": {
            "id": 10009
        },
        "lookupDescription": "Mini",
        "lookupValue": "MINI",
        "lookupCategoryId": 10009,
        "seats": 4
    },
    {
        "value": 100090048,
        "label": "SEDAN",
        "id": 100090048,
        "lookupCategory": {
            "id": 10009
        },
        "lookupDescription": "Sedan",
        "lookupValue": "SEDAN",
        "lookupCategoryId": 10009,
        "seats": 4
    },
    {
        "value": 100090049,
        "label": "SUV",
        "id": 100090049,
        "lookupCategory": {
            "id": 10009
        },
        "lookupDescription": "SUV",
        "lookupValue": "SUV",
        "lookupCategoryId": 10009,
        "seats": 6
    },
    {
        "value": 100090050,
        "label": "Luxury",
        "id": 100090050,
        "lookupCategory": {
            "id": 10009
        },
        "lookupDescription": "Luxury",
        "lookupValue": "LUXURY",
        "lookupCategoryId": 10009,
        "seats": 4
    },
    {
        "value": 100090051,
        "label": "MINI BUS",
        "id": 100090051,
        "lookupCategory": {
            "id": 10009
        },
        "lookupDescription": "Mini Bus",
        "lookupValue": "MINI BUS",
        "lookupCategoryId": 10009
    }
]

export const subCategories = [
    {
    "value": 100160001,
    "label": "Tata Indigo or Similar",
    "id": 100160001,
    "lookupCategory": {
        "id": 10016
    },
    "lookupDescription": "Tata Indigo or Similar",
    "lookupValue": "INDIGO",
    "lookupNotes": "https://zyppys.co.in/car-images/sedan.png",
    "lookupCategoryId": 10016
    },
    {
        "value": 100160002,
        "label": "Dzire /Etios or Similar",
        "id": 100160002,
        "lookupCategory": {
            "id": 10016
        },
        "lookupDescription": "Dzire /Etios or Similar",
        "lookupValue": "ETIOS",
        "lookupNotes": "https://zyppys.co.in/car-images/sedan.png",
        "lookupCategoryId": 10016
    },
    {
        "value": 100160003,
        "label": "Honda City or Similar",
        "id": 100160003,
        "lookupCategory": {
            "id": 10016
        },
        "lookupDescription": "Honda City or Similar",
        "lookupValue": "HCITY",
        "lookupNotes": "https://s3-us-west-2.amazonaws.com/revvselfdrivecar/carImages/white_images/honda+city.png",
        "lookupCategoryId": 10016
    },
    {
        "value": 100160004,
        "label": "Indica /Ritz or Similar",
        "id": 100160004,
        "lookupCategory": {
            "id": 10016
        },
        "lookupDescription": "Indica /Ritz or Similar",
        "lookupValue": "INDICA",
        "lookupNotes": "https://zyppys.co.in/car-images/small.png",
        "lookupCategoryId": 10016
    },
    {
        "value": 100160005,
        "label": "TOYOTA INNOVA",
        "id": 100160005,
        "lookupCategory": {
            "id": 10016
        },
        "lookupDescription": "Toyota Innova",
        "lookupValue": "TOYOTA INNOVA",
        "lookupNotes": "https://s3-us-west-2.amazonaws.com/revvselfdrivecar/carImages/white_images/innova.png",
        "lookupCategoryId": 10016
    },
    {
        "value": 100160012,
        "label": "Xylo/Ertiga or Similar",
        "id": 100160012,
        "lookupCategory": {
            "id": 10016
        },
        "lookupDescription": "Xylo/Ertiga or Similar",
        "lookupValue": "SAFARI",
        "lookupNotes": "https://zyppys.co.in/car-images/suv.png",
        "lookupCategoryId": 10016
    },
    {
        "value": 100160017,
        "label": "12 seater Tempo or Similar",
        "id": 100160017,
        "lookupCategory": {
            "id": 10016
        },
        "lookupDescription": "12 seater Tempo or Similar",
        "lookupValue": "TEMPO",
        "lookupNotes": "https://zyppys.co.in/car-images/van.png",
        "lookupCategoryId": 10016
    }
]

export const types = [
    {
        "value": 1,
        "label": "Outstation",
    },
    {
        "value": 2,
        "label": "Airport Transfer",
    },
    {
        "value": 3,
        "label": "Hourly Rentals",
    },
]

export const outstation = [
    {
        "value": 100010011,
        "label": "One Way"
    },
    {
        "value": 100010001,
        "label": "Round Trip"
    },
]

export const airport = [
    {
        "value": 100010014,
        "label": "Airport Pickup"
    },
    {
        "value": 100010015,
        "label": "Airport Dropoff"
    },
]

export const hourly = [
    {
        "value": 100010006,
        "label": "4hrs, 40kms"
    },
    {
        "value": 100010007,
        "label": "8hrs, 80kms"
    },
]