import tw from "tailwind.macro"
import React from "react"
import { connect } from "react-redux"
import { css } from '@emotion/core'
import SubHeader from "./SubHeader"
import CarsList from "../components/CarsList"
import CarsListOverlay from "../components/CarsListOverlay"
import ReviewSlider from "../components/ReviewSlider"
import { updateSelectedCar } from "../actions/appActions"
import { navigate } from "gatsby"

const Wrapper = tw.div`
 flex flex-col p-4 h-screen w-full
`
const ListContainer = tw.div`
 flex flex-col p-4 w-full md:w-3/4 lg:w-3/4 self-center mt-10
`

class CarsComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedVendor: {},
            showDetails: false,
            showOverlay: false
        }
    }

    componentDidMount(){
      if(window.history.state.hasOwnProperty("selectedVendor")){
        this.setState({ selectedVendor: this.props.app.selectedCar, showDetails: true, showOverlay: true})
      }
    }

    handleProceed = () => {
        this.props.dispatch(updateSelectedCar(this.state.selectedVendor))
        navigate('/custInfo', { state:{} })
    }

    render() {
        let ride = this.props.ride;
        let app = this.props.app;
        return (
            <Wrapper>
                <SubHeader />
                <ListContainer>
                    <CarsList showSlider={(vendor)=>{ this.setState({ selectedVendor: vendor, showDetails: true, showOverlay: true })}}/>
                </ListContainer>
                {/* DO NOT MOVE OVERLAY COMPONENT */}
            <CarsListOverlay active={this.state.showOverlay} overlayClicked={()=>{this.setState({ showOverlay: false, showDetails: false})}}/>
            {this.state.showDetails && <ReviewSlider active={this.state.showDetails} hasDone vendor={this.state.selectedVendor} close={()=>{this.setState({ showOverlay: false, showDetails: false})}} onDone={this.handleProceed}>
            <div css={css`${tw`flex flex-wrap w-full items-start`}`}>
          <div css={css`${tw`lg:w-1/2 md:w-1/2 w-full mt-2`}`}>
            <h4 css={css`${tw`mt-4 text-base font-bold`}`}>Pricing Details</h4>
            <ul css={css`${tw`leading-loose text-black mt-4`}`}>
              <li css={css`${tw`font-medium`}`}>Total Price : &#8377; {(this.state.selectedVendor.ride_amt).toLocaleString('en')}</li>
              <li css={css`${tw`text-xs opacity-75`}`}>Base Fare:{(this.state.selectedVendor.base_fare).toLocaleString('en')} </li>
              <li css={css`${tw`text-xs opacity-75`}`}>Driver Charges: {(this.state.selectedVendor.driver_charges).toLocaleString('en')}</li>
              <li css={css`${tw`text-xs opacity-75`}`}>GST ({this.state.selectedVendor.service_tax_percentage}%): {this.state.selectedVendor.service_tax_amt} </li>
              <li css={css`${tw`text-xs opacity-75`}`}>Cost per Km: {this.state.selectedVendor.actual_cost_per_km} </li>
            </ul>
          </div>
          <div css={css`${tw`lg:w-1/2 md:w-1/2 w-full mt-2`}`}>
            <h4 css={css`${tw`mt-4 text-base font-bold`}`}>Package Details </h4>
            <ul css={css`${tw`leading-loose text-black mt-4`}`}>
              <li css={css`${tw`font-medium`}`}>Package kms: {this.state.selectedVendor.package_kms}</li>
              <li css={css`${tw`font-medium`}`}>Package Days: {this.state.selectedVendor.no_of_days}</li>
              {/* <li css={css`${tw`text-xs font-light opacity-75`}`}>Day Calculation Type: {this.state.selectedVendor.time_frame_type_desc}</li> */}
            </ul>
          </div>

          <div css={css`${tw`mt-2 w-full`}`}>
            <h4 css={css`${tw`mt-4 text-base font-bold`}`}>Terms and Conditions</h4>
            {this.state.selectedVendor.terms_conditions.map((term) => (
                <p css={css`${tw`leading-loose text-sm opacity-75`}`}>*&nbsp;{term}</p>
            ))}
            
          </div>

        </div>
            </ReviewSlider>}
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    ride: state.ride,
    app: state.app
})

export default connect(mapStateToProps)(CarsComponent);
