import tw from "tailwind.macro"
import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { css } from '@emotion/core'

const Wrapper = styled.div`
 ${tw`flex flex-wrap content-center w-full bg-white rounded-lg shadow-2xl cursor-pointer`}
 height: 20rem;
`
const ContainerOne = styled.div`
${tw`w-full h-48 p-4`}
`
const ContainerTwo = styled.div`
${tw`w-full py-4 px-8`}
`
const Heading = styled.h1`
${tw`text-lg font-light text-black`}
`
const DetailsText = styled.p`
${tw`text-xs font-light text-black`}
`
const PriceText = styled.p`
${tw`text-xl font-medium text-black py-1`}
`
const PackageText = styled.p`
${tw`text-xs font-medium text-black`}
`

class CarCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        let ride = this.props.ride;
        let app = this.props.app;
        let car = this.props.car;
        let vendor = this.props.vendor
        return (
            <Wrapper onClick={()=>{this.props.selectedCard(vendor)}}>
            <ContainerOne>
            <Heading>{car.sub_category}</Heading>
            <img css={css`${tw`h-full`}`} src={car.sub_category_url}/>
            </ContainerOne>
            <ContainerTwo>
            <DetailsText>{vendor.no_of_seats} Seats A/C</DetailsText>
            <PriceText>&#8377; {Math.round(vendor.ride_amt).toLocaleString()}</PriceText>
            <PackageText>{vendor.package_kms} Kms {!app.isRental && !app.isAirport && '  | ' + vendor.actual_cost_per_km + ' Rs. per km'}</PackageText>
            </ContainerTwo>
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    ride: state.ride,
    app: state.app
})

export default connect(mapStateToProps)(CarCard);
