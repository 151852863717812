import React from "react"
import { connect } from "react-redux"
import tw from "tailwind.macro"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { fetchPartnerBookings } from "../../actions/userAction"
import SliderModal from "../SliderModal"
import Overlay from "../Overlay"
import Moment from "moment"
import DriverCard from "./DriverCard"
import VehicleCard from "./VehicleCard"
import AccordionItem from "../AccordionItem"
import Popup from 'reactjs-popup'
import { addDriver, addCar } from "./Functions"
import { fetchBidDetailsBusiness } from "../../actions/partnerActions"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash';
import Select from "react-dropdown-select"
import Loader from 'react-loader-spinner'
import { categories, subCategories } from "./StaticData"
import { fetchLocations, fetchProductNamesBusiness, fetchProductBusiness, fetchDriversForBusiness } from "../../actions/userAction"

const Wrapper = styled.div`
  ${tw`flex md:hidden lg:hidden relative w-screen h-screen flex-col p-8`}
  ${'' /* background: #e7ebef; */}
`
const SearchImage = styled.img`
${tw`absolute h-5 opacity-50`}
right: 1rem;
top: 1rem;
`
const Button = styled.button`
  ${tw`py-1 px-2 text-center text-base font-normal text-white`}
  background-color: #8a3df6;
`
const Row = tw.div`
block w-full
`
const Column = tw.div`
flex flex-row mt-1 md:mt-2 lg:mt-2 items-center w-full
`
const Title = tw.p`
flex flex-row text-gray-600 w-1/2
`
const SubTitle = tw.p`
text-gray-600
`
const Text = tw.p`
flex flex-row font-medium w-3/5
`
const SubText = tw.p`
break-words py-2 text-xs text-gray-700 font-medium ml-2
`
const StyledPopup = styled(Popup)`
// use your custom style for ".popup-overlay"
  &-overlay {
  }
// use your custom style for ".popup-content"
.popup-content {
    width: 80% !important;
  }
`
const LoaderContainer = styled.div`
  ${tw`flex justify-center h-full w-full bg-black absolute left-0 -mt-4 opacity-50`}
`
const InputText = tw.input`
w-full text-sm lg:text-base font-medium bg-gray-300 px-1 lg:px-5 py-2 appearance-none outline-none shadow-none border-none
`
const RadioButton = styled.div`
${tw`h-8 w-8 rounded-full border-2 border-purple-500 cursor-pointer`}
min-width: 2rem;
min-height: 2rem;
max-height: 2rem;
max-width: 2rem;
`

class DriversAndCarsMobile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedDriver: {},
            showLoader: false,
            showDetails: false,
            showOverlay: false,
            showAddDriver: false,
            showAddVehicle: false,
            gearType: "Automatic",
            isDrivers: true,
            name:'',
            phone: '',
            email: '',
            address: '',
            aadharNo: '',
            licenseNo: '',
            makerId: null,
            modelId: null,
            makeYear: '',
            regNo: '',
            categoryId: null,
            subCategoryId: null,
            locationId: null,
            cars: [],
            drivers: [],
            isDriversLoading: true,
            isProductLoading: true,
            isDriversAvailable: false,
            isProductAvailable: false
        }
    }

    componentWillMount() {
        this.props.dispatch(fetchLocations())
        this.props.dispatch(fetchProductNamesBusiness())
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.drivers != this.props.drivers){
            this.setState({ drivers: this.props.drivers })
        }
        if(prevProps.cars != this.props.cars){
            this.setState({ cars: this.props.cars })
        }
        if(prevProps.isProductLoading != this.props.isProductLoading){
            this.setState({ isProductLoading: this.props.isDriversLoading })
        }
        if(prevProps.isDriversLoading != this.props.isDriversLoading){
            this.setState({ isDriversLoading: this.props.isDriversLoading })
        }
        if(prevProps.isDriversAvailable != this.props.isDriversAvailable){
            this.setState({ isDriversAvailable: this.props.isDriversAvailable })
        }
        if(prevProps.isProductAvailable != this.props.isProductAvailable){
            this.setState({ isProductAvailable: this.props.isProductAvailable })
        }
    }

    filterCaseInsensitive = (filter, row) => {
        console.log(filter);
        const id = filter.pivotId || filter.id;
        let value = filter.value;
        console.log(value);
        if (typeof row[id] == 'string') {
            console.log(id);
            if (row[id] !== undefined) {
                return String(row[id].toLowerCase()).includes(value.toLowerCase());
            }
            else {
                return true;
            }
        }
        else {

            if (row[id] !== undefined) {
                return String(row[id]).includes(value.toString());
            }
            else {
                return true
            }
        }
    }

    handleClose = () => {
        window.document.getElementsByTagName("body")[0].style.overflow = "auto"
        this.setState({ showOverlay: false, showDetails: false })
        this.props.dispatch(fetchProductBusiness())
        this.props.dispatch(fetchDriversForBusiness())
    }

    handleSearch = (value) => {
        console.log(value);
        if (isNaN(value)) {
            window.document.getElementById("search").style.border = "thin solid #FF0000"
        }
        else {
            window.document.getElementById("search").style.border = "thin solid #00FF00"
            this.setState({ partnerBookings: this.props.partnerBookings.filter(b => b.liveApiBookingId.toString().includes(value)) })
        }
    }

    handleSliderOpen = (state) => {
        window.document.getElementsByTagName("body")[0].style.overflow = "hidden"
        if (state === "addDriver") {
            this.setState({ showAddDriver: true, showDetails: true, showOverlay: true, showAddVehicle: false })
        }
        else if (state === "addVehicle") {
            this.setState({ showAddVehicle: true, showDetails: true, showOverlay: true, showAddDriver: false })
        }
        else {
            this.setState({ showAddVehicle: false, showDetails: true, showOverlay: true, showAddDriver: false })
        }
    }

    toggleGearType = () => {
        if (this.state.gearType === "Automatic") {
            this.setState({ gearType: "Manual" })
        }
        else {
            this.setState({ gearType: "Automatic" })
        }
    }

    handleTextChange = (state, value) => {
        this.setState({ [state]: value })
    }

    handleAddDriver = () => {
        if(this.state.name == ''){
            alert("Please enter the name")
            return
        }
        if(this.state.phone.length < 10){
            alert("Please enter a valid phone number")
            return
        }
        if(this.state.address == ''){
            alert("Please enter the address")
            return
        }
        if(this.state.licenseNo == ''){
            alert("please enter the license number")
            return
        }
        let data={
            "name": this.state.name,
            "businessName": "Business Test",
            "address": this.state.address,
            "emailId": this.state.email,
            "mobileNo": this.state.phone,
            "aadharNo": this.state.aadharNo,
            "licenseNo": this.state.licenseNo,
            // "ownerId": null,
            "accountId": this.props.accountId
        }
        this.props.dispatch(addDriver(data))
    }

    handleAddCar = () => {
        if(this.state.makerId == null){
            alert("Please select the maker")
            return
        }
        if(this.state.modelId == null){
            alert("Please select the model")
            return
        }
        if(this.state.makeYear == ''){
            alert("Please enter the manufacture year")
            return
        }
        if(this.state.regNo == ""){
            alert("Please enter the registration number")
            return
        }
        if(this.state.categoryId == null){
            alert("Please select the category")
            return
        }
        if(this.state.subCategoryId == null){
            alert("Please select the sub category")
            return
        }
        if(this.state.locationId == null){
            alert("Please select the location")
            return
        }
        let data = {
            "maker": this.state.makerId.toString(),
            "model": this.state.modelId.toString(),
            "makeYear": this.state.makeYear,
            "regNo": this.state.regNo,
            "categoryId": this.state.categoryId.toString(),
            "subCategoryId": this.state.subCategoryId.toString(),
            "gearSystem": this.state.gearType,
            "drivenBy": "Chauffeur",
            "locationId": this.state.locationId,
            "accountId": this.props.accountId,
            // "ownerId": null
        }
        this.props.dispatch(addCar(data))
    }

    render() {
        return (
            <Wrapper>
                <h1 css={css`${tw`font-bold text-black text-lg py-2`}`}>Drivers And Cars</h1>
                <div css={css`${tw`block py-2`}`}>
                    <p css={css`${tw`inline-block font-normal text-base cursor-pointer`} + ${this.state.isDrivers ? tw` text-purple-500 underline` : tw` text-black`}`} onClick={() => { this.setState({ isDrivers: true }) }}>Drivers</p>
                    <p css={css`${tw`inline-block ml-4 font-normal text-base cursor-pointer`} + ${!this.state.isDrivers ? tw` text-purple-500 underline` : tw` text-black`}`} onClick={() => { this.setState({ isDrivers: false }) }}>Cars</p>
                </div>
                <div css={css`${tw`block py-2`}`}>
                    {this.state.isDrivers && <Button onClick={() => { this.handleSliderOpen() }}>Add Driver</Button>}
                    {!this.state.isDrivers && <Button onClick={() => { this.handleSliderOpen() }}>Add Car</Button>}
                </div>
                {/* <div css={css`${tw`flex w-full py-2 relative`}`}>
                <input type="text" placeholder="#booking id" id="search" onChange={(e)=>this.handleSearch(e.target.value)} css={css`${tw`flex w-full p-2 rounded-full bg-white border-gray-500 border appearance-none outline-none shadow-2xl`}`} />
                <SearchImage src={require("../images/search.png")} />
                </div> */}
                {!this.state.isDriversLoading && !this.state.isDriversAvailable && this.state.isDrivers && <p css={css`${tw`font-medium text-center self-center text-black text-lg py-4`}`}>No drivers available</p>}
                {!this.state.isProductLoading && !this.state.isProductAvailable && !this.state.isDrivers && <p css={css`${tw`font-medium text-center self-center text-black text-lg py-4`}`}>No drivers available</p>}
                {!this.state.isDriversLoading && this.state.isDriversAvailable && this.state.isDrivers && this.state.drivers.map(driver => (<div css={css`${tw`py-8`}`}>
                    <DriverCard driver={driver} />
                </div>
                ))}
                {!this.state.isProductLoading && this.state.isProductAvailable && !this.state.isDrivers && this.state.cars.map(car => (<div css={css`${tw`py-8`}`}>
                    <VehicleCard car={car} />
                </div>
                ))}
                {/* DO NOT MOVE OVERLAY COMPONENT */}
                <Overlay active={this.state.showOverlay} overlayClicked={this.handleClose} />
                {this.state.showDetails && <SliderModal active={this.state.showDetails} heading={""} close={this.handleClose}>
                    {this.state.showLoader && <LoaderContainer style={{ zIndex: 1000 }} css={css`${tw``} `}>
                        <div css={css`${tw`self-center`}`}>
                            <Loader
                                type="Puff"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                        </div>
                    </LoaderContainer>}
                    {this.state.isDrivers && <AccordionItem title="Onboard Driver" isActive fixed>
                        <Row>
                            <Column>
                                <Title>Name*</Title>
                                <InputText type="text" onChange={(e)=>{this.handleTextChange("name",e.target.value)}}/>
                            </Column>
                            <Column>
                                <Title>Phone Number*</Title>
                                <InputText type="text" onChange={(e)=>{this.handleTextChange("phone",e.target.value)}}/>
                            </Column>
                            <Column>
                                <Title>Address*</Title>
                                <InputText type="text" onChange={(e)=>{this.handleTextChange("address",e.target.value)}}/>
                            </Column>
                            <Column>
                                <Title>License Number*</Title>
                                <InputText type="text" onChange={(e)=>{this.handleTextChange("licenseNo",e.target.value)}}/>
                            </Column>
                            <Column>
                                <Title>Aadhar Number</Title>
                                <InputText type="text" onChange={(e)=>{this.handleTextChange("aadahrNo",e.target.value)}}/>
                            </Column>
                            <Column>
                                <Title>Email Id</Title>
                                <InputText type="text" onChange={(e)=>{this.handleTextChange("email",e.target.value)}}/>
                            </Column>
                        </Row>
                        <Row css={css`${tw`flex justify-end mt-2`}`}>
                            <Button css={css`${tw`py-1 px-4`}`} onClick={this.handleAddDriver}>Add Driver</Button>
                        </Row>
                    </AccordionItem>}
                    {!this.state.isDrivers && <AccordionItem title="Onboard Car" isActive fixed>
                    <div css={css`${tw`w-full -mt-6 md:mt-0 lg:mt-0`}`}>
                        <Row>
                            <Column>
                                <Title>Maker</Title>
                                <div css={css`${tw`w-full text-sm lg:text-base font-medium bg-gray-300 appearance-none outline-none shadow-none border-none`}`}>
                                    <Select options={this.props.makers} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                                        if (val.length > 0) {
                                            this.setState({ makerId: val[0].value })
                                        }
                                    }} />
                                </div>
                            </Column>
                            <Column>
                                <Title>Model</Title>
                                <div css={css`${tw`w-full text-sm lg:text-base font-medium bg-gray-300 appearance-none outline-none shadow-none border-none`}`}>
                                    <Select options={this.props.models} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                                        if (val.length > 0) {
                                            this.setState({ modelId: val[0].value })
                                        }
                                    }} />
                                </div>
                            </Column>
                            <Column>
                                <Title>Manufacture Year</Title>
                                <InputText type="text" onChange={(e)=>{this.handleTextChange("makeYear",e.target.value)}}/>
                            </Column>
                            <Column>
                                <Title>Registration Number</Title>
                                <InputText type="text" onChange={(e)=>{this.handleTextChange("regNo",e.target.value)}}/>
                            </Column>
                            <Column>
                                <Title>Category</Title>
                                <div css={css`${tw`w-full text-sm lg:text-base font-medium bg-gray-300 appearance-none outline-none shadow-none border-none`}`}>
                                    <Select options={this.props.categories} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                                        if (val.length > 0) {
                                            this.setState({ categoryId: val[0].value })
                                        }
                                    }} />
                                </div>
                            </Column>
                            <Column>
                                <Title>Sub Category</Title>
                                <div css={css`${tw`w-full text-sm lg:text-base font-medium bg-gray-300 appearance-none outline-none shadow-none border-none`}`}>
                                    <Select options={this.props.subCategories} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                                        if (val.length > 0) {
                                            this.setState({ subCategoryId: val[0].value })
                                        }
                                    }} />
                                </div>
                            </Column>
                            <Column>
                                <Title>Location</Title>
                                <div css={css`${tw`w-full text-sm lg:text-base font-medium bg-gray-300 appearance-none outline-none shadow-none border-none`}`}>
                                    <Select options={this.props.locations} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                                        if (val.length > 0) {
                                            this.setState({ locationId: val[0].value })
                                        }
                                    }} />
                                </div>
                            </Column>
                            {/* <Column>
                                <Title>Gear System</Title>
                                <div css={css`${tw`w-full flex items-center`}`}>
                                    <RadioButton css={css`${this.state.gearType == 'Automatic' ? tw`bg-purple-500` : tw`bg-transparent`}`} onClick={this.toggleGearType} />
                                    <Text css={css`${tw`ml-1`}`}>{this.state.gearType}</Text>
                                </div>
                            </Column> */}
                        </Row>
                        <Row css={css`${tw`flex justify-end mt-2`}`}>
                            <Button css={css`${tw`py-1 px-4`}`} onClick={this.handleAddCar}>Add Car</Button>
                        </Row>
                        </div>
                    </AccordionItem>}
                </SliderModal>}
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    locations: state.user.locations,
    makers: state.user.carsData.makers,
    models: state.user.carsData.models,
    categories: state.user.carsData.categories,
    subCategories: state.user.carsData.subCategories,
    drivers: state.user.drivers,
    cars: state.user.cars,
    isDriversLoading: state.user.isDriversLoading,
    isDriversAvailable: state.user.isDriversAvailable,
    isProductLoading: state.user.isProductLoading,
    isProductAvailable: state.user.isProductAvailable,
    accountId: state.user.userDetails.accountId
})

export default connect(mapStateToProps)(DriversAndCarsMobile)

const CalendarInput = ({ value, onClick }) => (
    <div css={css`${tw`p-2 text-center border border-gray-600 text-base`}`} className="example-custom-input" onClick={onClick}>
        {value}
    </div>
);

const MyContainer = ({ className, children }) => (
    <div className={className} style={{ marginLeft: '-10%' }}>
        <div style={{ position: "relative", display: "flex" }}>{children}</div>
    </div>
)