// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-js": () => import("./../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-booking-list-js": () => import("./../src/pages/bookingList.js" /* webpackChunkName: "component---src-pages-booking-list-js" */),
  "component---src-pages-cars-js": () => import("./../src/pages/cars.js" /* webpackChunkName: "component---src-pages-cars-js" */),
  "component---src-pages-confirmed-js": () => import("./../src/pages/confirmed.js" /* webpackChunkName: "component---src-pages-confirmed-js" */),
  "component---src-pages-cust-info-js": () => import("./../src/pages/custInfo.js" /* webpackChunkName: "component---src-pages-cust-info-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-partners-bookings-js": () => import("./../src/pages/partners/bookings.js" /* webpackChunkName: "component---src-pages-partners-bookings-js" */),
  "component---src-pages-partners-drivers-and-cars-js": () => import("./../src/pages/partners/driversAndCars.js" /* webpackChunkName: "component---src-pages-partners-drivers-and-cars-js" */),
  "component---src-pages-partners-js": () => import("./../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-partners-payments-js": () => import("./../src/pages/partners/payments.js" /* webpackChunkName: "component---src-pages-partners-payments-js" */),
  "component---src-pages-partners-pricing-js": () => import("./../src/pages/partners/pricing.js" /* webpackChunkName: "component---src-pages-partners-pricing-js" */),
  "component---src-pages-partners-profile-js": () => import("./../src/pages/partners/profile.js" /* webpackChunkName: "component---src-pages-partners-profile-js" */),
  "component---src-pages-partners-settings-js": () => import("./../src/pages/partners/settings.js" /* webpackChunkName: "component---src-pages-partners-settings-js" */),
  "component---src-pages-partners-support-js": () => import("./../src/pages/partners/support.js" /* webpackChunkName: "component---src-pages-partners-support-js" */),
  "component---src-pages-payments-js": () => import("./../src/pages/payments.js" /* webpackChunkName: "component---src-pages-payments-js" */),
  "component---src-pages-profile-js": () => import("./../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-settings-js": () => import("./../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-support-js": () => import("./../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */)
}

