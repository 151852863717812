import React from "react"
import { connect } from "react-redux"
import SEO from "../components/seo"
import tw from "tailwind.macro"
import styled from "@emotion/styled"
import Header from "../components/Header"
import PaymentsPortal from "../components/PaymentsPortal"
import PaymentsMobile from "../components/PaymentsMobile"
import Footer from "../components/Footer"
import Menu from "../components/Menu"
import Overlay from "../components/Overlay"
import { isMobile } from "react-device-detect"
import Loader from 'react-loader-spinner'
import { css } from '@emotion/core'
import Moment from "moment"
import { fetchPaymentsForBusiness } from "../actions/userAction"

const Wrapper = tw.div`
  flex items-center relative justify-center w-full flex-col bg-white
`
const LoaderContainer = styled.div`
  ${tw`justify-center h-full w-full bg-black left-0 fixed opacity-50`}
  top: 5.0rem;
  @media (min-width: 992px){
    top: 6.5rem;
  }
`

class Payments extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
            showOverlay: false,
            isPaymentsLoading: true
        }
    }

    componentDidUpdate(prevProps, prevState){
      
        if(prevProps.isPaymentsLoading != this.props.isPaymentsLoading){
          this.setState({ isPaymentsLoading: this.props.isPaymentsLoading})
        }
        if(prevProps.userDetails != this.props.userDetails){
          let data = {
            startDate: Moment(Moment().subtract(1, "month"), "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD 00:00:00"),
            endDate: Moment(Moment(), "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD 00:00:00")
        }
        this.props.dispatch(fetchPaymentsForBusiness(data));
        }
      }

    toggleMenu = () => {
        this.setState({ showMenu: !this.state.showMenu, showOverlay: !this.state.showOverlay })
    }

    render() {
        if (typeof window != `undefined`) {
            if (this.state.isPaymentsLoading) {
              window.document.getElementsByTagName("body")[0].style.overflow = "hidden";
            }
            else {
              window.document.getElementsByTagName("body")[0].style.overflow = "auto";
            }
          }
        return (
            <Wrapper>
                <SEO title="Payments" />
                <Header shadow business dark openMenu={this.toggleMenu} />
                {this.state.isPaymentsLoading && <LoaderContainer style={{zIndex:1000}} css={css`${tw``} display: ${this.state.isPaymentsLoading ? "flex !important" : "none !important"};`}>
                    <div css={css`${tw`self-center`}`}>
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={100}
                            width={100}
                        />
                    </div>
                </LoaderContainer>}
                <PaymentsPortal />
                <PaymentsMobile />
                {/* <Footer /> */}
                {/* DO NOT MOVE OVERLAY COMPONENT */}
                <Overlay active={this.state.showOverlay} overlayClicked={() => { this.setState({ showOverlay: false, showMenu: false }) }} />
                {this.state.showMenu && <Menu business openMenu={this.toggleMenu} />}
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    isPaymentsLoading: state.user.isPaymentsLoading,
    userDetails: state.user.userDetails
})

export default connect(mapStateToProps)(Payments)