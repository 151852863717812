import { urls } from "../api/urls"
import axios from "axios"
import { storeKey, retrieveKey } from "../asyncStorage"
import _ from "lodash"

export function updateLocations(data) {
    return {
        type: 'UPDATE_LOCATIONS',
        data
    }
}

export function updateBookings(data) {
    return {
        type: 'UPDATE_BOOKINGS',
        data
    }
}

export function updatePayments(data) {
    return {
        type: 'UPDATE_PAYMENTS',
        data
    }
}

export function updateProfile(data) {
    return {
        type: 'UPDATE_PROFILE',
        data
    }
}

export function updateLocationsLoading(data) {
    return {
        type: 'UPDATE_LOCATIONS_LOADING',
        data
    }
}

export function updateLocationsAvailable(data) {
    return {
        type: 'UPDATE_LOCATIONS_AVAILABLE',
        data
    }
}

export function updateBookingsLoading(data) {
    return {
        type: 'UPDATE_BOOKINGS_LOADING',
        data
    }
}

export function updateBookingsAvailable(data) {
    return {
        type: 'UPDATE_BOOKINGS_AVAILABLE',
        data
    }
}

export function updatePaymentsLoading(data) {
    return {
        type: 'UPDATE_PAYMENTS_LOADING',
        data
    }
}

export function updatePaymentsAvailable(data) {
    return {
        type: 'UPDATE_PAYMENTS_AVAILABLE',
        data
    }
}

export function updateProfileLoading(data) {
    return {
        type: 'UPDATE_PROFILE_LOADING',
        data
    }
}

export function updateProfileAvailable(data) {
    return {
        type: 'UPDATE_PROFILE_AVAILABLE',
        data
    }
}

export function updatePartnerBookings(data) {
    return {
        type: 'UPDATE_PARTNER_BOOKINGS',
        data
    }
}

export function updatePartnerBookingsLoading(data) {
    return {
        type: 'UPDATE_PARTNER_BOOKINGS_LOADING',
        data
    }
}

export function updatePartnerBookingsAvailable(data) {
    return {
        type: 'UPDATE_PARTNER_BOOKINGS_AVAILABLE',
        data
    }
}

export function updateCarsData(data) {
    return {
        type: 'UPDATE_CARS_DATA',
        data
    }
}

export function updateCars(data) {
    return {
        type: 'UPDATE_CARS',
        data
    }
}

export function updateProductLoading(data) {
    return {
        type: 'UPDATE_PRODUCT_LOADING',
        data
    }
}

export function updateProductAvailable(data) {
    return {
        type: 'UPDATE_PRODUCT_AVAILABLE',
        data
    }
}

export function updateDrivers(data) {
    return {
        type: 'UPDATE_DRIVERS',
        data
    }
}

export function updateDriversLoading(data) {
    return {
        type: 'UPDATE_DRIVERS_LOADING',
        data
    }
}

export function updateDriversAvailable(data) {
    return {
        type: 'UPDATE_DRIVERS_AVAILABLE',
        data
    }
}

export function updateProductPricing(data) {
    return {
        type: 'UPDATE_PRODUCT_PRICING',
        data
    }
}

export function updateVendorPayments(data) {
    return {
        type: 'UPDATE_VENDOR_PAYMENTS',
        data
    }
}

export function updateUserDetails(data) {
    return {
        type: 'UPDATE_USER_DETAILS',
        data
    }
}

export function fetchLocations() {
    return function (dispatch, getState) {
        dispatch(updateLocationsLoading(true));
        let url = urls.fetchLocations;
        axios.post(url, {})
            .then(response => {
                dispatch(updateLocationsLoading(false));
                console.log("Locations Response", response)
                let updatedArray = [];
                if (response.data.responseData.length > 0) {
                    response.data.responseData.map((location) => {
                        updatedArray.push(Object.assign({}, location, { label: location.name, value: location.id }))
                    })
                    storeKey("locations", updatedArray)
                    dispatch(updateLocationsAvailable(true));
                }
                else {
                    retrieveKey('locations')
                        .then(data => {
                            if (data.length > 0) {
                                updatedArray = data
                                dispatch(updateLocationsAvailable(true));
                            }
                            else {
                                dispatch(updateLocationsAvailable(false));
                            }
                        })
                        .catch(err => {
                            dispatch(updateLocationsAvailable(false));
                        })
                }
                dispatch(updateLocations(updatedArray))
            })
            .catch(err => {
                console.log("Locations Error --->", err)
                dispatch(updateLocationsLoading(false));
                retrieveKey('locations')
                    .then(data => {
                        if (data.length > 0) {
                            dispatch(updateLocationsAvailable(true));
                            dispatch(updateLocations(data))
                        }
                        else {
                            dispatch(updateLocationsAvailable(false));
                        }
                    })
                    .catch(err => {
                        dispatch(updateLocationsAvailable(false));
                    })
            })
    }
}


export function registerBusiness(data, success, error) {
    return function (dispatch, getState) {
        let url = urls.registerBusiness
        console.log("Input Data For Register Business", data);
        axios.post(url, data)
            .then(response => {
                console.log("Register Business Response Data", response)
                if (response.data.statusCD === 200) {
                    success("Request sent", response.data.responseData)
                }
                else if (response.data.statusCD === 404) {
                    success("Request already received", response.data.responseData)
                }
                else {
                    error("Faild to send", response)
                }
            })
            .catch(err => {
                console.log("Register Business Error --->", err)
                error(err)
            })
    }
}

export function fetchTripsForBusiness() {
    return function (dispatch, getState) {
        dispatch(updateBookingsLoading(true));
        let url = urls.fetchBidsForBusiness
        let accountId = getState().user.userDetails.accountId
        let input = {
            "accountId": accountId
        }
        let bookings = []
        console.log("Input Data For Fetch Bids", input);
        axios.post(url, input)
            .then(response => {
                dispatch(updateBookingsLoading(false));
                console.log("Fetch Bids Response Data", response);
                if (response.data.statusCD === 200) {
                    if (response.data.responseData.length > 0) {
                        bookings = response.data.responseData;
                        dispatch(updateBookings(bookings))
                        dispatch(updateBookingsAvailable(true))
                    }
                    else {
                        dispatch(updateBookings(bookings))
                        dispatch(updateBookingsAvailable(false))
                    }
                }
                else {
                    dispatch(updateBookings([]))
                    dispatch(updateBookingsAvailable(false))
                }
            })
            .catch(err => {
                dispatch(updateBookingsLoading(false));
                dispatch(updateBookingsAvailable(false))
                dispatch(updateBookings([]))
            })
    }
}

export function fetchPaymentsForBusiness(data) {
    return function (dispatch, getState) {
        dispatch(updatePaymentsLoading(true));
        let url = urls.fetchRideAmountsWithPaymentsForBusiness
        let accountId = getState().user.userDetails.accountId
        let input = {
            "accountId": accountId,
            "rideStartDate": data.startDate,
            "rideEndDate": data.endDate
        }
        let payments = []
        console.log("Input Data For Fetch Bids", input);
        axios.post(url, input)
            .then(response => {
                dispatch(updatePaymentsLoading(false));
                console.log("Fetch Bids Response Data", response);
                if (response.data.statusCD === 200) {
                    if (response.data.responseData.length > 0) {
                        payments = response.data.responseData;
                        dispatch(updatePayments(payments))
                        dispatch(updatePaymentsAvailable(true))
                    }
                    else {
                        dispatch(updatePayments(payments))
                        dispatch(updatePaymentsAvailable(false))
                    }
                }
                else {
                    dispatch(updatePayments([]))
                    dispatch(updatePaymentsAvailable(false))
                }
            })
            .catch(err => {
                dispatch(updatePaymentsLoading(false));
                dispatch(updatePaymentsAvailable(false))
                dispatch(updatePayments([]))
            })
    }
}

export function fetchBusinessProfile() {
    return function (dispatch, getState) {
        dispatch(updateProfileLoading(true))
        let url = urls.fetchBusinessProfile
        let mobileNo = getState().user.userDetails.mobileNo
        let input = {
            "mobileNo": Number(mobileNo)
        }
        let profileData = {}
        console.log("Input Data For Fetch Profile", input);
        axios.post(url, input)
            .then(response => {
                dispatch(updateProfileLoading(false))
                console.log("Fetch Profile Response Data", response);
                if (response.data.statusCD === 200) {
                    profileData = response.data.responseData;
                    dispatch(updateProfile(profileData))
                    dispatch(updateProfileAvailable(true))
                }
                else {
                    dispatch(updateProfile({}))
                    dispatch(updateProfileAvailable(false))
                }
            })
            .catch(err => {
                dispatch(updateProfileLoading(false))
                dispatch(updateProfileAvailable(false))
                dispatch(updateProfile({}))
            })
    }
}

export function fetchPartnerBookings() {
    return function (dispatch, getState) {
        dispatch(updatePartnerBookingsLoading(true))
        let url = urls.fetchPartnerData
        let accountId = getState().user.userDetails.accountId
        let input = {
            "accountId": accountId
        }
        let partnerBookings = []
        console.log("Input Data For Fetch Partner Data", input);
        axios.post(url, input)
            .then(response => {
                dispatch(updatePartnerBookingsLoading(false))
                console.log("Fetch Partner Data Response Data", response);
                if (response.data.statusCD === 200) {
                    if (response.data.responseData.length > 0) {
                        partnerBookings = response.data.responseData;
                        dispatch(updatePartnerBookings(partnerBookings))
                        dispatch(updatePartnerBookingsAvailable(true))
                    }
                    else {
                        dispatch(updatePartnerBookings(partnerBookings))
                        dispatch(updatePartnerBookingsAvailable(false))
                    }
                }
                else {
                    dispatch(updatePartnerBookings(partnerBookings))
                    dispatch(updatePartnerBookingsAvailable(false))
                }
            })
            .catch(err => {
                dispatch(updatePartnerBookingsLoading(false))
                dispatch(updatePartnerBookingsAvailable(false))
                dispatch(updatePartnerBookings(partnerBookings))
            })
    }
}

export function fetchProductNamesBusiness() {
    return function (dispatch, getState) {
        let url = urls.fetchProductNamesBusiness
        let makers = [], models = [], categories = [], subCategories = []
        let carsData = { makers, models, categories, subCategories }
        console.log("Input Data For Fetch Product Names Business", {});
        axios.post(url, {})
            .then(response => {
                console.log("Fetch Product Names Business Response Data", response);
                if (response.data.statusCD === 200) {
                    if (response.data.responseData.length > 0) {
                        let data = response.data.responseData;
                        data.map((item) => {
                            makers.push({ "label": item.makeDescription, "value": item.make })
                            models.push({ "label": item.modelDescription, "value": item.model })
                            categories.push({ "label": item.categoryDescription, "value": item.categoryId })
                            subCategories.push({ "label": item.subCategoryDescription, "value": item.subCategoryId })
                        })
                        makers = _.uniqBy(makers, "label")
                        models = _.uniqBy(models, "label")
                        categories = _.uniqBy(categories, "label")
                        subCategories = _.uniqBy(subCategories, "label")
                        console.log("Maker", makers, "\nModel", models, "\nCategory", categories, "\nSub Category", subCategories)
                        carsData = { makers, models, categories, subCategories }
                        dispatch(updateCarsData(carsData))
                    }
                    else {
                        console.log("Fetch Product Names Business Error")
                    }
                }
                else {
                    console.log("Fetch Product Names Business Error")
                }
            })
            .catch(err => {
                console.log("Fetch Product Names Business Error", err)
            })
    }
}

export function fetchProductBusiness() {
    return function (dispatch, getState) {
        dispatch(updateProductLoading(true))
        let url = urls.fetchProductBusiness
        let accountId = getState().user.userDetails.accountId
        let input = accountId
        let cars = []
        console.log("Input Data For Fetch Product Business", input);
        axios.post(url, input, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                dispatch(updateProductLoading(false))
                console.log("Fetch Product Business Response Data", response);
                if (response.data.statusCD === 200) {
                    if (response.data.responseData.length > 0) {
                        cars = response.data.responseData;
                        dispatch(updateCars(cars))
                        dispatch(updateProductAvailable(true))
                    }
                    else {
                        dispatch(updateCars(cars))
                        dispatch(updateProductAvailable(false))
                    }
                }
                else {
                    dispatch(updateCars(cars))
                    dispatch(updateProductAvailable(false))
                }
            })
            .catch(err => {
                dispatch(updateProductLoading(false))
                dispatch(updateProductAvailable(false))
                dispatch(updateCars(cars))
            })
    }
}

export function fetchDriversForBusiness() {
    return function (dispatch, getState) {
        dispatch(updateDriversLoading(true))
        let url = urls.fetchDriversForBusiness
        let accountId = getState().user.userDetails.accountId
        let input = {
            "accountId": accountId
        }
        let drivers = []
        console.log("Input Data For Fetch Drivers For Business", input);
        axios.post(url, input)
            .then(response => {
                dispatch(updateDriversLoading(false))
                console.log("Fetch Drivers For Business Response Data", response);
                if (response.data.statusCD === 200) {
                    if (response.data.responseData.length > 0) {
                        drivers = response.data.responseData;
                        dispatch(updateDrivers(drivers))
                        dispatch(updateDriversAvailable(true))
                    }
                    else {
                        dispatch(updateDrivers(drivers))
                        dispatch(updateDriversAvailable(false))
                    }
                }
                else {
                    dispatch(updateDrivers(drivers))
                    dispatch(updateDriversAvailable(false))
                }
            })
            .catch(err => {
                dispatch(updateDriversLoading(false))
                dispatch(updateDriversAvailable(false))
                dispatch(updateDrivers(drivers))
            })
    }
}

export function fetchProductPricingBusiness(data) {
    return function (dispatch, getState) {
        let url = urls.fetchProductPricingBusiness
        let productPricing = []
        console.log("Input Data For Fetch Product Pricing Business", data);
        axios.post(url, data)
            .then(response => {
                console.log("Fetch Product Pricing Business Response Data", response);
                if (response.data.statusCD === 200) {
                    if (response.data.responseData.length > 0) {
                        productPricing = response.data.responseData;
                        dispatch(updateProductPricing(productPricing))
                    }
                    else {
                        dispatch(updateProductPricing(productPricing))
                        alert("Pricing not available. Please modify your search")
                    }
                }
                else {
                    dispatch(updateProductPricing(productPricing))
                    alert("Cannot fetch pricing at the moment")
                }
            })
            .catch(err => {
                dispatch(updateProductPricing(productPricing))
                alert("Cannot fetch pricing at the moment")
            })
    }
}

export function fetchVendorBidsBusiness(data) {
    return function (dispatch, getState) {
        let url = urls.fetchVendorBidsBusiness
        let accountId = getState().user.userDetails.accountId
        let input = {
            "accountId": accountId,
            "startDate": data.startDate,
            "endDate": data.endDate
        }
        let payments = []
        console.log("Input Data For Fetch Vendor Bids Business", input);
        axios.post(url, input)
            .then(response => {
                console.log("Fetch Vendor Bids Business Response Data", response);
                if (response.data.statusCD === 200) {
                    if (response.data.responseData.length > 0) {
                        payments = response.data.responseData;
                        dispatch(updateVendorPayments(payments))
                    }
                    else {
                        dispatch(updateVendorPayments(payments))
                    }
                }
                else {
                    dispatch(updateVendorPayments([]))
                }
            })
            .catch(err => {
                dispatch(updateVendorPayments([]))
            })
    }
}



