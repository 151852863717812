import tw from "tailwind.macro"
import React from "react"
import styled from "@emotion/styled"
import BackgroundImage from "../images/vitor-pinto-BsRXRLiwcVg-unsplash.jpg"
import { css } from '@emotion/core'
import { connect } from "react-redux"
import { navigate } from 'gatsby';
import SliderModal from "../components/SliderModal"
import Overlay from "../components/Overlay"
import axios from 'axios'
import { urls as urls } from "../api/urls"
import {updateUserDetails} from "../actions/userAction"

const Wrapper = styled.div`
  ${tw`flex items-center relative justify-center content-center w-full flex-col h-screen py-16 sm:py-16 md:py-16 lg:py-0 lg:h-screen px-10 sm:px-10 md:px-10 lg:px-20 xl:px-20`}
  z-index: 1 !important;
`
const FormContainer = styled.div`
  ${tw`w-full md:w-1/2 relative lg:w-1/2 bg-white items-center justify-center content-center border-2 border-gray-800 rounded-lg`}
`
const ParallaxBgHolder = styled.div`
    ${tw`absolute h-screen w-full`};
    ${'' /* top: -30%; */}
    right: 0px;
    left: 0px;
    z-index: -1;
    background-image: url(${BackgroundImage});
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: cover;
    @media (min-width: 992px){
        ${'' /* top: -12%; */}
        background-position: center; 
        background-size: cover;
    }

    ::after{
        content: "";
        position: relative;
        display: block;
        z-index: 1;
        ${tw`h-screen w-full`};
        top: 0px;
        left: 0px;
        background-image: linear-gradient(to bottom, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.2) 85%, rgba(0,0,0,1) 100%);
        background-blend-mode: multiply;
        -webkit-background-blend-mode: multiply;
        -moz-background-blend-mode: multiply;
        -o-background-blend-mode: multiply;
    }
`
const HeadingContainer = tw.div`
text-center w-full
`
const Heading = tw.h1`
text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl font-black pt-5 pb-5
`
const FormFieldContainer = tw.div`
lg:flex justify-start items-center content-center flex-row w-full px-4 py-2 sm:py-2 md:py-4 lg:py-4
`
const InputLabel = tw.p`
text-lg sm:text-xl md:text-xl lg:text-xl xl:text-xl font-medium px-0 lg:px-5 py-2 w-full lg:w-2/5
`
const InputText = tw.input`
w-full lg:w-3/5 text-lg sm:text-xl md:text-xl lg:text-xl xl:text-xl font-medium bg-gray-300 px-2 lg:px-5 py-2 appearance-none outline-none shadow-none border-none
`
const PasswordLink = tw.a`
flex justify-end text-sm sm:text-sm md:text-sm lg:text-sm font-medium px-4 py-2 appearance-none outline-none shadow-none border-none
`
const ButtonContainer = tw.div`
flex py-5 w-full justify-center px-4
`
const SubmitButton = styled.button`
${tw`w-full sm:w-1/2 md:w-1/4 lg:w-1/4 py-2 text-white text-base lg:text-lg font-black self-center appearance-none outline-none shadow-none border-none text-center`}
  background-color: #8a3df6;
`
const TogglePassword = styled.span`
${tw` absolute self-center text-lg sm:text-xl md:text-xl lg:text-xl xl:text-xl py-1 lg:py-2 px-4`}
right: 0;
`
const TabContainer = styled.div`
${tw`flex w-full justify-center text-center items-center content-center`}
`
const Tab = styled.div`
${tw`flex w-1/2 py-5 text-center justify-center cursor-pointer text-lg sm:text-xl md:text-xl lg:text-xl xl:text-xl font-medium`}
`

const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/

class LoginForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            emailError: false,
            passwordError: false,
            showPassword: false,
            isCompany: true,
            showOverlay: false
        }
    }

    onChange = (state, value) => {
        this.setState({ [state]: value })
    }

    validate = (state) => {
        if(!(reg.test(this.state.email)) && state === "email"){
            this.setState({ emailError: true})
        }
        else if(state === "email"){
            this.setState({ emailError: false})
        }
        if(this.state.password === "" && state === "password"){
            this.setState({ passwordError: true})
        }
        else if(state === "password"){
            this.setState({ passwordError: false})
        }
    }

    handleLogin = () => {
        let emailError = false, passwordError= false
        if(!(reg.test(this.state.email))){
            emailError = true
            this.setState({ emailError: emailError})
        }
        if(this.state.password === ""){
            passwordError= true
            this.setState({ passwordError: passwordError})
        }
        if(emailError || passwordError){
            alert("Please fill the fields correctly")
        }
        else{
            let url = urls.loginBusinessUser
            let data = {
                "emailId": this.state.email,
                "password": this.state.password
            }
            console.log("Login URL", url, "\nLogin Input Data", data)
            axios.post(url, data)
            .then(response => {
                console.log("Login Business Response",response)
                if (response.data.statusCD == 200) {
                    let userDetails = response.data.responseData[0]
                    this.props.dispatch(updateUserDetails(userDetails))
                    window.localStorage.setItem('businessData', JSON.stringify({ partner: !this.state.isCompany, userDetails: userDetails }));
                    navigate(`/booking`, { state: { partner: !this.state.isCompany } })
                }
                else if(response.data.statusCD == 404){
                    alert("Invalid email or password. Please try again.")
                }
                else{
                    alert("Cannot login at the moment. Please try again.")
                }
            })
            .catch(error => {
                console.log("Login Business Error", error)
                alert("Cannot login at the moment. Please try again.")
            })
        }
    }

    handleForgotPassword = () => {
        window.document.getElementsByTagName("body")[0].style.overflow = "hidden"
        this.setState({ showOverlay: true })
    }

    handleClose = () => {
        window.document.getElementsByTagName("body")[0].style.overflow = "auto"
        this.setState({ showOverlay: false })
    }

    render() {
        return (
            <Wrapper>
                <FormContainer>
                <TabContainer>
                    <Tab css={css`${this.state.isCompany ? tw`border-r border-gray-400 text-purple-800` : tw`border-b border-gray-400 text-gray-500`} box-shadow: ${this.state.isCompany ? '15px -8px 15px 0px #88888826' : 'inset 0 -15px 15px -10px #00000030'};`} onClick={()=>{this.setState({ isCompany: true })}}>
                    <label css={css`${tw`cursor-pointer`}`}>Company</label>
                    </Tab>
                    <Tab css={css`${this.state.isCompany ? tw`border-b border-gray-400 text-gray-500` : tw`border-l border-gray-400 text-purple-800`} box-shadow: ${this.state.isCompany ? 'inset 0 -15px 15px -10px #00000030' : '-15px -8px 15px 0px #88888826' };`} onClick={()=>{this.setState({ isCompany: false })}}>
                    <label css={css`${tw`cursor-pointer`}`}>Partner</label>
                    </Tab>
                </TabContainer>
                    <HeadingContainer><Heading>Log In</Heading></HeadingContainer>
                    <FormFieldContainer>
                        <InputLabel style={{ color: (this.state.emailError ? 'red' : 'black') }}>{this.state.isCompany ? 'Company Email' : 'Partner Email'}</InputLabel>
                        <InputText type="email" onChange={(e) => { this.onChange("email", e.target.value) }} onBlur={() => {this.validate('email')}}/>
                    </FormFieldContainer>
                    <FormFieldContainer>
                        <InputLabel style={{ color: (this.state.passwordError ? 'red' : 'black') }}>Password</InputLabel>
                        <InputText type={this.state.showPassword ? 'text' : 'password'} onChange={(e) => { this.onChange("password", e.target.value) }} onBlur={() => {this.validate('password')}}/>
                        <TogglePassword onClick={()=> { this.setState({ showPassword: !this.state.showPassword})}}>
                            <img css={css`${tw`h-10`}`} src={require(this.state.showPassword ? "../images/password-eye-hide.png" : "../images/password-eye-show.png")} alt="toggle-password" />
                        </TogglePassword>
                    </FormFieldContainer>
                    <PasswordLink><span css={css`${tw`cursor-pointer`}`} onClick={this.handleForgotPassword}>Forgot Password? </span></PasswordLink>
                    <ButtonContainer>
                        <SubmitButton onClick={this.handleLogin}>Log In</SubmitButton>
                    </ButtonContainer>
                </FormContainer>
                <ParallaxBgHolder />
                {/* DO NOT MOVE OVERLAY COMPONENT */}
                {this.state.showOverlay && <Overlay active={this.state.showOverlay} overlayClicked={this.handleClose} />}
                {this.state.showOverlay && <SliderModal active={this.state.showOverlay} heading={"Forgot Password"} close={this.handleClose}>
                <div css={css`${tw`block`}`}>
                <h1 css={css`${tw`font-bold text-black text-lg py-4`}`}>Please contact our support</h1>
                <p css={css`${tw`font-medium text-black text-lg py-4`}`}>Call Us</p>
                <a href="tel:+918688901902" css={css`${tw`font-normal text-black text-base py-4`}`}>+91 8688 901 902</a>
                <p css={css`${tw`font-medium text-black text-lg py-4`}`}>Email Us</p>
                <a href="mailto:support@zyppys.com" css={css`${tw`font-normal text-black text-base py-4`}`}>support@zyppys.com</a>
                </div>
                </SliderModal>}
            </Wrapper>
        )
    }

}

const mapStateToProps = (state) => ({
    // locations: state.app.locations
})

export default connect(mapStateToProps)(LoginForm);