import tw from "tailwind.macro"
import React from "react"
import styled from "@emotion/styled"
import { css } from '@emotion/core'
import { connect } from "react-redux"
import { navigate } from 'gatsby';
import AccordionItem from "./AccordionItem"
import Moment from "moment"
import Popup from 'reactjs-popup'
import {urls as urls} from "../api/urls"
import axios from "axios"
import Dialog from "./Dialog"

const Wrapper = styled.div`
  ${tw`flex items-start relative justify-start content-center w-full flex-col h-full py-16 sm:py-16 md:py-16 lg:py-0 px-4 sm:px-4 md:px-4 lg:px-10 xl:px-10`}
  z-index: 1 !important;
`
const ItineraryLine = styled.div`
${tw`w-full flex flex-wrap justify-between py-3 lg:py-4 px-6`}
`
const ItineraryText = styled.p`
${tw`w-full my-1 font-sans text-gray-800`}
`
const MainTitle = styled.h2`
        opacity: 0.9;
        font-family: ProximaNova-Extrabld;
        @media (min-width: 992px){
            font-size: 36.65px;
        }
        font-size: 1.618rem;
        color: #222222;
        letter-spacing: -1.18px;
`
const Row = styled.div`
 ${tw`w-full flex flex-wrap justify-between items-center lg:mx-auto`};
`
const CarImage = styled.img`
    ${tw`-ml-2 my-0`}
 height: 20vh;
`
const CarTitle = styled.h3`
${tw`-mt-2 mb-0 lg:my-1`}
        opacity: 0.77;
        font-family: Chromatica-Bold;
        font-size: 25.89px;
        color: #222222;
        letter-spacing: -0.83px;
`
const CarSpecs = styled.p`
opacity: 0.5;
        font-family: ProximaNova-Regular;
        color: #222222;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        margin-right: 7.5px;
        border-box: border;
        font-size: 11px;

        vertical-align: middle;
        &:after{
            display: inline-block;
            content: "|";
            margin-left: 7.5px;
            
        }
        &:last-child:after{
            opacity: 0;
        }
        @media(min-width: 992px){
            font-size: 14px;

        }
`
const OrderNo = styled.h3`
   ${tw`mb-2`}
        opacity: 0.9;
        font-family: Chromatica-Bold;
        @media(min-width: 992px){
            font-size: 30.65px;
        }
        font-size: 18px;
        color: #222222;
        letter-spacing: -1.18px;
`
const RadioButton = styled.span`
${tw`flex h-6 w-6 rounded-full border-2 border-purple-500`}
min-width: 1.5rem;
min-height: 1.5rem;
`
const RadioText = styled.span`
${tw`flex ml-2 font-normal text-gray-800 text-sm`}
`

class CarConfirmed extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            bookingStatus: 'Confirmed',
            cancelReason: '',
            showDialog: false
        }
    }

    cancelRide = () => {
        window.document.getElementsByTagName("body")[0].style.overflow = "auto"
        this.setState({ showDialog: false })
        let url = urls.cancelUrl;
        let data = {
            "bidId": this.props.app.bidId,
            "cancelReason": this.state.cancelReason
        }
        console.log(data);
        axios.post(url, data)
            .then(response => {
                console.log(response);
                if (response.data.statusCD == 200 && response.data.statusMSG == "Cancellation Successful") {
                    alert('Your order has been cancelled successfully');
                    this.setState({ bookingStatus: 'Cancelled' });
                }
                else {
                    alert('Sorry! cannot cancel your ride at this moment');
                }
            })
            .catch(err => {
                alert('Sorry! cannot cancel your ride at this moment');
                console.log(err);
            })
    }

    render() {
        console.log(this.state.bookingStatus);
        let selectedCar = this.props.selectedCar;
        let app = this.props.app;
        let ride = this.props.ride
        return (
            <Wrapper>
                <Row>
                    <div css={css`${tw`w-full lg:w-1/2 lg:my-4 my-1`}`}>
                        <div css={css`${tw`w-full flex items-center`}`}>
                            <MainTitle>
                                Booking {this.state.bookingStatus}
                            </MainTitle>
                            <img css={css`${tw`mx-4`}`} src={require('../svgs/confirmed-check.svg')} />
                        </div>
                        <span css={css`${tw`block text-sm opacity-50 font-sans tracking-normal`}`}>
                            You've booked from {selectedCar.account_name}
                        </span>
                    </div>
                </Row>

                <Row>
                    <div css={css`${tw`w-full lg:w-1/2 py-2`}`}>
                        <CarImage src={selectedCar.sub_category_url} />
                        <CarTitle>{selectedCar.sub_category}</CarTitle>
                        <div css={css`${tw`flex mx-auto`}`}>
                            <CarSpecs>
                                {selectedCar.no_of_seats} SEATS
                                </CarSpecs>
                        </div>
                    </div>
                    <div css={css`${tw`w-full lg:w-1/2 py-2 lg:text-right`}`}>
                        <OrderNo>Order #{app.bidId}</OrderNo>
                        <p css={css`${tw`text-sm text-grey-darker`}`}>Price, &#8377; {Math.round(selectedCar.ride_amt).toLocaleString()} </p>
                    </div>
                </Row>

                <Row>
                    <AccordionItem title={'Itinerary'}>
                        <ItineraryLine>
                            <ItineraryText css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800`}`}>Pickup City</ItineraryText>
                            <ItineraryText css={css`${tw`font-sans w-full md:w-auto font-bold text-gray-600`}`}>{ride.pickup.city}</ItineraryText>
                        </ItineraryLine>
                        <ItineraryLine>
                            <ItineraryText css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800`}`}>Pickup Location</ItineraryText>
                            <ItineraryText css={css`${tw`font-sans w-full md:w-auto font-bold text-gray-600`}`}>{ride.pickup.loc}</ItineraryText>
                        </ItineraryLine>
                        {!app.isRental && !app.isAirport && <ItineraryLine>
                            <ItineraryText css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800`}`}>Destination City</ItineraryText>
                            <ItineraryText css={css`${tw`font-sans w-full md:w-auto font-bold text-gray-600`}`}>{ride.dest.city}</ItineraryText>
                        </ItineraryLine>}
                        {!app.isRental && <ItineraryLine>
                            <ItineraryText css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800`}`}>Destination Location</ItineraryText>
                            <ItineraryText css={css`${tw`font-sans w-full md:w-auto font-bold text-gray-600`}`}>{ride.dest.loc}</ItineraryText>
                        </ItineraryLine>}
                        <ItineraryLine>
                            <ItineraryText css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800`}`}>Start Date & Time</ItineraryText>
                            <ItineraryText css={css`${tw`font-sans w-full md:w-auto font-bold text-gray-600`}`}>{Moment(ride.chauffeurStartDate, "DD-MM-YYYY").format("DD MMM,YYYY")}&nbsp;{Moment(ride.startTime, "HH:mm:ss").format("hh:mm A")}</ItineraryText>
                        </ItineraryLine>
                        {ride.measureLookupId == "100010001" && <ItineraryLine>
                            <ItineraryText css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800`}`}>End Date & Time</ItineraryText>
                            <ItineraryText css={css`${tw`font-sans w-full md:w-auto font-bold text-gray-600`}`}>{Moment(ride.chauffeurEndDate, "DD-MM-YYYY").format("DD MMM,YYYY")}&nbsp;{Moment(ride.endTime, "HH:mm:ss").format("hh:mm A")}</ItineraryText>
                        </ItineraryLine>}
                    </AccordionItem>
                    <AccordionItem title={'Traveller details'}>
                        <ItineraryLine>
                            <ItineraryText css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800`}`}>Namee</ItineraryText>
                            <ItineraryText css={css`${tw`font-sans w-full md:w-auto font-bold text-gray-600`}`}>{app.user.name}</ItineraryText>
                        </ItineraryLine>
                        <ItineraryLine>
                            <ItineraryText css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800`}`}>Mobile Number</ItineraryText>
                            <ItineraryText css={css`${tw`font-sans w-full md:w-auto font-bold text-gray-600`}`}>{app.user.phone}</ItineraryText>
                        </ItineraryLine>
                        <ItineraryLine>
                            <ItineraryText css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800`}`}>Email</ItineraryText>
                            <ItineraryText css={css`${tw`font-sans w-full md:w-auto font-bold text-gray-600`}`}>{app.user.email}</ItineraryText>
                        </ItineraryLine>
                    </AccordionItem>
                    <AccordionItem title="Important Terms You Should Know">
                        {
                            selectedCar.hasOwnProperty('terms_conditions')
                                ?
                                selectedCar.terms_conditions.map((terms) => {
                                    return (
                                        <p css={css`${tw`block w-full px-6 leading-loose text-gray-800`}`}>*&nbsp;{terms}</p>
                                    );
                                })
                                :
                                selectedCar.hasOwnProperty("terms_conditions_url") && <a href={selectedCar.terms_conditions_url}>{selectedCar.terms_conditions_url}</a>
                        }
                    </AccordionItem>
                    <AccordionItem title="Price Breakdown">
                        <ItineraryLine>
                            <p css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800`}`}>Base Fare
                                            {selectedCar.package_kms * selectedCar.actual_cost_per_km == selectedCar.base_fare && <span css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800 block opacity-50 py-2`}`}> {selectedCar.package_kms.toLocaleString()} Kms x &#8377; {selectedCar.actual_cost_per_km.toLocaleString()}</span>}
                            </p>
                            <p css={css`${tw`font-sans w-full md:w-auto font-bold text-gray-600`}`}>&#8377; {Math.round(selectedCar.base_fare).toLocaleString()}</p>
                        </ItineraryLine>
                        {selectedCar.hasOwnProperty("driver_charges_per_day") && selectedCar.driver_charges_per_day != null && selectedCar.driver_charges_per_day.toLocaleString() &&
                            <ItineraryLine>
                                <p css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800`}`}>Driver Charges
                                        <span css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800 block opacity-50 py-2`}`}>&#8377; {selectedCar.driver_charges_per_day.toLocaleString()} x {selectedCar.no_of_days} Days </span>
                                </p>
                                <p css={css`${tw`font-sans w-full md:w-auto font-bold text-gray-600`}`}>&#8377; {Math.floor(selectedCar.driver_charges_per_day * selectedCar.no_of_days).toLocaleString()}</p>
                            </ItineraryLine>}
                        {selectedCar.hasOwnProperty("night_charges") && selectedCar.night_charges != null && selectedCar.night_charges > 0 &&
                            <ItineraryLine>
                                <p css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800`}`}>Night Charges </p>
                                <p css={css`${tw`font-sans w-full md:w-auto font-bold text-gray-600`}`}>&#8377; {selectedCar.night_charges.toLocaleString()} </p>
                            </ItineraryLine>
                        }
                        {selectedCar.hasOwnProperty("service_tax_amt") && selectedCar.service_tax_amt != null && parseInt(selectedCar.service_tax_amt) > 0 &&
                            <ItineraryLine>
                                <p css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800`}`}>GST</p>
                                <p css={css`${tw`font-sans w-full md:w-auto font-bold text-gray-600`}`}>&#8377; {selectedCar.service_tax_amt.toLocaleString()}</p>
                            </ItineraryLine>
                        }
                        {selectedCar.hasOwnProperty("state_tax") && selectedCar.state_tax > 0 && parseInt(selectedCar.state_tax) > 0 &&
                            <ItineraryLine>
                                <p css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800`}`}>Border Tax</p>
                                <p css={css`${tw`font-sans w-full md:w-auto font-bold text-gray-600`}`}>&#8377; {selectedCar.state_tax.toLocaleString()}</p>
                            </ItineraryLine>
                        }
                        {selectedCar.hasOwnProperty("service_charges") && selectedCar.service_charges != null && parseInt(selectedCar.service_charges) > 0 &&
                            <ItineraryLine>
                                <p css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800`}`}>Service Charges</p>
                                <p css={css`${tw`font-sans w-full md:w-auto font-bold text-gray-600`}`}>&#8377; {selectedCar.service_charges.toLocaleString()}</p>
                            </ItineraryLine>
                        }
                        {selectedCar.hasOwnProperty("toll_tax") && selectedCar.toll_tax != null && parseInt(selectedCar.toll_tax) > 0 &&
                            <ItineraryLine>
                                <p css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800`}`}>Tolls</p>
                                <p css={css`${tw`font-sans w-full md:w-auto font-bold text-gray-600`}`}>&#8377; {selectedCar.toll_tax.toLocaleString()}</p>
                            </ItineraryLine>
                        }
                        {selectedCar.hasOwnProperty("extra_cost_per_km") && selectedCar.extra_cost_per_km != null && parseInt(selectedCar.extra_cost_per_km) > 0 &&
                            <ItineraryLine>
                                <p css={css`${tw`font-sans w-full md:w-auto font-medium text-gray-800`}`}>Extra Cost per Km</p>
                                <p css={css`${tw`font-sans w-full md:w-auto font-bold text-gray-600`}`}>&#8377; {selectedCar.extra_cost_per_km.toLocaleString()}</p>
                            </ItineraryLine>
                        }
                    </AccordionItem>
                </Row>
                <Row>
                {this.state.bookingStatus == "Confirmed" && <div css={css`${tw`w-full font-medium text-gray-800 rounded lg:w-1/5 lg:my-4 py-4 border-2 border-solid border-purple-500 items-center justify-center cursor-pointer px-4 text-center text-purple-500 hover:bg-purple-500 hover:text-white`}`} onClick={()=>{this.setState({ showDialog: true }); window.document.getElementsByTagName("body")[0].style.overflow = "hidden"}}>Cancel Order</div>}
                    {this.state.showDialog && <Dialog heading="Cancel Order" buttonOneText="No" buttonTwoText="Yes" buttonOnePressed={() => { this.setState({ showDialog: false });window.document.getElementsByTagName("body")[0].style.overflow = "auto" }} buttonTwoPressed={this.cancelRide} >
                        <p>Are you sure you want to cancel this order? You may be charged a slight fee for this cancellation.</p>
                    </Dialog>}
                {/* {this.state.bookingStatus == "Confirmed" && <Popup
                        trigger={<div css={css`${tw`w-full font-medium text-gray-800 rounded lg:w-1/5 lg:my-4 py-4 border-2 border-solid border-grey-light  items-center justify-center cursor-pointer px-4 text-center hover:bg-gray-500`}`}>Cancel Order</div>}
                        position="top center"
                        closeOnDocumentClick >

                        <div css={css`${tw`block w-full p-2`}`}>
                            <div css={css`${tw`flex items-center py-2`}`}>
                                <RadioButton css={css`${this.state.cancelReason == 'CP' ? tw`bg-purple-500` : tw`bg-transparent`}`} onClick={(e) => { this.setState({ cancelReason: 'CP' }) }} />
                                    <RadioText>Change of plan</RadioText>
                            </div>
                            <div css={css`${tw`flex items-center py-2`}`}>
                                <RadioButton css={css`${this.state.cancelReason == 'PC' ? tw`bg-purple-500` : tw`bg-transparent`}`} onClick={(e) => { this.setState({ cancelReason: 'PC' }) }} />
                                    <RadioText>Price Comparison</RadioText>
                            </div>
                            <div css={css`${tw`flex items-center py-2`}`}>
                                <RadioButton css={css`${this.state.cancelReason == 'NR' ? tw`bg-purple-500` : tw`bg-transparent`}`} onClick={(e) => { this.setState({ cancelReason: 'NR' }) }} />
                                    <RadioText>No Response from Service Provider</RadioText>
                            </div>
                            <div css={css`${tw` w-full`}`}>
                                <div onClick={() => this.cancelRide()} css={css`${tw`flex font-medium text-gray-800 rounded py-2 border-2 border-solid border-purple-500 items-center justify-center cursor-pointer px-4 text-center hover:bg-purple-500 hover:text-white`}`}>Proceed</div>
                            </div>
                        </div>

                    </Popup>} */}
                </Row>
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    selectedCar: state.app.selectedCar,
    app: state.app,
    ride: state.ride
})

export default connect(mapStateToProps)(CarConfirmed);