import tw from "tailwind.macro"
import React from "react"
import styled from "@emotion/styled"
import BackgroundImage from "../svgs/mock-map.svg"
import { css } from '@emotion/core'
import { connect } from "react-redux"
import { navigate } from 'gatsby';
import * as rideActions from '../../src/actions/rideActions';
import * as appActions from '../../src/actions/appActions';
import StopsList from "../components/StopsList"
import DateTime from "../components/DateTime"

const Wrapper = styled.div`
  ${tw`flex items-center relative justify-start content-center w-screen flex-col h-full md:h-full lg:h-screen px-4 sm:px-4 md:px-4 lg:px-10`}
  z-index: 1 !important;
`
const FormContainer = styled.div`
  ${tw`w-full md:w-3/4 relative lg:w-1/2 items-center justify-center content-center mt-20 py-2 px-4 border shadow-lg rounded-lg`}
`
const ParallaxBgHolder = styled.div`
    ${tw`absolute h-screen w-full`};
    ${'' /* top: -30%; */}
    right: 0px;
    left: 0px;
    z-index: -1;
    ${'' /* background-image: url(${BackgroundImage}); */}
    background: #fbfbfb ;
    background-repeat: no-repeat;
    ${'' /* background-position: 50% 0%; */}
    background-size: cover;
    @media (min-width: 992px){
        ${'' /* top: -12%; */}
        background-position: center; 
        background-size: cover;
    }

    ::after{
        content: "";
        position: relative;
        display: block;
        z-index: 1;
        ${tw`h-screen w-full`};
        top: 0px;
        left: 0px;
        ${'' /* background-image: linear-gradient(to bottom, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.2) 85%, rgba(0,0,0,1) 100%); */}
        background-blend-mode: multiply;
        -webkit-background-blend-mode: multiply;
        -moz-background-blend-mode: multiply;
        -o-background-blend-mode: multiply;
    }
`
const CapsuleContainter = styled.div`
${tw`flex justify-between w-full items-center py-4`}
`
const Capsule = styled.div`
${tw`flex flex-grow text-xs sm:text-xs md:text-base lg:text-base font-normal px-2 sm:px-2 md:px-2 lg:px-8 py-2 rounded-full truncate cursor-pointer shadow-xl`}
${'' /* background-image: linear-gradient(-134deg,#3023ae,#c86dd7); */}
`
const InputContainer = styled.div`
${tw`block py-2`}
`
const FormFieldContainer = tw.div`
flex justify-start items-center content-center flex-row w-full py-2 sm:py-2 md:py-4 lg:py-4
`
const FirstInputField = styled.input`
${tw`w-11/12 text-lg sm:text-xl md:text-xl lg:text-xl xl:text-xl font-medium pl-12 py-2 appearance-none outline-none shadow-xl border-none`}
`
const CurrentLocation = styled.div`
${tw`px-2 lg:px-5 py-3 shadow-xl cursor-pointer`}
background-color: #8a3df6;
`
const InputField = styled.input`
${tw`w-full text-lg sm:text-xl md:text-xl lg:text-xl xl:text-xl font-medium bg-white pl-12 py-2 appearance-none outline-none shadow-xl border-none`}
`
const DestinationDot = styled.div`
${tw` absolute self-center text-lg sm:text-xl md:text-xl lg:text-xl xl:text-xl py-1 lg:py-2 px-4 cursor-pointer`}
background: #7d0ede;
box-shadow: 0 6px 8px -3px rgba(125,14,222,.7);
left: 0.85rem;
width: 1.75rem;
height: 1.75rem;
border-radius: 100px;
padding: 0.35rem 0.5rem;
color: #fff;
text-align: center;
position: absolute;
`
const ButtonContainer = styled.div`
${tw`flex w-full py-4 items-center justify-center`}
`
const Button = styled.div`
${tw`w-3/4 py-3 text-white text-base lg:text-lg font-normal self-center appearance-none outline-none shadow-xl border-none cursor-pointer text-center rounded-full`}
background-image: linear-gradient(-134deg,#3023ae,#c86dd7);
`
const StopsContainer = styled.div`
${tw`w-full`}
`
const DateTimeContainer = styled.div`
${tw`w-full`}
`

let coords;

class BookingForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    useGeolocation = () => {
        this.props.dispatch(appActions.geoLocationServiceRequest());
        let self = this;
        let options = {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        };
    
        navigator.geolocation.getCurrentPosition(success, error, options);
        function success(pos) {
          debugger
          self.props.dispatch(appActions.geoLocationServiceSuccess());
          let crd = pos.coords;
          coords = crd;
          let latitude = coords.latitude;
          let longitude = coords.longitude;
          self.props.dispatch(appActions.updateMapCoords({latitude,longitude}))
          self.props.dispatch(appActions.mountMap());
          let latlng = new window.google.maps.LatLng(coords.latitude,coords.longitude);
          // self.setState({LatLng:latlng});
          let geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({'location': latlng}, function(results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {
                debugger
                let flag = 0;
                let city = '';
                let result = results[0];
                self.props.dispatch(rideActions.updatePickupLocation({loc:result.formatted_address,locLat:result.geometry.location.lat(),locLng:result.geometry.location.lng()}));
                let types = ['locality','administrative_area_level_2','administrative_area_level_1'];
                for(let i=0;i<3;i++) {
                  for(let j=0;j<result.address_components.length;j++){
                    if(result.address_components[j].types[0] === types[i]){
                      city = result.address_components[j].long_name;
                      flag = 1;
                      break;
                    }
                  }
                  if(flag === 1){
                    self.props.dispatch(rideActions.fetchLocationByLatLong(city));
                    if(city === 'Secunderabad'){
                      city = 'Hyderabad';
                    }
                    if(city === "Bangalore" || city==="Bengaluru Rural" || city==="Bengaluru Urban" || city === "Bangalore Rural"|| city==="Bangalore Urban"){
                      city = "Bengaluru";
                    }
                    self.props.dispatch(rideActions.addFirstStop({city:city,name:result.formatted_address,latitude:result.geometry.location.lat(),longitude:result.geometry.location.lng()}));
                    geocoder.geocode({'address':city},function(result,status){
                      if (status === window.google.maps.GeocoderStatus.OK) {
                        debugger
                        let res = result[0];
                        self.props.dispatch(appActions.addFirstHotelCity({name:city,latitude:res.geometry.location.lat(),longitude:res.geometry.location.lng()}));
                      }
                    })
                    break;
                  }
                }
            }
    
            else {
                alert("Geocoder failed due to: " + status);
                self.props.dispatch(appActions.geoLocationServiceFail());
              }
          });
        }
    
        function error(err) {
          console.warn(`ERROR(${err.code}): ${err.message}`);
          if(err.code === 1) {
            alert("Please Enable Location Service to Locate You or Enter Pick Up Location Manually.");
            self.props.dispatch(appActions.geoLocationServiceFail());
            // self.setState({mountMap:false});
          }
          else if(err.code === 3){
            alert('Unable to Locate You at this Moment. Please Try Again or Enter Your Location Manually.');
            self.props.dispatch(appActions.geoLocationServiceFail());
          }
        }
      }

      handleFromChange = (data) => {
        debugger
        //do not remove this
        if(this.props.app.mountMap == false){
          this.props.dispatch(appActions.updateMapCoords({latitude:data.latitude,longitude:data.longitude}));
          this.props.dispatch(appActions.mountMap());
        }
        this.props.dispatch(rideActions.updatePickupLocation({loc:data.name,locLat:data.latitude,locLng:data.longitude}));
      }

  handleRentalType = (type) => {
    if (type === 'outstation') {
      this.props.dispatch(rideActions.setAirportTransferToFalse());
      this.props.dispatch(rideActions.setHourlyRentalToFalse());
    }
    else if (type === 'hourly') {
      this.props.dispatch(rideActions.setHourlyRentalToTrue());
      this.props.dispatch(rideActions.setAirportTransferToFalse());
    }
    else if (type === 'airport') {
      this.props.dispatch(rideActions.setAirportTransferToTrue());
      this.props.dispatch(rideActions.setHourlyRentalToFalse());
    }
  }

  handleSearch = () => {
    let ride = this.props.ride
    if(!ride.pickup.hasOwnProperty('loc') || (ride.pickup.hasOwnProperty('loc') && ride.pickup.loc.length < 2)){
      alert("Please enter the pickup location")
      return
    }
    if(this.props.app.isRental){
      if(ride.rentalType == 5 || ride.rentalType == 6){
        // SUCCESS
      }
      else{
        alert("Please choose a package")
        return
      }
    }
    if(!this.props.app.isRental){
      if(!ride.dest.hasOwnProperty('loc') || (ride.dest.hasOwnProperty('loc') && ride.dest.loc.length < 2)){
        alert("Please enter the destination location")
        return
      }
    }
    this.props.dispatch(rideActions.updateCarsData([]));
    this.props.dispatch(rideActions.setChauffeurDrivenToTrue());
    this.props.dispatch(rideActions.setSelfDrivenToFalse());
    this.props.dispatch(rideActions.fetchVehiclesForChauffeurDriven());
    navigate('cars', { state:{} })
  }
      
    render() {
        return (
            <Wrapper>
                <FormContainer>
                  <CapsuleContainter>
                      <Capsule css={css`${tw``}; background-image: ${this.props.app.isRental ? 'linear-gradient(-134deg,#3023ae,#c86dd7)' : 'linear-gradient(-134deg,#fff,#fff)'}; color: ${this.props.app.isRental ? 'white' : 'black' };`} onClick={()=>{this.handleRentalType('hourly')}}>Hourly Rental</Capsule>
                      <Capsule css={css`${tw``}; background-image: ${!this.props.app.isAirport && !this.props.app.isRental ? 'linear-gradient(-134deg,#3023ae,#c86dd7)' : 'linear-gradient(-134deg,#fff,#fff)'}; color: ${!this.props.app.isAirport && !this.props.app.isRental ? 'white' : 'black' };`} onClick={()=>{this.handleRentalType('outstation')}}>Outstation</Capsule>
                      <Capsule css={css`${tw``}; background-image: ${this.props.app.isAirport ? 'linear-gradient(-134deg,#3023ae,#c86dd7)' : 'linear-gradient(-134deg,#fff,#fff)'}; color: ${this.props.app.isAirport ? 'white' : 'black' };`} onClick={()=>{this.handleRentalType('airport')}}>Airport Transfer</Capsule>
                  </CapsuleContainter>
            {/* <InputContainer>
            <FormFieldContainer>
            <DestinationDot/>
                <FirstInputField placeholder="Enter a location"/>
                            <CurrentLocation onClick={this.useGeolocation}>
                                <img src={require("../svgs/location.svg")} css={css`${tw`h-5 md:h-6 lg:h-6`}`} alt="location" />
                            </CurrentLocation>
                </FormFieldContainer>
                <FormFieldContainer>
                <DestinationDot/>
                <InputField placeholder="Enter a location"/>
                </FormFieldContainer>
            </InputContainer> */}
            <StopsContainer>
            <StopsList useGeolocation={this.useGeolocation} handleFromChange={this.handleFromChange}/>
            </StopsContainer>
            <DateTimeContainer>
              <DateTime />
            </DateTimeContainer>
            <ButtonContainer>
            <Button onClick={this.handleSearch}>Proceed</Button>
            </ButtonContainer>
                </FormContainer>
                {/* <ParallaxBgHolder /> */}
            </Wrapper>
        )
    }

}

const mapStateToProps = (state) => ({
    app : state.app,
    ride:state.ride
})

export default connect(mapStateToProps)(BookingForm);