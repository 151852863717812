import React from 'react';
import Stop from './Stop';
import {connect} from 'react-redux';
import {addStop,addStopAtIndex,updateDestLocation,addFirstStop,editStop,updateDestCity,fetchLocationByLatLong,updatePickupLocation,removeStop,updateTripType, updateRideDistance, updateRideDuration} from '../actions/rideActions';
import {addFirstHotelCity,addHotelCity,editHotelCity,removeHotelCity} from '../actions/appActions';
import { css } from '@emotion/core'
import styled from "@emotion/styled"
import tw from "tailwind.macro"




class StopsList extends React.Component{
  constructor(props){
    super(props);
    this.state  = {
      reRender : false
    }
    this.addStop = this.addStop.bind(this);
    this.setTypeProp = this.setTypeProp.bind(this);
    this.addFirstStop = this.addFirstStop.bind(this);
    this.editStop = this.editStop.bind(this);
    this.updateDestCity = this.updateDestCity.bind(this);
    this.updateDestLocation = this.updateDestLocation.bind(this);
    this.getLeadTimeByCity = this.getLeadTimeByCity.bind(this);
    this.setDisableProp = this.setDisableProp.bind(this);
    this.removeStop = this.removeStop.bind(this);
    this.enableRemove = this.enableRemove.bind(this);
    this.addHotelCity = this.addHotelCity.bind(this);
    this.addFirstHotelCity = this.addFirstHotelCity.bind(this);
    this.editHotelCity = this.editHotelCity.bind(this);
  }


  shouldComponentUpdate(nextProps,nextState){
  debugger
    if(this.props.ride.stops.length != nextProps.ride.stops.length){
      return true;
    }
    if(this.state.reRender == nextState.reRender){
      return true;
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.ride.stops != this.props.ride.stops){
      if(this.props.ride.stops.length > 1){
        this.getDistanceBetweenStops()
      }
    }
  }

  getDistanceBetweenStops = () => {
    let self = this;
    if (this.props.ride.stops[0]==undefined && this.props.ride.stops.length <= 2 ){
        return;
    }
    let waypoints = []
    let stops = this.props.ride.stops;
    let origin = stops[0].name;
    let destination = stops[stops.length - 1].name;
    for(let i = 1; i< stops.length - 1; i++){
        waypoints.push(stops[i].name)
    }
    let request = {
      origin: origin,
      destination: destination,
      waypoints: waypoints,
      travelMode: 'DRIVING'
    };
    let directionsService= new window.google.maps.DirectionsService({});
    directionsService.route(request, function(result, status) {
      if (status == 'OK') {
            const route = result.routes[0];
            let distance = route.legs.reduce((carry, curr) => {
                return carry + curr.distance.value;
            }, 0) / 1000;
            let duration = route.legs.reduce((carry, curr) => {
                return carry + curr.duration.value;
            }, 0) / 3600;
            self.props.dispatch(updateRideDistance(parseInt(distance)));
            self.props.dispatch(updateRideDuration(parseInt(duration)));

        } else {
            console.log("error")
        }
    });

}

  setTypeProp(id){
    debugger
    if(id==1){
      return 'pickup';
    }
    else if(id==this.props.ride.stops.length){
      return 'final';
    }
    else{
      return 'middle';
    }
  }
  setDisableProp(id){
    debugger
    // if(id==1 && this.props.location.pathname == '/cars'){
    //   //disables changing pickup point at cars page
    //   return true;
    // }
    // else{
    //   return false;
    // }
  }

  addFirstStop(data){
    debugger
    console.log(data)
    this.props.dispatch(addFirstStop(data));
    this.props.handleFromChange(data);
    // this.props.dispatch(updatePickupLocation({loc:data.name,locLat:data.latitude,locLng:data.longitude}))
  }


  getLeadTimeByCity(city){
    this.props.dispatch(fetchLocationByLatLong(city));
  }

  addStop(data){
    debugger
    console.log(data)
    let stops = this.props.ride.stops;
    let payLoad = data;
    for(let i=0;i<stops.length;i++){
      if(data.name == stops[i].name){
        alert("This Stop has already been Added");
        this.setState({reRender : false});
        return false;
      }
    }
    if(this.props.isSelfDriven){
      this.props.dispatch(addStopAtIndex(stops.length-1,data));
      this.props.dispatch(updateDestLocation({loc:payLoad.name,locLat:payLoad.latitude,locLng:payLoad.longitude}));
      return true;
    }
    this.props.dispatch(addStop(data));
    this.props.dispatch(updateDestLocation({loc:payLoad.name,locLat:payLoad.latitude,locLng:payLoad.longitude}));
    return true;
  }

  editStop(oldStopId,data){
    let stops = this.props.ride.stops;
    let payLoad = data;
    for(let i=0;i<stops.length;i++){
      if(data.name == stops[i].name){
        alert("This Stop has already been Added");
        this.setState({reRender : true});
        return false;
      }
    }
    this.props.dispatch(editStop(oldStopId,data));
    if(this.props.ride.measureLookupId == "100010011" && this.props.ride.stops.length == oldStopId){
      this.props.dispatch(updateDestLocation({loc:payLoad.name,locLat:payLoad.latitude,locLng:payLoad.longitude}));
    }
    return true;
  }


  removeStop(id){
    debugger
    let stops = this.props.ride.stops;
    let stopsLength = stops.length;
    let cities = this.props.hotelCities; //hotelCities is an array of objects of selected locations' city,lat&lng
    if(stops.length > 1 && id > 1){
      if(this.props.ride.measureLookupId == "100010001"){
        if(id == stops.length){
          this.props.dispatch(removeStop(id));
          this.props.dispatch(updateTripType("One Way"));
        }
        else if(id == stops.length - 1 ){
          this.props.dispatch(updateDestLocation({loc:stops[stopsLength-3].name,locLat:stops[stopsLength-3].latitude,locLng:stops[stopsLength-3].longitude}));
          this.props.dispatch(updateDestCity({city:cities[cities.length-2].name,cityLat:cities[cities.length-2].latitude,cityLng:cities[cities.length-2].longitude}));
          this.props.dispatch(removeStop(id));
          this.props.dispatch(removeHotelCity(id));
        }
        else{
          this.props.dispatch(removeStop(id));
          this.props.dispatch(removeHotelCity(id));
        }
      }
      else{
        if(id == stops.length){
          if(stops.length > 2){
            this.props.dispatch(updateDestLocation({loc:stops[stopsLength-2].name,locLat:stops[stopsLength-2].latitude,locLng:stops[stopsLength-2].longitude}));
            this.props.dispatch(updateDestCity({city:cities[cities.length-2].name,cityLat:cities[cities.length-2].latitude,cityLng:cities[cities.length-2].longitude}));
          }
          else{
            this.props.dispatch(updateDestLocation({loc:'',locLat:'',locLng:''}));
            this.props.dispatch(updateDestCity({city:'',cityLat:'',cityLng:''}));
          }
        }
        this.props.dispatch(removeStop(id));
        this.props.dispatch(removeHotelCity(id));
      }
    }
  }

  updateDestCity(data){
    this.props.dispatch(updateDestCity(data));
  }
  updateDestLocation(data){
    this.props.dispatch(updateDestLocation(data));
  }

  enableRemove(seqId){//to enable remove stop
    if(seqId > 1){
      return true;
    }
  }

  addFirstHotelCity(data){
    debugger
    this.props.dispatch(addFirstHotelCity(data));
  }

  addHotelCity(data){
    debugger
    let flag = 0;
    let hotelCities = this.props.hotelCities;
    for(let i in hotelCities){
      if(data.name == hotelCities[i].name){
        flag = 1
        break;
      }
    }
    if(flag == 0){
      this.props.dispatch(addHotelCity(data));
    }
  }

  editHotelCity(oldId,data){
    this.props.dispatch(editHotelCity(oldId,data));
  }

  render(){
    let ride = this.props.ride;
    let app = this.props.app;
    return(
      <StopsContainer>
        {ride.stops.length == 0 ? <Stop geoLocationRequest={this.props.geoLocationRequest} addStop={this.addStop} id={1} removeStop={this.removeStop} getLeadTimeByCity={this.getLeadTimeByCity} addFirstStop={this.addFirstStop} useGeolocation={this.props.useGeolocation} addFirstHotelCity={this.addFirstHotelCity} editHotelCity={this.editHotelCity} addHotelCity={this.addHotelCity} placeholder="Enter Pickup Location" type='pickup'/> : ""}
        {ride.stops.map((stop,i) => app.isRental ? i== 0 && (
          <Stop key={stop.seqId} geoLocationRequest={this.props.geoLocationRequest} addStop={this.addStop} remove={this.enableRemove(stop.seqId)} addFirstStop={this.addFirstStop} removeStop={this.removeStop} useGeolocation={this.props.useGeolocation} getLeadTimeByCity={this.getLeadTimeByCity} editStop={this.editStop} updateDestCity={this.updateDestCity} updateDestLocation={this.updateDestLocation} text={stop.name} addFirstHotelCity={this.addFirstHotelCity} editHotelCity={this.editHotelCity} addHotelCity={this.addHotelCity} id={stop.seqId} type={this.setTypeProp(i+1)} disable={this.setDisableProp(i+1)}/>
        )
        :
        i <= 1 && (<Stop key={stop.seqId} geoLocationRequest={this.props.geoLocationRequest} addStop={this.addStop} remove={this.enableRemove(stop.seqId)} addFirstStop={this.addFirstStop} removeStop={this.removeStop} useGeolocation={this.props.useGeolocation} getLeadTimeByCity={this.getLeadTimeByCity} editStop={this.editStop} updateDestCity={this.updateDestCity} updateDestLocation={this.updateDestLocation} text={stop.name} addFirstHotelCity={this.addFirstHotelCity} editHotelCity={this.editHotelCity} addHotelCity={this.addHotelCity} id={stop.seqId} type={this.setTypeProp(i+1)} disable={this.setDisableProp(i+1)}/>
        ))}
        {/* {ride.stops.length >= 2 && !app.isRental ? <Stop addStop={this.addStop} placeholder="Add Destination" text="" updateDestLocation={this.updateDestLocation}  updateDestCity={this.updateDestCity} editHotelCity={this.editHotelCity} addHotelCity={this.addHotelCity} id={0} /> : ""} */}
        {ride.stops.length <= 1 && !app.isRental ? <Stop addStop={this.addStop} id={4} removeStop={this.removeStop} updateDestCity={this.updateDestCity} updateDestLocation={this.updateDestLocation} type='final' text="" addHotelCity={this.addHotelCity} placeholder="Where do you want to go?"/> : ""}
      </StopsContainer>
     
    );
  }
}

const mapStateToProps = (state) => ({
    isSelfDriven : state.app.isSelfDriven,
    geoLocationRequest : state.app.geoLocationRequest,
    hotelCities : state.app.hotelCities,
    ride : state.ride,
    isCarsAvailable:state.app.isCarsAvailable,
    hotelCities : state.app.hotelCities,
    app : state.app,
})

export default connect(mapStateToProps)(StopsList);

const StopsContainer = styled.ul`
${tw`py-2`}
`