import tw from "tailwind.macro"
import React from "react"
import styled from "@emotion/styled"
import fancyCurves from "../svgs/fancy-curves-divider-down.svg"
import { css } from '@emotion/core'

const Wrapper = styled.div`
  ${tw`flex items-center relative justify-between flex-col w-full h-auto sm:h-auto md:h-auto py-16 sm:py-16 md:py-16 lg:py-0 px-5 sm:px-10 md:px-10 lg:px-20 xl:px-20`}
  background: black;
        background-size: cover;
        background-repeat: no-repeat;
        ::before{
            content: "";
            display: block;
            position: absolute;
            top: -9.9rem;
            height: 10rem;
            width: 100%;
            background-image: url(${fancyCurves});
            background-size: cover;
            background-repeat: no-repeat;
            transform: rotate(180deg);
        }
`

export default (props) => (
  <Wrapper css={css`${props.addMargin || props.mobileView ? tw`mt-0` : tw`mt-48` }`}>
  </Wrapper>
)
