import tw from "tailwind.macro"
import React from "react"
import { css } from '@emotion/core'
import styled from "@emotion/styled"
import { navigate } from "gatsby"

const Wrapper = tw.div`
absolute w-1/2 h-full right-0 top-0 z-50 bg-black
`
const Container = tw.div`
px-5 lg:px-10 py-5 mt-20
`
const Link = styled.div`
  ${tw`px-2 py-4 text-white text-sm lg:text-lg font-medium rounded appearance-none outline-none shadow-none border-none text-left`}
`
const Hamburger = styled.div`
${tw`absolute px-1 py-1 bg-white shadow-full rounded-full cursor-pointer`}
top: 1.3rem;
right: 1.3rem;
`
const MenuDropDown = styled.div`
${tw`block relative items-center self-center justify-center content-center`}
`
const VendorContainer = styled.div`
${tw`flex px-2 py-4 items-center`}
`
const VendorLogo = styled.img`
${tw`h-10 w-10 rounded-full border border-white bg-white`}
object-fit: contain;
`
const VendorName = styled.p`
${tw`ml-1 text-white text-sm lg:text-lg font-medium text-left`}
`
const DownArrow = styled.i`
${tw`ml-2 mb-1`}
  border: solid white;
  border-width: 0px 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`
const DropDown = styled.div`
${tw`absolute block md:hidden w-32 lg:hidden bg-white p-2 border border-black`}
left: 0.5rem;
top: 3.5rem;
`
const DropDownItem = styled.ul`
${tw`py-1 px-1 text-black text-sm lg:text-lg font-medium`}
`


export default class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDropDown: false
    }
  }

  render() {
    return (
      <Wrapper>
        <Hamburger onClick={this.props.openMenu}><img src={require('../svgs/menu-02.svg')} css={css`${tw`h-8`}`} alt="menu" /></Hamburger>
        <Container>
          {(this.props.business || this.props.partner) && <MenuDropDown><VendorContainer 
          // onClick={() => this.setState({ showDropDown: !this.state.showDropDown})}
          >
            <VendorLogo src={"https://s3-us-west-2.amazonaws.com/vendorlogos/swing_logo.png"} />
            <VendorName>Swing</VendorName>
            {/* <DownArrow /> */}
          </VendorContainer>
       {this.state.showDropDown && <DropDown>
        <a href={window.location.origin + "/profile"} onClick={()=>{this.setState({ showDropDown: false})}}><DropDownItem css={css`${tw``} background-color: ${typeof window != `undefined` && window.location.pathname.includes("/profile") ? "#8a3df6" : "#FFFFFF" }; color: ${typeof window != `undefined` && window.location.pathname.includes("/profile") ? "white" : "black" }`}>Profile</DropDownItem></a>
        <a href={window.location.origin + "/"} onClick={()=>{this.setState({ showDropDown: false})}}><DropDownItem css={css`${tw``} background-color: ${typeof window != `undefined` && window.location.pathname.includes("/settings") ? "#8a3df6" : "#FFFFFF" }; color: ${typeof window != `undefined` && window.location.pathname.includes("/settings") ? "white" : "black" }`}>Settings</DropDownItem></a>
        <a href={window.location.origin + "/"} onClick={()=>{alert("Log out"); this.setState({ showDropDown: false});}}><DropDownItem>Log out</DropDownItem></a>
          </DropDown>}
          </MenuDropDown>}
          {this.props.business && <>
          <a href={window.location.origin + "/booking"}><Link>Rent a Car</Link></a>
          <a href={window.location.origin + "/bookingList"}><Link>Bookings</Link></a>
          <a href={window.location.origin + "/payments"}><Link>Payments</Link></a>
          <a href={window.location.origin + "/profile"}><Link>Profile</Link></a>
          {/* <a href={window.location.origin + "/settings"}><Link>Settings</Link></a> */}
          <a href={window.location.origin + "/support"}><Link>Support</Link></a>
          </>}
          {this.props.partner && <>
            <a onClick={()=>navigate('/booking', { state: { partner: true } })}><Link>Rent a Car</Link></a>
          <a href={window.location.origin + "/partners/bookings"}><Link>Bookings</Link></a>
          <a href={window.location.origin + "/partners/driversAndCars"}><Link>Drivers And Cars</Link></a>
          <a href={window.location.origin + "/partners/pricing"}><Link>Pricing</Link></a>
          <a href={window.location.origin + "/partners/payments"}><Link>Payments</Link></a>
          <a href={window.location.origin + "/partners/profile"}><Link>Profile</Link></a>
          {/* <a href={window.location.origin + "/partners/settings"}><Link>Settings</Link></a> */}
          <a href={window.location.origin + "/partners/support"}><Link>Support</Link></a>
          </>}
          {(this.props.business || this.props.partner) && <Link css={css`${tw`cursor-pointer`}`} onClick={()=>{ window.localStorage.removeItem("businessData"); navigate('/login', { state: {} })}}>Log out</Link>}
        </Container>
      </Wrapper>
    )
  }
}
