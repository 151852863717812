import React from "react"
import { connect } from "react-redux"
import tw from "tailwind.macro"
import styled from "@emotion/styled"
import ReactTable from "react-table";
import "react-table/react-table.css";
import { css } from "@emotion/core"
import { fetchLocations, fetchProductNamesBusiness, fetchProductBusiness, fetchDriversForBusiness } from "../../actions/userAction"
import SliderModal from "../SliderModal"
import Overlay from "../Overlay"
import AccordionItem from "../AccordionItem"
import Select from "react-dropdown-select"
import Loader from 'react-loader-spinner'
import { addDriver, addCar } from "./Functions"
import { categories, subCategories } from "./StaticData"

const Wrapper = tw.div`
  hidden md:flex lg:flex relative w-full flex-col bg-white p-4 md:px-8 lg:px-8
`
const Button = styled.button`
  ${tw`py-1 px-2 text-center text-base font-normal text-white`}
  background-color: #8a3df6;
`
const Row = tw.div`
flex w-full justify-around items-center
`
const Column = tw.div`
block
`
const Title = tw.p`
flex text-gray-600
`
const SubTitle = tw.p`
text-gray-600 text-xs
`
const Text = tw.p`
flex py-2 font-medium
`
const SubText = tw.p`
break-words py-2 text-xs text-gray-700 font-medium
`
const LoaderContainer = styled.div`
  ${tw`flex justify-center h-full w-full bg-black absolute left-0 -mt-4 opacity-50`}
`
const InputText = tw.input`
w-40 lg:w-56 text-sm lg:text-base font-medium bg-gray-300 px-1 lg:px-5 py-2 appearance-none outline-none shadow-none border-none
`
const RadioButton = styled.div`
${tw`h-8 w-8 rounded-full border-2 border-purple-500 cursor-pointer`}
min-width: 2rem;
min-height: 2rem;
max-height: 2rem;
max-width: 2rem;
`

class DriversAndCarsPortal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedDriver: {},
            showLoader: false,
            showDetails: false,
            showOverlay: false,
            showAddDriver: false,
            showAddVehicle: false,
            gearType: "Automatic",
            isDrivers: true,
            name:'',
            phone: '',
            email: '',
            address: '',
            aadharNo: '',
            licenseNo: '',
            makerId: null,
            modelId: null,
            makeYear: '',
            regNo: '',
            categoryId: null,
            subCategoryId: null,
            locationId: null
        }
    }

    componentWillMount() {
        this.props.dispatch(fetchLocations())
        this.props.dispatch(fetchProductNamesBusiness())
    }

    componentDidUpdate(prevProps, prevState) {
    }

    filterCaseInsensitive = (filter, row) => {
        console.log(filter);
        const id = filter.pivotId || filter.id;
        let value = filter.value;
        console.log(value);
        if (typeof row[id] == 'string') {
            console.log(id);
            if (row[id] !== undefined) {
                return String(row[id].toLowerCase()).includes(value.toLowerCase());
            }
            else {
                return true;
            }
        }
        else {

            if (row[id] !== undefined) {
                return String(row[id]).includes(value.toString());
            }
            else {
                return true
            }
        }
    }

    handleRowClicked = (rowInfo) => {
        window.document.getElementsByTagName("body")[0].style.overflow = "hidden"
        console.log("Selected Ride", rowInfo.original)
        this.setState({ selectedDriver: rowInfo.original, showDetails: true, showOverlay: true })
    }

    handleClose = () => {
        window.document.getElementsByTagName("body")[0].style.overflow = "auto"
        this.setState({ showOverlay: false, showDetails: false })
        this.props.dispatch(fetchProductBusiness())
        this.props.dispatch(fetchDriversForBusiness())
    }

    handleSliderOpen = (state) => {
        window.document.getElementsByTagName("body")[0].style.overflow = "hidden"
        if (state === "addDriver") {
            this.setState({ showAddDriver: true, showDetails: true, showOverlay: true, showAddVehicle: false })
        }
        else if (state === "addVehicle") {
            this.setState({ showAddVehicle: true, showDetails: true, showOverlay: true, showAddDriver: false })
        }
        else {
            this.setState({ showAddVehicle: false, showDetails: true, showOverlay: true, showAddDriver: false })
        }
    }

    toggleGearType = () => {
        if (this.state.gearType === "Automatic") {
            this.setState({ gearType: "Manual" })
        }
        else {
            this.setState({ gearType: "Automatic" })
        }
    }

    handleTextChange = (state, value) => {
        this.setState({ [state]: value })
    }

    handleAddDriver = () => {
        if(this.state.name == ''){
            alert("Please enter the name")
            return
        }
        if(this.state.phone.length < 10){
            alert("Please enter a valid phone number")
            return
        }
        if(this.state.address == ''){
            alert("Please enter the address")
            return
        }
        if(this.state.licenseNo == ''){
            alert("please enter the license number")
            return
        }
        let data={
            "name": this.state.name,
            "businessName": "Business Test",
            "address": this.state.address,
            "emailId": this.state.email,
            "mobileNo": this.state.phone,
            "aadharNo": this.state.aadharNo,
            "licenseNo": this.state.licenseNo,
            // "ownerId": null,
            "accountId": this.props.accountId
        }
        this.props.dispatch(addDriver(data))
    }

    handleAddCar = () => {
        if(this.state.makerId == null){
            alert("Please select the maker")
            return
        }
        if(this.state.modelId == null){
            alert("Please select the model")
            return
        }
        if(this.state.makeYear == ''){
            alert("Please enter the manufacture year")
            return
        }
        if(this.state.regNo == ""){
            alert("Please enter the registration number")
            return
        }
        if(this.state.categoryId == null){
            alert("Please select the category")
            return
        }
        if(this.state.subCategoryId == null){
            alert("Please select the sub category")
            return
        }
        if(this.state.locationId == null){
            alert("Please select the location")
            return
        }
        let data = {
            "maker": this.state.makerId.toString(),
            "model": this.state.modelId.toString(),
            "makeYear": this.state.makeYear,
            "regNo": this.state.regNo,
            "categoryId": this.state.categoryId.toString(),
            "subCategoryId": this.state.subCategoryId.toString(),
            "gearSystem": this.state.gearType,
            "drivenBy": "Chauffeur",
            "locationId": this.state.locationId,
            "accountId": this.props.accountId,
            // "ownerId": null
        }
        this.props.dispatch(addCar(data))
    }

    render() {
        return (
            <Wrapper>
                <h1 css={css`${tw`font-bold text-black text-lg py-2`}`}>Drivers And Cars</h1>
                <div css={css`${tw`flex py-2`}`}>
                    <p css={css`${tw`font-normal text-base cursor-pointer`} + ${this.state.isDrivers ? tw` text-purple-500 underline` : tw` text-black`}`} onClick={() => { this.setState({ isDrivers: true }) }}>Drivers</p>
                    <p css={css`${tw`ml-4 font-normal text-base cursor-pointer`} + ${!this.state.isDrivers ? tw` text-purple-500 underline` : tw` text-black`}`} onClick={() => { this.setState({ isDrivers: false }) }}>Cars</p>
                </div>
                <div css={css`${tw`flex py-2`}`}>
                    {this.state.isDrivers && <Button onClick={() => { this.handleSliderOpen() }}>Add Driver</Button>}
                    {!this.state.isDrivers && <Button onClick={() => { this.handleSliderOpen() }}>Add Car</Button>}
                </div>
                <ReactTable
                    data={this.state.isDrivers ? this.props.drivers : this.props.cars}
                    style={{ cursor: 'pointer', width: '100%' }}
                    filterable
                    sorted={[{ // the sorting model for the table
                        id: 'bidId',
                        desc: true
                    },
                    ]}
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: (e, handleOriginal) => {
                                if (rowInfo != undefined) {
                                    this.handleRowClicked(rowInfo)
                                }
                                console.log("A Td Element was clicked!");
                                console.log("it produced this event:", e);
                                console.log("It was in this column:", column);
                                console.log("It was in this row:", rowInfo);
                                console.log("It was in this table instance:", instance);

                                // IMPORTANT! React-Table uses onClick internally to trigger
                                // events like expanding SubComponents and pivots.
                                // By default a custom 'onClick' handler will override this functionality.
                                // If you want to fire the original onClick handler, call the
                                // 'handleOriginal' function.
                                if (handleOriginal) {
                                    handleOriginal();
                                }
                            }
                        };
                    }}
                    columns={[
                        {
                            Header: "Name",
                            accessor: "name",
                            show: this.state.isDrivers,
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Mobile Number",
                            accessor: "mobileNo",
                            show: this.state.isDrivers,
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Address",
                            accessor: "address",
                            show: this.state.isDrivers,
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Driving License",
                            accessor: "licenseNo",
                            show: this.state.isDrivers,
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Status",
                            id: 1,
                            accessor: (data) => (data.isActive ? "Active" : "Inactive"),
                            show: this.state.isDrivers,
                            style: { textAlign: "center" },
                        },
                        // {
                        //     Header: "Maker",
                        //     accessor: "maker",
                        //     show: !this.state.isDrivers,
                        //     style: { textAlign: "center" },
                        // },
                        {
                            Header: "Model",
                            accessor: "model_description",
                            show: !this.state.isDrivers,
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Manufacture Year",
                            accessor: "make_year",
                            show: !this.state.isDrivers,
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Registration Number",
                            accessor: "reg_no",
                            show: !this.state.isDrivers,
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Category",
                            accessor: "category_description",
                            show: !this.state.isDrivers,
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Sub Category",
                            accessor: "sub_category_description",
                            show: !this.state.isDrivers,
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "City",
                            accessor: "location_name",
                            show: !this.state.isDrivers,
                            style: { textAlign: "center" },
                        },
                        // {
                        //     Header: "Gear System",
                        //     accessor: "gear_system",
                        //     show: !this.state.isDrivers,
                        //     style: { textAlign: "center" },
                        // },
                    ]}
                    className="-striped -highlight"
                    defaultPageSize={20}
                    defaultFilterMethod={this.filterCaseInsensitive}
                />
                {/* DO NOT MOVE OVERLAY COMPONENT */}
                <Overlay active={this.state.showOverlay} overlayClicked={this.handleClose} />
                {this.state.showDetails && <SliderModal active={this.state.showDetails} heading={this.state.isDrivers ? "" : ""} close={this.handleClose}>
                    {this.state.showLoader && <LoaderContainer style={{ zIndex: 1000 }} css={css`${tw``} `}>
                        <div css={css`${tw`self-center`}`}>
                            <Loader
                                type="Puff"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                        </div>
                    </LoaderContainer>}
                    {this.state.isDrivers && <AccordionItem title="Onboard Driver" isActive fixed>
                        <Row>
                            <Column>
                                <Title>Name</Title>
                                <InputText type="text" onChange={(e)=>{this.handleTextChange("name",e.target.value)}}/>
                                <Column css={css`${tw`mt-8`}`}>
                                    <Title>Address</Title>
                                    <InputText type="text" onChange={(e)=>{this.handleTextChange("address",e.target.value)}}/>
                                </Column>
                            </Column>
                            <Column>
                                <Title>Phone Number</Title>
                                <InputText type="text" onChange={(e)=>{this.handleTextChange("phone",e.target.value)}}/>
                                <Column css={css`${tw`mt-8`}`}>
                                    <Title>Aadhar Number (Optional)</Title>
                                    <InputText type="text" onChange={(e)=>{this.handleTextChange("aadharNo",e.target.value)}}/>
                                </Column>
                            </Column>
                            <Column>
                                <Title>Email Id (Optional)</Title>
                                <InputText type="text" onChange={(e)=>{this.handleTextChange("email",e.target.value)}}/>
                                <Column css={css`${tw`mt-8`}`}>
                                    <Title>License Number</Title>
                                    <InputText type="text" onChange={(e)=>{this.handleTextChange("licenseNo",e.target.value)}}/>
                                </Column>
                            </Column>
                        </Row>
                        <Row css={css`${tw`justify-end mt-8`}`}>
                            <Button css={css`${tw`w-64 py-2`}`} onClick={this.handleAddDriver}>Add Driver</Button>
                        </Row>
                    </AccordionItem>}
                    {!this.state.isDrivers && <AccordionItem title="Onboard Car" isActive fixed>
                        <Row>
                            <Column>
                                <Title>Maker</Title>
                                <div css={css`${tw`w-40 lg:w-56 text-sm lg:text-base font-medium bg-gray-300 appearance-none outline-none shadow-none border-none`}`}>
                                    <Select options={this.props.makers} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                                        if (val.length > 0) {
                                            this.setState({ makerId: val[0].value })
                                        }
                                    }} />
                                </div>
                                <Column css={css`${tw`mt-8`}`}>
                                    <Title>Model</Title>
                                    <div css={css`${tw`w-40 lg:w-56 text-sm lg:text-base font-medium bg-gray-300 appearance-none outline-none shadow-none border-none`}`}>
                                        <Select options={this.props.models} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                                            if (val.length > 0) {
                                                this.setState({ modelId: val[0].value })
                                            }
                                        }} />
                                    </div>
                                </Column>
                            </Column>
                            <Column>
                                <Title>Manufacture Year</Title>
                                <InputText type="text" onChange={(e)=>{this.handleTextChange("makeYear",e.target.value)}}/>
                                <Column css={css`${tw`mt-8`}`}>
                                    <Title>Registration Number</Title>
                                    <InputText type="text" onChange={(e)=>{this.handleTextChange("regNo",e.target.value)}}/>
                                </Column>
                            </Column>
                            <Column>
                                <Title>Category</Title>
                                <div css={css`${tw`w-40 lg:w-56 text-sm lg:text-base font-medium bg-gray-300 appearance-none outline-none shadow-none border-none`}`}>
                                    <Select options={categories} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                                        if (val.length > 0) {
                                            this.setState({ categoryId: val[0].value })
                                        }
                                    }} />
                                </div>
                                <Column css={css`${tw`mt-8`}`}>
                                    <Title>Sub Category</Title>
                                    <div css={css`${tw`w-40 lg:w-56 text-sm lg:text-base font-medium bg-gray-300 appearance-none outline-none shadow-none border-none`}`}>
                                        <Select options={subCategories} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                                            if (val.length > 0) {
                                                this.setState({ subCategoryId: val[0].value })
                                            }
                                        }} />
                                    </div>
                                </Column>
                            </Column>
                            <Column css={css`${tw`h-full py-2`}`}>
                                <Title>Location</Title>
                                <div css={css`${tw`w-40 lg:w-56 text-sm lg:text-base font-medium bg-gray-300 appearance-none outline-none shadow-none border-none`}`}>
                                    <Select options={this.props.locations} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                                        if (val.length > 0) {
                                            this.setState({ locationId: val[0].value })
                                        }
                                    }} />
                                </div>
                                {/* <Column css={css`${tw`mt-8`}`}>
                                    <Title>Gear System</Title>
                                    <div css={css`${tw`flex items-center`}`}>
                                        <RadioButton css={css`${this.state.gearType == 'Automatic' ? tw`bg-purple-500` : tw`bg-transparent`}`} onClick={this.toggleGearType} />
                                        <Text css={css`${tw`ml-1`}`}>{this.state.gearType}</Text>
                                    </div>
                                </Column> */}
                            </Column>
                        </Row>
                        <Row css={css`${tw`justify-end mt-8`}`}>
                            <Button css={css`${tw`w-64 py-2`}`} onClick={this.handleAddCar}>Add Car</Button>
                        </Row>
                    </AccordionItem>}
                </SliderModal>}
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    locations: state.user.locations,
    makers: state.user.carsData.makers,
    models: state.user.carsData.models,
    categories: state.user.carsData.categories,
    subCategories: state.user.carsData.subCategories,
    cars: state.user.cars,
    drivers: state.user.drivers,
    accountId: state.user.userDetails.accountId
})

export default connect(mapStateToProps)(DriversAndCarsPortal)

const CalendarInput = ({ value, onClick }) => (
    <div css={css`${tw`p-2 text-center border border-gray-600 text-base`}`} className="example-custom-input" onClick={onClick}>
        {value}
    </div>
);

