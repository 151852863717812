import tw from "tailwind.macro"
import React from "react"
import SEO from "../../components/seo"
import FooterWIthoutLinks from "../../components/FooterWIthoutLinks"
import Header from "../../components/Header"
import { connect } from "react-redux"
import Menu from "../../components/Menu"
import Overlay from "../../components/Overlay"
import styled from "@emotion/styled"
import ProfileModal from "../../components/ProfileModal"
import { fetchBusinessProfile } from "../../actions/userAction";
import Loader from 'react-loader-spinner'
import { css } from '@emotion/core'

const Wrapper = styled.div`
  ${tw`flex items-center relative justify-center w-full flex-col bg-white`}
`
const LoaderContainer = styled.div`
  ${tw`justify-center h-full w-full bg-black left-0 fixed opacity-50`}
  top: 5.0rem;
  @media (min-width: 992px){
    top: 6.5rem;
  }
`

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
      showOverlay: false,
      isProfileLoading: true
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.isProfileLoading != this.props.isProfileLoading){
      this.setState({ isProfileLoading: this.props.isProfileLoading})
    }
    if(prevProps.userDetails != this.props.userDetails){
      this.props.dispatch(fetchBusinessProfile())
    }
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu, showOverlay: !this.state.showOverlay })
  }

  render() {
    if (typeof window != `undefined`) {
      if (this.state.isProfileLoading) {
        window.document.getElementsByTagName("body")[0].style.overflow = "hidden";
      }
      else {
        window.document.getElementsByTagName("body")[0].style.overflow = "auto";
      }
    }
    return (
      <Wrapper>
        <SEO title="Profile" />
        <Header shadow partner dark openMenu={this.toggleMenu} />
        {this.state.isProfileLoading && <LoaderContainer style={{ zIndex: 1000 }} css={css`${tw``} display: ${this.state.isProfileLoading ? "flex !important" : "none !important"};`}>
          <div css={css`${tw`self-center`}`}>
            <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
            />
          </div>
        </LoaderContainer>}
        <ProfileModal />
        <FooterWIthoutLinks />
        {/* DO NOT MOVE OVERLAY COMPONENT */}
        <Overlay active={this.state.showOverlay} overlayClicked={() => { this.setState({ showOverlay: false, showMenu: false }) }} />
        {this.state.showMenu && <Menu partner openMenu={this.toggleMenu} />}
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  isProfileLoading: state.user.isProfileLoading,
  userDetails: state.user.userDetails
})

export default connect(mapStateToProps)(Profile);