let initialState = {
    ride: {},
    driver: {},
    vehicle: {},
    isTripLoading: false,
    isTripAvailable: false
}

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_RIDE_DETAILS':
            return {
                ...state,
                ride: action.data
            }
        case 'UPDATE_DRIVER_DETAILS':
            return {
                ...state,
                driver: action.data
            }
        case 'UPDATE_VEHICLE_DETAILS':
            return {
                ...state,
                vehicle: action.data
            }
        case 'UPDATE_TRIP_LOADING':
            return {
                ...state,
                isTripLoading: action.data
            }
        case 'UPDATE_TRIP_AVAILABLE':
            return {
                ...state,
                isTripAvailable: action.data
            }
        default:
            return state
    }
}