import tw from "tailwind.macro"
import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import Moment from "moment"

const Wrapper = tw.div`
 flex flex-col w-full p-4 h-screen
`
const DestinationWrapper = tw.div`
  w-full py-4 text-white
`
const DateTimeWrapper = tw.div`
w-full py-4 text-white
`
const TripTypeWrapper = tw.div`
w-full py-4 text-white
`
const Heading = tw.h1`
text-xl font-bold text-gray-400 p-2
`
const Text = tw.p`
text-lg font-normal text-gray-400 p-2 ml-4
`

class SideBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        let ride = this.props.ride;
        let app = this.props.app;
        return (
            <Wrapper>
                <DestinationWrapper>
                    <Heading>From</Heading>
                    <Text>{ride.pickup.city}</Text>
                </DestinationWrapper>
                {!app.isRental && !app.isAirport && <DestinationWrapper>
                    <Heading>To</Heading>
                    <Text>{ride.dest.city}</Text>
                </DestinationWrapper>}
                <DateTimeWrapper>
                    <Heading>Start Date & Time</Heading>
                    <Text>{Moment(ride.chauffeurStartDate,"DD-MM-YYYY").format("DD, MMM")} {Moment(ride.startTime,'HH:mm:ss').format('hh:mm A')}</Text>
                </DateTimeWrapper>
                {ride.measureLookupId == "100010001" && <DateTimeWrapper>
                    <Heading>End Date & Time</Heading>
                    <Text>{Moment(ride.chauffeurEndDate,"DD-MM-YYYY").format("DD, MMM")} {Moment(ride.endTime,'HH:mm:ss').format('hh:mm A')}</Text>
                </DateTimeWrapper>}
                {!app.isRental && !app.isAirport && <TripTypeWrapper>
                    <Heading>Trip Type</Heading>
                    <Text>{ride.measureLookupId == "100010001" ? "Round Trip" : "One Way"}</Text>
                </TripTypeWrapper>}
                {app.isRental && <TripTypeWrapper>
                    <Heading>Package</Heading>
                    <Text>{ride.rentalType == 5 ? "4hrs, 40kms" : "8hrs, 80kms"}</Text>
                </TripTypeWrapper>}
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    ride: state.ride,
    app: state.app
})

export default connect(mapStateToProps)(SideBar);
