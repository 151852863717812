import tw from "tailwind.macro"
import React from "react"
import styled from "@emotion/styled"
import BackgroundImage from "../../images/vitor-pinto-BsRXRLiwcVg-unsplash.jpg"
import { css } from '@emotion/core'
import { connect } from "react-redux"
import { navigate } from 'gatsby';

const Wrapper = styled.div`
  ${tw`flex items-center relative justify-center content-center w-full flex-col h-screen py-16 sm:py-16 md:py-16 lg:py-0 lg:h-screen px-10 sm:px-10 md:px-10 lg:px-20 xl:px-20`}
  z-index: 1 !important;
`
const FormContainer = styled.div`
  ${tw`w-full md:w-1/2 relative lg:w-1/2 bg-white items-center justify-center content-center px-4 border-2 border-gray-800 rounded-lg`}
`
const ParallaxBgHolder = styled.div`
    ${tw`absolute h-screen w-full`};
    ${'' /* top: -30%; */}
    right: 0px;
    left: 0px;
    z-index: -1;
    background-image: url(${BackgroundImage});
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: cover;
    @media (min-width: 992px){
        ${'' /* top: -12%; */}
        background-position: center; 
        background-size: cover;
    }

    ::after{
        content: "";
        position: relative;
        display: block;
        z-index: 1;
        ${tw`h-screen w-full`};
        top: 0px;
        left: 0px;
        background-image: linear-gradient(to bottom, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.2) 85%, rgba(0,0,0,1) 100%);
        background-blend-mode: multiply;
        -webkit-background-blend-mode: multiply;
        -moz-background-blend-mode: multiply;
        -o-background-blend-mode: multiply;
    }
`
const HeadingContainer = tw.div`
text-center w-full
`
const Heading = tw.h1`
text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl font-black pt-5 pb-5
`
const FormFieldContainer = tw.div`
lg:flex justify-start items-center content-center flex-row w-full py-2 sm:py-2 md:py-4 lg:py-4
`
const InputLabel = tw.p`
text-lg sm:text-xl md:text-xl lg:text-xl xl:text-xl font-medium px-0 lg:px-5 py-2 w-full lg:w-2/5
`
const InputText = tw.input`
w-full lg:w-3/5 text-lg sm:text-xl md:text-xl lg:text-xl xl:text-xl font-medium bg-gray-300 px-2 lg:px-5 py-2 appearance-none outline-none shadow-none border-none
`
const PasswordLink = tw.a`
flex justify-end text-sm sm:text-sm md:text-sm lg:text-sm font-medium py-2 appearance-none outline-none shadow-none border-none
`
const ButtonContainer = tw.div`
flex py-5 w-full justify-center
`
const SubmitButton = styled.button`
${tw`w-full sm:w-1/2 md:w-1/4 lg:w-1/4 py-2 text-white text-base lg:text-lg font-black self-center appearance-none outline-none shadow-none border-none text-center`}
  background-color: #8a3df6;
`
const TogglePassword = styled.span`
${tw` absolute self-center text-lg sm:text-xl md:text-xl lg:text-xl xl:text-xl py-1 lg:py-2 px-4`}
right: 0;
`
const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/

class LoginForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            emailError: false,
            passwordError: false,
            showPassword: false
        }
    }

    onChange = (state, value) => {
        this.setState({ [state]: value })
    }

    validate = (state) => {
        if(!(reg.test(this.state.email)) && state === "email"){
            this.setState({ emailError: true})
        }
        else if(state === "email"){
            this.setState({ emailError: false})
        }
        if(this.state.password === "" && state === "password"){
            this.setState({ passwordError: true})
        }
        else if(state === "password"){
            this.setState({ passwordError: false})
        }
    }

    handleLogin = () => {
        let emailError = false, passwordError= false
        if(!(reg.test(this.state.email))){
            emailError = true
            this.setState({ emailError: emailError})
        }
        if(this.state.password === ""){
            passwordError= true
            this.setState({ passwordError: passwordError})
        }
        if(emailError || passwordError){
            alert("Please fill the fields correctly")
        }
        else{
            navigate(`/partners/bookings`, { state: {} })
        }
    }

    render() {
        return (
            <Wrapper>
                <FormContainer>
                    <HeadingContainer><Heading>Log In</Heading></HeadingContainer>
                    <FormFieldContainer>
                        <InputLabel style={{ color: (this.state.emailError ? 'red' : 'black') }}>Partner Email</InputLabel>
                        <InputText type="email" onChange={(e) => { this.onChange("email", e.target.value) }} onBlur={() => {this.validate('email')}}/>
                    </FormFieldContainer>
                    <FormFieldContainer>
                        <InputLabel style={{ color: (this.state.passwordError ? 'red' : 'black') }}>Password</InputLabel>
                        <InputText type={this.state.showPassword ? 'text' : 'password'} onChange={(e) => { this.onChange("password", e.target.value) }} onBlur={() => {this.validate('password')}}/>
                        <TogglePassword onClick={()=> { this.setState({ showPassword: !this.state.showPassword})}}>
                            <img css={css`${tw`h-10`}`} src={require(this.state.showPassword ? "../../images/password-eye-hide.png" : "../../images/password-eye-show.png")} alt="toggle-password" />
                        </TogglePassword>
                    </FormFieldContainer>
                    <PasswordLink><span css={css`${tw`cursor-pointer`}`} onClick={()=>{alert("Forgot Password")}}>Forgot Password? </span></PasswordLink>
                    <ButtonContainer>
                        <SubmitButton onClick={this.handleLogin}>Log In</SubmitButton>
                    </ButtonContainer>
                </FormContainer>
                <ParallaxBgHolder />
            </Wrapper>
        )
    }

}

const mapStateToProps = (state) => ({
    // locations: state.app.locations
})

export default connect(mapStateToProps)(LoginForm);