import tw from "tailwind.macro"
import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { css } from '@emotion/core'
import Moment from "moment"

const Wrapper = styled.div`
 ${tw`content-center w-full bg-white border border-gray-500 border-solid rounded-lg shadow-2xl cursor-pointer p-4`}
 height: 18rem;
`

class PaymentCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <Wrapper 
            // onClick={()=>{this.props.cardPressed(this.props.payment)}}
            >
            <p css={css`${tw`font-medium text-center text-base py-2 bg-purple-500 text-white`}`}>Booking Id - {this.props.payment.bidId}</p>
            <p css={css`${tw`font-medium text-sm py-1 mt-2`}`}>Status: {this.props.payment.hasOwnProperty('rideStatusDescription') ? this.props.payment.rideStatusDescription : ''}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Ride Date: {Moment(this.props.payment.rideStartDate).format("DD MMM")} - {Moment(this.props.payment.rideEndDate).format("DD MMM")}</p>
            {/* <p css={css`${tw`font-normal text-sm py-1`}`}>Transaction Id: </p> */}
            <p css={css`${tw`font-normal text-sm py-1`}`}>Amount: &#8377;{Math.round(this.props.payment.totalBillAmount).toLocaleString()}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Traveller Name: {this.props.payment.travelerName}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Traveller Contact: {this.props.payment.travelerContactNo}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>From: {this.props.payment.fromCity}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>To: {this.props.payment.toCity}</p>
            {/* <p css={css`${tw`font-normal text-sm py-1`}`}>To: </p> */}
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(PaymentCard);
