import tw from "tailwind.macro"
import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { css } from '@emotion/core'
import Moment from "moment"

const Wrapper = styled.div`
 ${tw`content-center w-full bg-white border border-gray-500 border-solid rounded-lg shadow-2xl cursor-pointer p-4`}
 height: 18rem;
`

class TripCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <Wrapper onClick={()=>{this.props.cardPressed(this.props.trip)}}>
            <p css={css`${tw`font-medium text-center text-base py-2 bg-purple-500 text-white`}`}>Booking Id - {this.props.trip.liveApiBookingId}</p>
            <p css={css`${tw`font-medium text-sm py-1 mt-2`}`}>Status: {this.props.trip.hasOwnProperty('rideStatusDesc') && this.props.trip.rideStatusDesc != '' ? this.props.trip.rideStatusDesc : 'Upcoming'}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>{this.props.trip.fromCity} {this.props.trip.hasOwnProperty("toCity") && " - " + this.props.trip.toCity}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Traveller Name: {this.props.trip.travelerName}</p>
            {/* <p css={css`${tw`font-normal text-sm py-1`}`}>City: {this.props.trip.fromCity}</p> */}
            <p css={css`${tw`font-normal text-sm py-1`}`}>Start Date: {Moment(this.props.trip.rideStartDate, "YYYY-MM-DD HH:mm:ss").format("DD MMM, YYYY hh:mm A")}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>End Date: {Moment(this.props.trip.rideEndDate, "YYYY-MM-DD HH:mm:ss").format("DD MMM, YYYY hh:mm A")}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Trip Type: {this.props.trip.tripType}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Vehicle: {this.props.trip.carType}</p>
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    ride: state.ride,
    app: state.app
})

export default connect(mapStateToProps)(TripCard);
