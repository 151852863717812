import { urls } from "../../api/urls"
import axios from "axios"
import { storeKey, retrieveKey } from "../../asyncStorage"
import Moment from "moment"
import { fetchBidDetailsBusiness } from "../../actions/partnerActions"

export function cancelRide(bidId) {
    return function (dispatch, getState) {
        let url = urls.cancelUrl;
        let input = {
            "bidId": bidId,
            "cancelReason": ""
        }
        console.log("Input Data for Cancel Ride", input);
        axios.post(url, input)
            .then(response => {
                console.log("Response from Cancel Ride", response)
                if (response.data.statusCD == 200 && response.data.statusMSG == "Cancellation Successful") {
                    console.log("Cancel Ride Successful", response.data.responseData)
                    dispatch(fetchBidDetailsBusiness(bidId))
                    alert("Ride successfully cancelled")
                }
                else {
                    console.log("Cancel Ride failed");
                    alert("Unable to cancel ride at this moment")
                }
            })
            .catch(err => {
                console.log("Cancel Ride failed", err);
                alert("Unable to cancel ride at this moment")
            })
    }
}

export function addDriver(data) {
    return function (dispatch, getState) {
        let url = urls.addDriverForBusiness;
        console.log("Input Data for Add Driver For Business", data);
        axios.post(url, data)
            .then(response => {
                console.log("Response from Add Driver For Business", response)
                if (response.data.statusCD == 200) {
                    console.log("Add Driver For Business Successful", response.data.responseData)
                    alert("Driver added successfully")
                }
                else {
                    console.log("Add Driver For Business failed");
                    alert("Unable to add driver at this moment")
                }
            })
            .catch(err => {
                console.log("Add Driver For Business failed", err);
                alert("Unable to add driver at this moment")
            })
    }
}

export function addCar(data) {
    return function (dispatch, getState) {
        let url = urls.addVehicleForBusiness;
        console.log("Input Data for Add Driver For Business", data);
        axios.post(url, data)
            .then(response => {
                console.log("Response from Add Vehicle For Business", response)
                if (response.data.statusCD == 200) {
                    console.log("Add Vehicle For Business Successful", response.data.responseData)
                    alert("Car added successfully")
                }
                else {
                    console.log("Add Vehicle For Business failed");
                    alert("Unable to add car at this moment")
                }
            })
            .catch(err => {
                console.log("Add Vehicle For Business failed", err);
                alert("Unable to add car at this moment")
            })
    }
}

export function startRide(data, success, error) {
    return function (dispatch, getState) {
        let url = urls.startRideForBusiness;
        console.log("Input Data for Start Ride For Business", data);
        axios.post(url, data)
            .then(response => {
                console.log("Response from Start Ride For Business", response)
                if (response.data.statusCD == 200) {
                    console.log("Start Ride For Business Successful", response.data.responseData)
                    dispatch(fetchBidDetailsBusiness(data.bidId))
                    success()
                }
                else {
                    console.log("Start Ride For Business failed");
                    error()
                }
            })
            .catch(err => {
                console.log("Start Ride For Business failed", err);
                error()
            })
    }
}

export function endRide(data, success, error) {
    return function (dispatch, getState) {
        let url = urls.endRideForBusiness;
        console.log("Input Data for End Ride For Business", data);
        axios.post(url, data)
            .then(response => {
                console.log("Response from End Ride For Business", response)
                if (response.data.statusCD == 200) {
                    console.log("End Ride For Business Successful", response.data.responseData)
                    dispatch(fetchBidDetailsBusiness(data.bidId))
                    success()
                }
                else {
                    console.log("End Ride For Business failed");
                    error()
                }
            })
            .catch(err => {
                console.log("End Ride For Business failed", err);
                error()
            })
    }
}

export function assignRide(data) {
    return function (dispatch, getState) {
        let url = urls.assignPartnerAndVehicleBusiness;
        console.log("Input Data for Assign Partner And Vehicle Business", data);
        axios.post(url, data)
            .then(response => {
                console.log("Response from Assign Partner And Vehicle Business", response)
                if (response.data.statusCD == 200) {
                    console.log("Assign Partner And Vehicle Business Successful", response.data.responseData)
                    dispatch(fetchBidDetailsBusiness(data.bidId))
                    alert("Driver and vehicle assigned successfully")
                }
                else {
                    console.log("Assign Partner And Vehicle Business failed");
                    alert("Cannot assign driver and vehicle at the moment")
                }
            })
            .catch(err => {
                console.log("Assign Partner And Vehicle Business failed", err);
                alert("Cannot assign driver and vehicle at the moment")
            })
    }
}

export function insertOrUpdateProductPricingBusiness(data, success, error){
    return function (dispatch, getState) {
        let url = urls.insertOrUpdateProductPricingBusiness;
        console.log("Input Data for Insert Or Update Product Pricing Business", data);
        axios.post(url,data)
        .then(response => {
            console.log("Response from Insert Or Update Product Pricing Business", response)
            if(response.data.statusCD == 200){
                console.log("Insert Or Update Product Pricing Business Successful", response.data.responseData)
                success(response)
            }
            else{
                console.log("Insert Or Update Product Pricing Business failed");
                error(response)
            }
        })
        .catch(err => {
            console.log("Insert Or Update Product Pricing Business failed", err);
            error(err)
        })
    }
}
