import React from "react"
import { connect } from "react-redux"
import tw from "tailwind.macro"
import styled from "@emotion/styled"
import ReactTable from "react-table";
import "react-table/react-table.css";
import { css } from "@emotion/core"
import SliderModal from "./SliderModal"
import Overlay from "../components/Overlay"
import Moment from "moment"
import { urls } from "../api/urls"
import axios from "axios"
import { fetchTripsForBusiness } from "../actions/userAction"

const Wrapper = tw.div`
  hidden md:flex lg:flex relative w-full flex-col bg-white p-4 md:px-8 lg:px-8
`
const Button = styled.button`
  ${tw`py-1 px-2 text-center text-base font-normal text-white border border-solid border-purple-500 text-purple-500 hover:bg-purple-500 hover:text-white`}
  ${'' /* background-color: #8a3df6; */}
`

class TripsPortal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: 'upcoming',
            selectedRide: {},
            showDetails: false,
            showOverlay: false,
            isCancelled: false
        }
    }

    filterCaseInsensitive = (filter, row) => {
        console.log(filter);
        const id = filter.pivotId || filter.id;
        let value = filter.value;
        console.log(value);
        if (typeof row[id] == 'string') {
            console.log(id);
            if (row[id] !== undefined) {
                return String(row[id].toLowerCase()).includes(value.toLowerCase());
            }
            else {
                return true;
            }
        }
        else {

            if (row[id] !== undefined) {
                return String(row[id]).includes(value.toString());
            }
            else {
                return true
            }
        }
    }

    handleRowClicked = (rowInfo) => {
        window.document.getElementsByTagName("body")[0].style.overflow = "hidden"
        this.setState({ selectedRide: rowInfo.original, showDetails: true, showOverlay: true })
    }

    handleClose = () => {
        window.document.getElementsByTagName("body")[0].style.overflow = "auto"
        this.setState({ showOverlay: false, showDetails: false })
        this.props.dispatch(fetchTripsForBusiness());
    }

    handleCancelRide = () => {
        //eslint-disable-next-line
        if (confirm("Are you sure you want to cancel this ride?")) {
            let url = urls.cancelUrl;
            let input = {
                "bidId": this.state.selectedRide.liveApiBookingId,
                "cancelReason": ""
            }
            console.log("Input Data for Cancel Ride", input);
            let self = this
            axios.post(url, input)
                .then(response => {
                    console.log("Response from Cancel Ride", response)
                    if (response.data.statusCD == 200 && response.data.statusMSG == "Cancellation Successful") {
                        console.log("Cancel Ride Successful", response.data.responseData)
                        alert("Ride successfully cancelled")
                        this.setState({ isCancelled: true })
                    }
                    else {
                        console.log("Cancel Ride failed");
                        alert("Unable to cancel ride at this moment")
                    }
                })
                .catch(err => {
                    console.log("Cancel Ride failed", err);
                    alert("Unable to cancel ride at this moment")
                })
        }
    }

    render() {
        return (
            <Wrapper>
                <h1 css={css`${tw`font-bold text-black text-lg py-2`}`}>Bookings</h1>
                <div css={css`${tw`flex py-2`}`}>
                    <p css={css`${tw`font-normal text-base cursor-pointer`} + ${this.state.status == "upcoming" ? tw` text-purple-500 underline` : tw` text-black`}`} onClick={() => {this.setState({ status: 'upcoming' })}}>Upcoming</p>
                    <p css={css`${tw`ml-4 font-normal text-base cursor-pointer`} + ${this.state.status == "past" ? tw` text-purple-500 underline` : tw` text-black`}`} onClick={() => {this.setState({ status: 'past' })}}>Past</p>
                </div>
                <ReactTable
                    data={this.props.bookings.filter( r => (this.state.status == 'past' ? r.hasOwnProperty('rideStatusDesc') && r.rideStatusDesc != '' : !r.hasOwnProperty('rideStatusDesc')))}
                    style={{ cursor: 'pointer', width: '100%' }}
                    filterable
                    // sorted={[{ // the sorting model for the table
                    //     id: 'bidId',
                    //     desc: true
                    // },
                    // ]}
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: (e, handleOriginal) => {
                                if (rowInfo != undefined) {
                                    this.handleRowClicked(rowInfo)
                                }
                                console.log("A Td Element was clicked!");
                                console.log("it produced this event:", e);
                                console.log("It was in this column:", column);
                                console.log("It was in this row:", rowInfo);
                                console.log("It was in this table instance:", instance);

                                // IMPORTANT! React-Table uses onClick internally to trigger
                                // events like expanding SubComponents and pivots.
                                // By default a custom 'onClick' handler will override this functionality.
                                // If you want to fire the original onClick handler, call the
                                // 'handleOriginal' function.
                                if (handleOriginal) {
                                    handleOriginal();
                                }
                            }
                        };
                    }}
                    columns={[
                        {
                            Header: "Booking Id",
                            accessor: "liveApiBookingId",
                            style: {textAlign: "center"},
                        },
                        {
                            Header: "Traveller Name",
                            accessor: "travelerName",
                            style: {textAlign: "center"},
                        },
                        {
                            Header: "Start Date",
                            id: 1,
                            accessor: (data) => (Moment(data.rideStartDate, "YYYY-MM-DD HH:mm:ss").format("DD MMM, YYYY hh:mm A")),
                            style: {textAlign: "center"},
                        },
                        {
                            Header: "End Date",
                            id: 3,
                            accessor: (data) => (Moment(data.rideEndDate, "YYYY-MM-DD HH:mm:ss").format("DD MMM, YYYY hh:mm A")),
                            style: {textAlign: "center"},
                        },
                        {
                            Header: "From",
                            accessor: "fromCity",
                            style: {textAlign: "center"},
                        },
                        // {
                        //     Header: "To",
                        //     accessor: "toCity",
                        //     style: {textAlign: "center"},
                        // },
                        {
                            Header: "Total Amount",
                            accessor: (data) => ( data.hasOwnProperty('totalBillAmount') ? "₹" + Math.round(data.totalBillAmount).toLocaleString() : ""),
                            id: 4,
                            style: {textAlign: "center"},
                        },
                        {
                            Header: "Trip Type",
                            accessor: "tripType",
                            style: {textAlign: "center"},
                        },
                        {
                            Header: "Vehicle",
                            accessor: "carType",
                            style: {textAlign: "center"},
                        },
                        {
                            Header: "Status",
                            id: 2,
                            show: this.state.status == 'past',
                            accessor: (data) => ( this.state.status == 'past' ? data.rideStatusDesc : 'Upcoming'),
                            style: {textAlign: "center"},
                        },
                    ]}
                    className="-striped -highlight"
                    defaultPageSize={20}
                    defaultFilterMethod={this.filterCaseInsensitive}
                />
                {/* DO NOT MOVE OVERLAY COMPONENT */}
                <Overlay active={this.state.showOverlay} overlayClicked={this.handleClose} />
                {this.state.showDetails && <SliderModal active={this.state.showDetails} heading={"#" + this.state.selectedRide.liveApiBookingId} close={this.handleClose}>
                {!this.state.selectedRide.hasOwnProperty("rideStatusDesc") && <Button css={css`${tw``}`} onClick={this.handleCancelRide}>Cancel Trip</Button>}
                    <div css={css`${tw`flex flex-wrap w-full items-start`}`}>
                        <div css={css`${tw`lg:w-1/2 md:w-1/2 w-full mt-2`}`}>
                            <h4 css={css`${tw`mt-4 text-lg font-bold`}`}>Ride Details</h4>
                            <ul css={css`${tw`leading-loose text-black mt-4`}`}>
                                <li css={css`${tw`font-medium text-base`}`}>Ride Status : {this.state.status == 'past' ? (!this.state.isCancelled ? this.state.selectedRide.rideStatusDesc : "Cancelled") : (!this.state.isCancelled ? 'Upcoming' : "Cancelled")}</li>
                                <li css={css`${tw`text-base opacity-75`}`}>Trip Type: {this.state.selectedRide.tripType} </li>
                                <li css={css`${tw`text-base opacity-75`}`}>From: {this.state.selectedRide.fromCity}</li>
                                <li css={css`${tw`text-base opacity-75`}`}>To: {this.state.selectedRide.toCity}</li>
                                {/* <li css={css`${tw`text-base opacity-75`}`}>To: Bangalore </li> */}
                                <li css={css`${tw`text-base opacity-75`}`}>Start Date: {Moment(this.state.selectedRide.rideStartDate, "YYYY-MM-DD HH:mm:ss").format("DD MMM, YYYY hh:mm A")} </li>
                                <li css={css`${tw`text-base opacity-75`}`}>End Date: {Moment(this.state.selectedRide.rideEndDate, "YYYY-MM-DD HH:mm:ss").format("DD MMM, YYYY hh:mm A")} </li>
                                <li css={css`${tw`text-base opacity-75`}`}>Vehicle: {this.state.selectedRide.carType} </li>
                            </ul>
                        </div>
                        <div css={css`${tw`lg:w-1/2 md:w-1/2 w-full mt-2`}`}>
                            <h4 css={css`${tw`mt-4 text-lg font-bold`}`}>Traveller Details </h4>
                            <ul css={css`${tw`leading-loose text-black mt-4`}`}>
                                <li css={css`${tw`font-medium text-base`}`}>Name: {this.state.selectedRide.travelerName}</li>
                                <li css={css`${tw`font-medium text-base`}`}>Phone: <a href={"tel:+91" + this.state.selectedRide.travelerContactNo}>{this.state.selectedRide.travelerContactNo}</a></li>
                                <li css={css`${tw`font-medium text-base`}`}>Email: <a href={"mailto:" + this.state.selectedRide.travelerEmailId}>{this.state.selectedRide.travelerEmailId}</a></li>
                            </ul>
                        </div>
                        {this.state.selectedRide.hasOwnProperty("totalBillAmount") && <div css={css`${tw`lg:w-1/2 md:w-1/2 w-full mt-2`}`}>
                            <h4 css={css`${tw`mt-4 text-lg font-bold`}`}>Price BreakDown </h4>
                            <ul css={css`${tw`leading-loose text-black mt-4`}`}>
                                <li css={css`${tw`font-medium text-base`}`}>Total Amount: {"₹" + Math.round(this.state.selectedRide.totalBillAmount).toLocaleString()}</li>
                                <li css={css`${tw`font-normal text-base`}`}>Ride Amount: {this.state.selectedRide.hasOwnProperty("rideAmount") && "₹" + Math.round(this.state.selectedRide.rideAmount).toLocaleString()}</li>
                                {this.state.selectedRide.hasOwnProperty("driverCharges") && <li css={css`${tw`font-normal text-base`}`}>Driver Charges: {"₹" + Math.round(this.state.selectedRide.driverCharges).toLocaleString()}</li>}
                                {this.state.selectedRide.hasOwnProperty("nightCharges") && <li css={css`${tw`font-normal text-base`}`}>Night Charges: {"₹" + Math.round(this.state.selectedRide.nightCharges).toLocaleString()}</li>}
                                {this.state.selectedRide.hasOwnProperty("serviceTax") && <li css={css`${tw`font-normal text-base`}`}>GST: {"₹" + Math.round(this.state.selectedRide.serviceTax).toLocaleString()}</li>}
                            </ul>
                        </div>}
                        {/* {this.state.selectedRide.hasOwnProperty("termsAndConditions") && <div css={css`${tw`mt-2 w-full`}`}>
            <h4 css={css`${tw`mt-4 text-base font-bold`}`}>Terms and Conditions</h4>
            {this.state.selectedRide.terms_conditions.map((term) => (
                <p css={css`${tw`leading-loose text-sm opacity-75`}`}>*&nbsp;{term}</p>
            ))}
            
          </div>} */}
                    </div>
                </SliderModal>}
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    bookings: state.user.bookings

})

export default connect(mapStateToProps)(TripsPortal)