import React from "react"
import { connect } from "react-redux"
import tw from "tailwind.macro"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { fetchPartnerBookings } from "../../actions/userAction"
import SliderModal from "../SliderModal"
import Overlay from "../../components/Overlay"
import Moment from "moment"
import BookingCard from "./BookingCard"
import AccordionItem from "../AccordionItem"
import Popup from 'reactjs-popup'
import { cancelRide, startRide, endRide, assignRide } from "./Functions"
import { fetchBidDetailsBusiness } from "../../actions/partnerActions"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash';
import Select from "react-dropdown-select"
import Loader from 'react-loader-spinner'

const Wrapper = styled.div`
  ${tw`flex md:hidden lg:hidden relative w-screen h-screen flex-col p-8`}
  ${'' /* background: #e7ebef; */}
`
const SearchImage = styled.img`
${tw`absolute h-5 opacity-50`}
right: 1rem;
top: 1rem;
`
const Button = styled.button`
  ${tw`py-1 px-2 text-center text-base font-normal text-white`}
  background-color: #8a3df6;
  width: 48%;
`
const Row = tw.div`
block w-full
`
const Column = tw.div`
flex flex-row mt-2 items-center w-full
`
const Title = tw.p`
flex flex-row text-gray-600 w-2/5
`
const SubTitle = tw.p`
text-gray-600
`
const Text = tw.p`
flex flex-row font-medium w-3/5
`
const SubText = tw.p`
break-words py-2 text-xs text-gray-700 font-medium ml-2
`
const StyledPopup = styled(Popup)`
// use your custom style for ".popup-overlay"
  &-overlay {
  }
// use your custom style for ".popup-content"
.popup-content {
    width: 80% !important;
  }
`
const LoaderContainer = styled.div`
  ${tw`flex justify-center h-full w-full bg-black absolute left-0 -mt-4 opacity-50`}
`

class BookingsMobile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: 'upcoming',
            selectedRide: {},
            showDetails: false,
            showOverlay: false,
            partnerBookings: [],
            isPartnerBookingsLoading: true,
            isPartnerBookingsAvailable: false,
            driver: {},
            vehicle: {},
            startDate: Moment(),
            endDate: Moment(),
            odometerStartReading: null,
            odometerEndReading: null,
            showRideStart: false,
            showRideEnd: false,
            showLoader: false,
            licenseNo: null,
            regNo: null
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.partnerBookings != this.props.partnerBookings) {
            this.setState({ partnerBookings: _.orderBy(this.props.partnerBookings, ['bidId'], ['desc']) })
        }
        if (prevProps.isPartnerBookingsAvailable != this.props.isPartnerBookingsAvailable) {
            this.setState({ isPartnerBookingsAvailable: this.props.isPartnerBookingsAvailable })
        }
        if (prevProps.isPartnerBookingsLoading != this.props.isPartnerBookingsLoading) {
            this.setState({ isPartnerBookingsLoading: this.props.isPartnerBookingsLoading })
        }
        if (prevProps.driver != this.props.driver) {
            this.setState({ driver: this.props.driver })
        }
        if (prevProps.vehicle != this.props.vehicle) {
            this.setState({ vehicle: this.props.vehicle })
        }
        if (prevProps.ride != this.props.ride) {
            this.setState({ selectedRide: this.props.ride, showDetails: true, showOverlay: true })
        }
    }

    filterCaseInsensitive = (filter, row) => {
        console.log(filter);
        const id = filter.pivotId || filter.id;
        let value = filter.value;
        console.log(value);
        if (typeof row[id] == 'string') {
            console.log(id);
            if (row[id] !== undefined) {
                return String(row[id].toLowerCase()).includes(value.toLowerCase());
            }
            else {
                return true;
            }
        }
        else {

            if (row[id] !== undefined) {
                return String(row[id]).includes(value.toString());
            }
            else {
                return true
            }
        }
    }

    handleCardClicked = (ride) => {
        window.document.getElementsByTagName("body")[0].style.overflow = "hidden"
        console.log("Selected Ride", ride)
        this.props.dispatch(fetchBidDetailsBusiness(ride.bidId))
    }

    handleClose = () => {
        window.document.getElementsByTagName("body")[0].style.overflow = "auto"
        this.props.dispatch(fetchPartnerBookings());
        this.setState({ showOverlay: false, showDetails: false })
    }

    handleSearch = (value) => {
        console.log(value);
        if (isNaN(value)) {
            window.document.getElementById("search").style.border = "thin solid #FF0000"
        }
        else {
            window.document.getElementById("search").style.border = "thin solid #00FF00"
            this.setState({ partnerBookings: this.props.partnerBookings.filter(b => b.liveApiBookingId.toString().includes(value)) })
        }
    }

    popupOpen = (state) => {
        if(state === "start"){
            this.setState({ openPopup: true, showRideStart: true, showRideEnd: false });
        }
        else if(state === "end"){
            this.setState({ openPopup: true, showRideStart: false, showRideEnd: true });
        }
        else{
            this.setState({ openPopup: true });
        }
    }

    closeModal = () => {
        this.setState({ openPopup: false })
    }

    handleCancelRide = () => {
        //eslint-disable-next-line
        if (confirm("Are you sure you want to cancel this ride?")) {
            this.props.dispatch(cancelRide(this.state.selectedRide.bidId))
        }
    }

    handleStartDateChange = (date) => {
        this.setState({ startDate: Moment(date) })
    }

    handleEndDateChange = (date) => {
        this.setState({ endDate: Moment(date) })
    }

    handleStartClick = () => {
        if(this.state.odometerStartReading == null){
            alert("Please enter the start odometer reading")
            return
        }
        let data = {
            "bidId": Number(this.state.selectedRide.bidId),
            "odometerStartReading": Number(this.state.odometerStartReading),
            "rideStartDate": Moment(this.state.startDate,"DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY HH:mm:ss")
        }
        this.props.dispatch(startRide(data,this.startSuccess, this.startError))
    }

    startSuccess = () => {
        this.setState({ openPopup: false })
        alert("Ride successfully started")
    }

    startError = () => {
        alert("Cannot start ride at this moment")
    }

    handleEndClick = () => {
        if(this.state.odometerEndReading == null){
            alert("Please enter the end odometer reading")
            return
        }
        let data = {
            "bidId": Number(this.state.selectedRide.bidId),
            "odometerEndReading": Number(this.state.odometerEndReading),
            "actualRideEndDate": Moment(this.state.endDate,"DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY HH:mm:ss")
        }
        this.props.dispatch(endRide(data,this.endSuccess, this.endError))
    }

    endSuccess = () => {
        this.setState({ openPopup: false })
        alert("Ride successfully ended")
    }

    endError = () => {
        alert("Cannot end ride at this moment")
    }

    handleAssignClick = () => {
        if(this.state.licenseNo == null){
            alert("Please select a driver")
            return
        }
        if(this.state.regNo == null){
            alert("Please select a car")
            return
        }
        let data = {
            "bidId": Number(this.state.selectedRide.bidId),
            "regNo": this.state.regNo.toString(),
            "licenseNo": this.state.licenseNo.toString()
        }
        this.props.dispatch(assignRide(data))
    }

    render() {
        return (
            <Wrapper>
                <h1 css={css`${tw`font-bold text-black text-lg py-2`}`}>Bookings</h1>
                {this.state.isPartnerBookingsAvailable && !this.state.isPartnerBookingsLoading && <div css={css`${tw`flex py-2`}`}>
                    <p css={css`${tw`font-normal text-base cursor-pointer`} + ${this.state.status == "upcoming" ? tw` text-purple-500 underline` : tw` text-black`}`} onClick={() => { this.setState({ status: 'upcoming' }) }}>Upcoming</p>
                    <p css={css`${tw`ml-4 font-normal text-base cursor-pointer`} + ${this.state.status == "past" ? tw` text-purple-500 underline` : tw` text-black`}`} onClick={() => { this.setState({ status: 'past' }) }}>Past</p>
                </div>}
                {/* <div css={css`${tw`flex w-full py-2 relative`}`}>
                <input type="text" placeholder="#booking id" id="search" onChange={(e)=>this.handleSearch(e.target.value)} css={css`${tw`flex w-full p-2 rounded-full bg-white border-gray-500 border appearance-none outline-none shadow-2xl`}`} />
                <SearchImage src={require("../images/search.png")} />
                </div> */}
                {!this.state.isPartnerBookingsAvailable && !this.state.isPartnerBookingsLoading && <p css={css`${tw`font-medium text-center self-center text-black text-lg py-4`}`}>No bookings available</p>}
                {this.state.isPartnerBookingsAvailable && !this.state.isPartnerBookingsLoading && this.state.partnerBookings.filter(r => (this.state.status == 'past' ? r.hasOwnProperty('rideStatusDesc') && r.rideStatusDesc != '' : !r.hasOwnProperty('rideStatusDesc'))).map(trip => (<div css={css`${tw`py-8`}`}>
                    <BookingCard trip={trip} cardPressed={(ride) => { this.handleCardClicked(ride) }} />
                </div>
                ))}
                {/* DO NOT MOVE OVERLAY COMPONENT */}
                <Overlay active={this.state.showOverlay} overlayClicked={this.handleClose} />
                {this.state.showDetails && <SliderModal active={this.state.showDetails} heading={"#" + this.state.selectedRide.bidId} close={this.handleClose}>
                {this.state.showLoader && <LoaderContainer style={{ zIndex: 1000 }} css={css`${tw``} `}>
                <div css={css`${tw`self-center`}`}>
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={100}
                            width={100}
                        />
                    </div>
                    </LoaderContainer>}
                    <div css={css`${tw`block w-full`}`}>
                        <div css={css`${tw`flex justify-between items-center w-full py-2`}`}>
                        {this.state.selectedRide.hasOwnProperty("rideStatusDesc") && this.state.selectedRide.rideStatusDesc.toLowerCase().includes("confirmed") && <Button css={css`${tw``}`} onClick={()=>this.popupOpen("start")}>Start Trip</Button>}
                        {this.state.selectedRide.hasOwnProperty("rideStatusDesc") && this.state.selectedRide.rideStatusDesc.toLowerCase().includes("started") && <Button css={css`${tw``}`} onClick={()=>this.popupOpen("end")}>End Trip</Button>}
                        </div>
                        <div css={css`${tw`flex justify-between items-center w-full py-2`}`}>
                            {/* <Button css={css`${tw``}`}>Reassign Trip</Button> */}
                            {!this.state.selectedRide.hasOwnProperty("rideStatusDesc") && <button css={css`${tw`py-1 px-2 text-center text-base font-normal border border-solid border-purple-500 text-purple-500 hover:bg-purple-500 hover:text-white`} width: 48%;`} onClick={this.handleCancelRide}>Cancel Trip</button>}
                        </div>
                        {/* <div css={css`${tw`flex justify-between items-center w-full py-2`}`}>
                            <Button css={css`${tw``}`}>Send Invoice</Button>
                        </div> */}
                    </div>
                    <AccordionItem title="Traveller Details" isActive fixed>
                        <Row>
                            <Column>
                                <Title>Name</Title>
                                <Text>{this.state.selectedRide.travelerName} </Text>
                            </Column>
                            <Column>
                                <Title>Phone</Title>
                                <a css={css`${tw`flex font-medium`}`} href={"tel:+91" + this.state.selectedRide.travelerContactNo}>{this.state.selectedRide.travelerContactNo} </a>
                            </Column>
                            <Column>
                                <Title>Email</Title>
                                <a css={css`${tw`flex font-medium`}`} href={"mailto:" + this.state.selectedRide.travelerEmailId}>{this.state.selectedRide.travelerEmailId} </a>
                            </Column>
                        </Row>
                    </AccordionItem>
                    <AccordionItem title="Trip Details" isActive fixed>
                        <Row>
                            <Column>
                                <Title>From</Title>
                                <Text>{this.state.selectedRide.fromCity}</Text>
                            </Column>
                            <Column>
                                <Title>Pickup Point</Title>
                                <Text>{this.state.selectedRide.pickupLocality.substr(this.state.selectedRide.pickupLocality.indexOf(",") + 1)}</Text>
                            </Column>
                            {!this.state.selectedRide.measureLookupDesc.includes('hour') && <Column>
                                <Title>To</Title>
                                <Text>{this.state.selectedRide.toCity}</Text>
                            </Column>}
                            {!this.state.selectedRide.measureLookupDesc.includes('hour') && <Column>
                                <Title>Dropoff Point</Title>
                                <Text>{this.state.selectedRide.destinationLocality.substr(this.state.selectedRide.destinationLocality.indexOf(",") + 1)}</Text>
                            </Column>}
                            <Column>
                                <Title>Trip Type</Title>
                                <Text>{this.state.selectedRide.measureLookupDesc}</Text>
                            </Column>
                            <Column>
                                <Title>Travel Dates</Title>
                                <Text>{Moment(this.state.selectedRide.rideStartDate, "DD-MM-YYYY HH:mm:ss").format("MMM DD") + " - " + Moment(this.state.selectedRide.rideEndDate, "DD-MM-YYYY HH:mm:ss").format("MMM DD")}</Text>
                            </Column>
                            <Column>
                                <Title>Start Time</Title>
                                <Text>{Moment(this.state.selectedRide.rideStartDate, "DD-MM-YYYY HH:mm:ss").format("hh:mm A")}</Text>
                            </Column>
                            <Column>
                                <Title>Status</Title>
                                <p css={css`${tw`font-medium ml-0 border-2 border-blue-700 py-1 px-2 font-medium`}`}>{this.state.selectedRide.hasOwnProperty("rideStatusDesc") ? this.state.selectedRide.rideStatusDesc : 'Upcoming'}</p>
                            </Column>
                        </Row>
                    </AccordionItem>
                    <AccordionItem title="Package Details" isActive fixed>
                        <Row>
                            <Column>
                                <Title>Total Amount</Title>
                                <Text>{this.state.selectedRide.hasOwnProperty("totalBillAmount") ? "₹ " + Math.round(this.state.selectedRide.totalBillAmount).toLocaleString() : "₹ 0"}</Text>
                            </Column>
                            <Column>
                                <Title>Base Fare</Title>
                                <Text>{this.state.selectedRide.hasOwnProperty("rideAmount") ? "₹ " + Math.round(this.state.selectedRide.rideAmount).toLocaleString() : "₹ 0"}</Text>
                            </Column>
                            <Column>
                                <Title>Total KMs</Title>
                                <Text>{this.state.selectedRide.hasOwnProperty("packageKms") ? Math.round(this.state.selectedRide.packageKms).toLocaleString() : "₹ 0"}</Text>
                            </Column>
                            <Column>
                                <Title>Driver Charges</Title>
                                <Text>{this.state.selectedRide.hasOwnProperty("driverCharges") ? "₹ " + Math.round(this.state.selectedRide.driverCharges).toLocaleString() : "₹ 0"}</Text>
                            </Column>
                            <Column>
                                <Title>Vehicle</Title>
                                <Text>{this.state.selectedRide.categoryDesc}</Text>
                            </Column>
                            <Column>
                                <Title>GST</Title>
                                <Text>{this.state.selectedRide.hasOwnProperty("serviceTax") ? "₹ " + Math.round(this.state.selectedRide.serviceTax).toLocaleString() : "₹ 0"}</Text>
                            </Column>
                            <Column>
                                <Title>Price/Km</Title>
                                <Text>{this.state.selectedRide.hasOwnProperty("actualCostPerKm") ? "₹ " + Math.round(this.state.selectedRide.actualCostPerKm).toLocaleString() : "₹ 0"}</Text>
                            </Column>
                            <Column>
                                <Title>Extra Cost/Km</Title>
                                <Text>{this.state.selectedRide.hasOwnProperty("extraCostPerKm") ? "₹ " + Math.round(this.state.selectedRide.extraCostPerKm).toLocaleString() : "₹ 0"}</Text>
                            </Column>
                            <Column>
                                <Title>Extra Cost/Hr</Title>
                                <Text>{this.state.selectedRide.hasOwnProperty("extraCostPerHr") ? "₹ " + Math.round(this.state.selectedRide.extraCostPerHr).toLocaleString() : "₹ 0"}</Text>
                            </Column>
                            <Column>
                                <Title>Days</Title>
                                <Text>{this.state.selectedRide.hasOwnProperty("noOfDays") ? this.state.selectedRide.noOfDays : 0}</Text>
                            </Column>
                        </Row>
                    </AccordionItem>
                    <Row>
                        <AccordionItem title="Vehicle, Driver Details & Options" isActive fixed>
                        {this.state.driver.hasOwnProperty("mobileNo") && this.state.vehicle.hasOwnProperty("regNo") && <Row>
                                <Column>
                                    <Title>Driver Name</Title>
                                    <Text>{this.state.driver.name}</Text>
                                </Column>
                                <Column>
                                    <Title>Driver Phone</Title>
                                    <a css={css`${tw`flex py-2 font-medium`}`} href={"tel:+91" + this.state.driver.mobileNo}>{this.state.driver.mobileNo}</a>
                                </Column>
                                <Column>
                                    <Title>Vehicle #</Title>
                                    <Text>{this.state.vehicle.regNo}</Text>
                                </Column>
                            </Row>}
                            {!this.state.driver.hasOwnProperty("mobileNo") && !this.state.vehicle.hasOwnProperty("regNo") && !this.state.selectedRide.bidStatusDesc.toLowerCase().includes("cancelled") && <Row>
                                <Column>
                                    <Title>Driver</Title>
                                    <div css={css`${tw`w-full text-base font-medium appearance-none outline-none shadow-none border-none`}`}>
                                    <Select options={this.props.drivers} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} labelField="name" onChange={(val) => {
                                        if (val.length > 0) {
                                            this.setState({ licenseNo: val[0].licenseNo })
                                        }
                                    }} />
                                    </div>
                                </Column>
                                <Column>
                                    <Title>Vehicle</Title>
                                    <div css={css`${tw`w-full text-base font-medium appearance-none outline-none shadow-none border-none`}`}>
                                    <Select options={this.props.cars} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} labelField="model_description" onChange={(val) => {
                                        if (val.length > 0) {
                                            this.setState({ regNo: val[0].reg_no })
                                        }
                                    }} />
                                    </div>
                                </Column>
                                <Column css={css`${tw`w-full justify-end py-4`}`}>
                                    <Button onClick={this.handleAssignClick}>Assign Driver</Button>
                                </Column>
                            </Row>}
                            {!this.state.driver.hasOwnProperty("mobileNo") && !this.state.vehicle.hasOwnProperty("regNo") && this.state.selectedRide.bidStatusDesc.toLowerCase().includes("cancelled") && <Row css={css`${tw`-mt-5`}`}>
                                <Column>
                                    <Text>No Data</Text>
                                </Column>
                            </Row>}
                        </AccordionItem>
                        <AccordionItem title="Final Bill Details" isActive fixed>
                            <Row>
                                <Column>
                                    <Title>Od. Start Reading</Title>
                                    <Text>{this.state.selectedRide.hasOwnProperty("odometerStartReading") ? this.state.selectedRide.odometerStartReading : 0}</Text>
                                </Column>
                                <Column>
                                    <Title>Od. End Reading</Title>
                                    <Text>{this.state.selectedRide.hasOwnProperty("odometerEndReading") ? this.state.selectedRide.odometerEndReading : 0}</Text>
                                </Column>
                                <Column>
                                    <Title>Total Amount</Title>
                                    <Text>{this.state.selectedRide.hasOwnProperty("totalBillAmount") ? "₹ " + Math.round(this.state.selectedRide.totalBillAmount).toLocaleString() : "₹ 0"}</Text>
                                </Column>
                                <Column>
                                    <Title>Driver Charges</Title>
                                    <Text>{this.state.selectedRide.hasOwnProperty("driverCharges") ? "₹ " + Math.round(this.state.selectedRide.driverCharges).toLocaleString() : "₹ 0"}</Text>
                                </Column>
                                <Column>
                                    <Title>Total KMs</Title>
                                    <Text>{this.state.selectedRide.hasOwnProperty("packageKms") ? Math.round(this.state.selectedRide.packageKms).toLocaleString() : "₹ 0"}</Text>
                                </Column>
                                <Column>
                                    <Title>GST</Title>
                                    <Text>{this.state.selectedRide.hasOwnProperty("serviceTax") ? "₹ " + Math.round(this.state.selectedRide.serviceTax).toLocaleString() : "₹ 0"}</Text>
                                </Column>
                            </Row>
                        </AccordionItem>
                    </Row>
                </SliderModal>}
                <StyledPopup
                    open={this.state.openPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div css={css`${tw`border-2`}`}>
                        <div className="modal" style={{ position: 'absolute', right: 10 }}>
                            <a style={{cursor: 'pointer' }} className="close" onClick={this.closeModal}>
                                <div style={{ textAlign: 'center', color: 'gray', cursor: 'pointer' }}>Close</div>
                            </a>
                        </div>
                        {this.state.showRideStart && <div css={css`${tw`p-2`}`}>
                            <div css={css`${tw`block w-full items-center`}`}>
                                <p css={css`${tw`text-base text-black`}`}>Start Date:</p>
                                <DatePicker
                                    selected={Moment(this.state.startDate).toDate()}
                                    onChange={date => this.handleStartDateChange(date)}
                                    showTimeSelect
                                    timeFormat="hh:mm a"
                                    timeIntervals={30}
                                    timeCaption="Time"
                                    dateFormat="MMMM dd, yyyy hh:mm aa"
                                    customInput={<CalendarInput />}
                                    tabIndex={1}
                                    calendarContainer={MyContainer}
                                />
                            </div>
                            <div css={css`${tw`block items-center w-full mt-4`}`}>
                                <p css={css`${tw`text-base text-black`}`}>Odometer Start Reading:</p>
                                <input type="text" css={css`${tw`border border-gray-600 p-2 text-base`}`} onChange={(e) => this.setState({ odometerStartReading: e.target.value })} /></div>
                            <div css={css`${tw`mt-4 flex w-full`}`}>
                                <Button css={css`${tw`rounded-none px-4`}`} onClick={this.handleStartClick}>Start</Button>
                            </div>
                        </div>}
                        {this.state.showRideEnd && <div css={css`${tw`p-2`}`}>
                            <div css={css`${tw`block w-full items-center`}`}>
                                <p css={css`${tw`text-base text-black`}`}>End Date:</p>
                                <div css={css`${tw`flex items-center content-center justify-start`}`}>
                                <DatePicker
                                    selected={Moment(this.state.endDate).toDate()}
                                    onChange={date => this.handleEndDateChange(date)}
                                    showTimeSelect
                                    timeFormat="hh:mm a"
                                    timeIntervals={30}
                                    timeCaption="Time"
                                    dateFormat="MMMM dd, yyyy h:mm aa"
                                    customInput={<CalendarInput />}
                                    tabIndex={1}
                                    calendarContainer={MyContainer}
                                />
                                </div>
                            </div>
                            <div css={css`${tw`block items-center w-full mt-4`}`}>
                                <p css={css`${tw`text-base text-black`}`}>Odometer End Reading:</p>
                                <input type="text" css={css`${tw`border border-gray-600 p-2 text-base`}`} onChange={(e) => this.setState({ odometerEndReading: e.target.value })} /></div>
                            <div css={css`${tw`mt-4 flex w-full`}`}>
                                <Button css={css`${tw`rounded-none px-4`}`} onClick={this.handleEndClick}>End</Button>
                            </div>
                        </div>}
                    </div>
                </StyledPopup>
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    partnerBookings: state.user.partnerBookings,
    isPartnerBookingsAvailable: state.user.isPartnerBookingsAvailable,
    isPartnerBookingsLoading: state.user.isPartnerBookingsLoading,
    driver: state.partner.driver,
    vehicle: state.partner.vehicle,
    ride: state.partner.ride,
    drivers: state.user.drivers,
    cars: state.user.cars
})

export default connect(mapStateToProps)(BookingsMobile)

const CalendarInput = ({ value, onClick }) => (
    <div css={css`${tw`p-2 text-center border border-gray-600 text-base`}`} className="example-custom-input" onClick={onClick}>
        {value}
    </div>
);

const MyContainer = ({ className, children }) => (
    <div className={className} style={{ marginLeft: '-10%'}}>
        <div style={{ position: "relative", display: "flex" }}>{children}</div>
    </div>
)