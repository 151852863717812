import axios from 'axios';
import Moment from 'moment';
import {fetchDefaultHotels,fetchDefaultRecreationDetails} from './appActions';
import {urls as urls} from '../api/urls';
import _ from "lodash";

let nextStopId = 0;

export function updatePickupCity(data) {
  debugger
  return {type:'UPDATE_PICKUP_CITY',data};
}

export function updatePickupLocation(data) {
  debugger
  return {type:'UPDATE_PICKUP_LOCATION',data};
}

export function updateDestLocation(data) {
  debugger
  return {type:'UPDATE_DEST_LOCATION',data};
}

export function updateDestCity(data) {
  debugger
  return {type:'UPDATE_DEST_CITY',data};
}

export function updateChauffeurStartDate(date) {
  debugger
  return {type:'UPDATE_CHAUFFEUR_STARTDATE', date};
}

export function updateChauffeurEndDate(date) {
  debugger
  return {type:'UPDATE_CHAUFFEUR_ENDDATE', date};
}

export function updateSelfDriveStartDate(date) {
  debugger
  return {type:'UPDATE_SELFDRIVE_STARTDATE', date};
}

export function updateSelfDriveEndDate(date) {
  debugger
  return {type:'UPDATE_SELFDRIVE_ENDDATE', date};
}

export function updateStartTime(data) {
  return {type:'UPDATE_STARTTIME',data};
}

export function updateEndTime(data) {
  return {type:'UPDATE_ENDTIME',data};
}

export function updateNoOfDays(data) {
  return {type:'UPDATE_NOOF_DAYS',data};
}

export function updateRideDistance(data) {
  return {type:'UPDATE_RIDE_DISTANCE',data};
}

export function updateRideDuration(data) {
  return {type:'UPDATE_RIDE_DURATION',data};
}

export function updateTripType(data){
  if(data == "Round Trip"){
    return {type:'UPDATE_TRIP_TYPE',id:'100010001'}
  }
  else if(data=="One Way"){
    return {type:'UPDATE_TRIP_TYPE',id:'100010011'}
  }
}

export function addFirstStop(data) {
  debugger
  let id = 1;
  nextStopId = nextStopId+1;
  return {type:'ADD_FIRST_STOP',id,data}
}

export function addStop(data) {
  debugger
  let id = ++nextStopId
  return {type:'ADD_STOP',id,data}
}

export function addStopAtIndex(index,data) {
  debugger
  return {type:'ADD_STOP_AT_INDEX',index,data}
}

export function removeStop(id){
  nextStopId--;
  return {type:'REMOVE_STOP',id}
}

export function removeLastStop(){
  return {type:'REMOVE_LAST_STOP'}
}

export function editStop(id,data) {
  debugger
  return {type:'EDIT_STOP',id,data}
}

export function addHotelStop(id,hotelId,data) {
  debugger
  return {type:'ADD_HOTEL_STOP',id,hotelId,data}
}

export function removeHotelStop(hotelId) {
  return {
    type : 'REMOVE_HOTEL_STOP',
    hotelId
  }
}

export function addRecreationStop(id,recId,data) {
  debugger
  return {type:'ADD_RECREATION_STOP',id,recId,data}
}

export function removeRecreationStop(recId) {
  return {
    type : 'REMOVE_RECREATION_STOP',
    recId
  }
}

export function carsLoadingToTrue(){
  return {
    type : 'CARS_LOADING_TRUE'
  }
}

export function carsLoadingToFalse(){
  return {
    type : 'CARS_LOADING_FALSE'
  }
}

export function fetchVehiclesSuccess() {
  return {
    type:'FETCH_VEHICLES_SUCCESS'
  }
}

export function fetchVehiclesFail() {
  return {
    type:'FETCH_VEHICLES_FAIL'
  }
}

export function updateCarsData(data){
  debugger
  return{
    type:'UPDATE_CARS_DATA',
    data
  }
}

export function setSelfDrivenToTrue() {
  return {
    type:'SELF_DRIVEN_TRUE',
  }
}

export function setSelfDrivenToFalse() {
  return {
    type:'SELF_DRIVEN_FALSE',
  }
}

export function setChauffeurDrivenToTrue() {
  return {
    type:'SET_CHAUFFEUR_DRIVEN_TRUE',
  }
}

export function setChauffeurDrivenToFalse() {
  return {
    type:'SET_CHAUFFEUR_DRIVEN_FALSE',
  }
}

export function setHourlyRentalToTrue() {
  return {
    type:'HOURLY_RENTAL_TRUE',
  }
}

export function setHourlyRentalToFalse() {
  return {
    type:'HOURLY_RENTAL_FALSE',
  }
}

export function setAirportTransferToTrue() {
  return {
    type:'AIRPORT_TRANSFER_TRUE',
  }
}

export function setAirportTransferToFalse() {
  return {
    type:'AIRPORT_TRANSFER_FALSE',
  }
}

export function updateRentalType(data){
  return {
      type: 'UPDATE_RENTAL_TYPE',
      data
  }
}


export function fetchLocationByLatLong(city) {
  console.log(city);
  return function(dispatch,getState) {
    debugger
    if(city == 'Secunderabad'){
      city = 'Hyderabad';
    }
    if(city == "Bangalore" || city=="Bengaluru Rural" || city=="Bengaluru Urban" || city == "Bangalore Rural"|| city=="Bangalore Urban"){
      city = "Bengaluru";
    }
    let self = this;
    let url = urls.locationByLatLongUrl;
    let input = {name : city};
    debugger
    axios.post(url,input)
      .then(function(response) {
        debugger
        console.log(response);
        if(response.data.statusCD == 200) {
          debugger
          let result = response.data.responseData;
          dispatch(updatePickupCity({city:city,cityLat : result.latitude,cityLng:result.longitude, cityLeadTime : result.leadTime}));
        }
        if(response.data.statusCD == 404) {
          dispatch(updatePickupCity({city:'',cityLat : '',cityLng:'', cityLeadTime : ''}));
        }
      })
      .catch(function(error) {
        console.log(error);
        dispatch(updatePickupCity({city:'',cityLat : '',cityLng:'', cityLeadTime : ''}));
      });
  }
}


let cars = [];
export function fetchVehiclesForChauffeurDriven() {
  return function(dispatch,getState) {
    dispatch(carsLoadingToTrue());
    let ride = getState().ride;
    let app= getState().app;
    let url = urls.vehiclesUrl;
    let data;
    let accountId = getState().user.userDetails.accountId
    let stops=[];
     data = {
          "pickupLocation": {
              "latitude": ride.pickup.cityLat,
              "longitude": ride.pickup.cityLng
            },
            "destinationLocation": {
             "latitude": ride.dest.cityLat,
              "longitude": ride.dest.cityLng
            },
          "ride": {
            // "measureLookupId": ride.measureLookupId,
            // "rideHrs": ride.rideDuration,
            "distance":ride.rideDistance,
            "rideStartDate": ride.chauffeurStartDate + " " +ride.startTime,
            "accountId": accountId
          }
      }
    if(ride.measureLookupId == "100010001" && !app.isAirport && !app.isRental){
      data.ride.measureLookupId=ride.measureLookupId;
      data.ride.rideEndDate = ride.chauffeurEndDate + " " +ride.endTime;
      data.ride.distance =  parseInt(ride.rideDistance);
      for(let i=0;i<ride.stops.length;i++){
        stops.push(ride.stops[i]);
    }
    // data.stops = stops;
    }
    else if(ride.measureLookupId == '100010011'  && !app.isAirport && !app.isRental){
      data.ride.measureLookupId=ride.measureLookupId;
      data.ride.rideEndDate = ride.chauffeurEndDate + ' 23:59:00';
      data.ride.distance = ride.rideDistance;
      for(let i=0;i<ride.stops.length;i++){
        stops.push(ride.stops[i]);
    }
    // data.stops = stops;
  }
  else if(app.isAirport){
    let isAirportPickup = ride.pickup.loc.includes("Airport") || ride.pickup.loc.includes("airport") ? true : false;
    let isAirportDrop = ride.dest.loc.includes("Airport") || ride.dest.loc.includes("airport") ? true : false;
    stops.push(ride.stops[0]);
    stops.push(ride.stops[1]);
    data.stops=stops;
    data.destinationLocation.latitude=isAirportDrop ? ride.dest.locLat  :ride.dest.cityLat;
    data.destinationLocation.longitude= isAirportDrop ? ride.dest.locLng  :ride.dest.cityLng;
    data.ride.measureLookupId=isAirportPickup ? 100010014 : (isAirportDrop ? 100010015 : 100007120);
    data.ride.rideEndDate=ride.chauffeurEndDate + " " + ride.endTime;
    data.ride.distance = ride.measureLookupId == "100010001" ? parseInt(ride.rideDistance / 2) : ride.rideDistance;
  }
  else if(app.isRental){
    data.destinationLocation.latitude = undefined;
    data.destinationLocation.longitude = undefined;
    let measureLookupId;
    if(ride.rentalType === 4){
        measureLookupId = 100010009
        // 2 hrs
    }
    else if(ride.rentalType === 5){
        measureLookupId = 100010006
        // 4 hrs
    }
    else if(ride.rentalType === 6){
        measureLookupId = 100010007
        // 8hours
    }
    else{
        measureLookupId = null
    }
    stops.push(ride.stops[0]);
    data.stops = stops;
    data.ride.measureLookupId=measureLookupId;
    data.ride.rideEndDate=ride.chauffeurEndDate + " " + ride.endTime;
    // delete data.destinationLocation;
     delete data.ride.distance;
  }
    debugger
    console.log(url);
    console.log(ride.rideDistance);
    console.log(data);
    axios.post(url, data)
      .then(function (response) {
        debugger
        dispatch(carsLoadingToFalse());
        console.log(response);
        if(response.data.statusCD == 200&&response.data.hasOwnProperty("responseData")&&response.data.responseData.length > 0){
          let result = _.cloneDeep(response.data.responseData);
          for(let i = 0; i< result.length; i++){
            let newObj = Object.assign({},result[i].vendors[0], 
              { 
                sub_category: result[i].sub_category, 
                sub_category_id: result[i].sub_category_id, 
                sub_category_url: result[i].sub_category_url, 
                no_of_seats: result[i].no_of_seats, 
                category_id: result[i].category_id, 
                category: result[i].category 
              })
              result[i].vendors[0] = newObj
          }
          // for(let i = 0; i < result.length; i++){
          //   cars.push({
          //     sub_category : result[i].sub_category,
          //     sub_category_id:result[i].sub_category_id,
          //     sub_category_url :  result[i].sub_category_url,
          //     vendors:[]
          //   });

          //   cars[i].vendors.push(result[i]);

          //   for(let j = i + 1; j < result.length; j++){
          //     if(cars[i].sub_category_id == result[j].sub_category_id){
          //       cars[i].vendors.push(result[j]);
          //       result.splice(j,1);
          //       j--;
          //     }
          //   }
          // }
          dispatch(updateCarsData(result));
          dispatch(fetchVehiclesSuccess());
          dispatch(fetchDefaultHotels());
          dispatch(fetchDefaultRecreationDetails());
        }
        else {
          dispatch(fetchVehiclesFail());
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("An Error Occured at the Server. Please Try Again Later");
        dispatch(carsLoadingToFalse());
        dispatch(fetchVehiclesFail());
      });
  }
}

export function fetchVehiclesForSelfDriven() {
  return function(dispatch,getState){
    dispatch(carsLoadingToTrue());
    let ride = getState().ride;
    let url = urls.vehiclesUrl;
    let app = getState().app;
    let data = {
                "pickupLocation": {
                  "latitude": ride.pickup.cityLat,
                  "longitude": ride.pickup.cityLng
                },
                "ride": {
                  "rideStartDate": ride.selfDriveStartDate +" "+ ride.startTime,
                  "rideEndDate": ride.selfDriveEndDate +" "+ ride.endTime,
                  "accountId": Number(app.accountId)
                }
              }
    debugger
    axios.post(url,data)
      .then(function (response) {
        debugger
        dispatch(carsLoadingToFalse());
        console.log(response);
        if(response.data.statusCD == 200&&response.data.hasOwnProperty("responseData")&&response.data.responseData.length > 0){
          cars.length = 0;
          let result = response.data.responseData;
          for(let i=0;i<result.length;i++){
            debugger
            cars.push(
              {
                sub_category:result[i].sub_category,
                sub_category_id:result[i].sub_category_id,
                image_url:result[i].image_url,
                security_deposit:result[i].security_deposit,
                terms_conditions_url:result[i].terms_conditions_url,
                sub_category_url :  result[i].sub_category_url,
                withoutFuel : [],
                withFuel : []
              }
            )
            if(result[i].measure_cd.split(' ')[1] == 'without'){
              cars[i].withoutFuel.push(result[i]);
            }
            else{
              cars[i].withFuel.push(result[i]);
            }
            for(let j=i+1;j<result.length;j++) {
              if(cars[i].sub_category_id == result[j].sub_category_id){
                if(result[j].measure_cd.split(' ')[1] == 'with'){
                  cars[i].withFuel.push(result[j]);
                }
                else if(result[j].measure_cd.split(' ')[1] == 'without'){
                  cars[i].withoutFuel.push(result[j]);
                }
                result.splice(j,1);
                j--;
              }
            }
          }
          dispatch(updateCarsData(cars));
          dispatch(fetchVehiclesSuccess());
          dispatch(fetchDefaultHotels());
          dispatch(fetchDefaultRecreationDetails());
        }

        else{
          dispatch(fetchVehiclesFail());
        }
      })
      .catch(function (error) {
        alert("An Error Occured at the Server. Please Try Again Later");
        dispatch(carsLoadingToFalse());
        dispatch(fetchVehiclesFail());
      });
  }
}