import tw from "tailwind.macro"
import React from "react"
import { css } from '@emotion/core'
import "../fonts/fonts.css"
import styled from "@emotion/styled"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import {updateUserDetails} from "../actions/userAction"

const Wrapper = tw.div`
  flex items-center justify-start w-full top-0 z-40
`
const ContainerOne = tw.div`
  flex items-center w-1/2 md:w-1/4 lg:w-1/4 px-5 lg:px-10 py-5
`
const ContainerTwo = tw.div`
  flex items-center justify-end w-1/2 md:w-9/12 lg:w-9/12 px-5 lg:px-10 py-5
`
const Text = tw.p`
  ml-1 text-gray-600 text-lg md:text-lg lg:text-3xl font-medium
`
const Button = styled.div`
  ${tw`px-4 py-1 text-white text-base lg:text-lg font-semibold rounded appearance-none outline-none shadow-none border-none text-center`}
  background-color: #8a3df6;
`
const Link = styled.div`
  ${tw`hidden md:flex lg:flex px-2 py-1 text-black text-xs lg:text-lg font-medium rounded appearance-none outline-none shadow-none border-none text-center`}
`
const Hamburger = styled.div`
${tw`flex md:hidden lg:hidden px-1 py-1 bg-white shadow-full rounded-full cursor-pointer`}
`
const MenuDropDown = styled.div`
${tw`block relative items-center self-center justify-center content-center`}
`
const VendorContainer = styled.div`
${tw`hidden md:flex lg:flex px-4 items-center cursor-pointer`}
`
const VendorLogo = styled.img`
${tw`h-8 w-8 rounded-full border border-black`}
object-fit: contain;
`
const VendorName = styled.p`
${tw`ml-1 text-black text-xs lg:text-lg font-medium text-center`}
`
const DownArrow = styled.i`
${tw`ml-2 mb-1`}
  border: solid black;
  border-width: 0px 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`
const DropDown = styled.div`
${tw`absolute hidden md:block w-0 md:w-24 lg:w-32 lg:block bg-white border border-black`}
left: 1rem;
top: 2rem;
`
const DropDownItem = styled.ul`
${tw`py-1 px-1 text-black text-xs lg:text-base font-medium`}
`


class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDropDown: false
    }
  }

  componentDidMount() {
    if (typeof window != `undefined`) {
      let businessData = JSON.parse(window.localStorage.getItem('businessData'))
      if(businessData == null && window.location.pathname != "/"){
        window.localStorage.removeItem("businessData")
        navigate('/login', { state: {} })
      }
      else if(businessData != null && businessData.hasOwnProperty("userDetails")){
        this.props.dispatch(updateUserDetails(businessData.userDetails))
      }
    }
  }

  handleLogOut = () => {
    window.localStorage.removeItem("businessData")
    navigate('/login', { state: {} })
  }

  render() {
    return (
      <Wrapper css={css`${this.props.shadow ? tw`border-b-2 relative` : tw`absolute`}`}>
        <ContainerOne>
          <img
            css={css`${tw`h-10 md:h-10 lg:h-16`}`}
            src={require(this.props.dark ? '../svgs/logo-with-text.svg' : '../svgs/logo-with-light-text.svg')}
            alt="Zyppys Logo" />
          <Text css={css`${this.props.dark ? tw`` : tw`mb-1 sm:mb-1 md:mb-1 lg:mb-1`}`}>business</Text>
        </ContainerOne>
        {typeof window != `undefined` && <ContainerTwo>
          {this.props.showLogin && <a href="login"><Button>Log In</Button></a>}
          {this.props.showRegister && <a href="/"><Button>Register</Button></a>}
          {this.props.business && <>
          <a href={window.location.origin + "/booking"}><Link css={css`${tw``} text-decoration: ${window.location.pathname == "/booking" || window.location.pathname == "/booking/" ? "underline !important" : "none" }`}>Rent a Car</Link></a>
          <a href={window.location.origin + "/bookingList"}><Link css={css`${tw``} text-decoration: ${window.location.pathname.includes("/bookingList") ? "underline !important" : "none" }`}>Bookings</Link></a>
          <a href={window.location.origin + "/payments"}><Link css={css`${tw``} text-decoration: ${window.location.pathname.includes("/payments") ? "underline !important" : "none" }`}>Payments</Link></a>
          {/* <a href={window.location.origin + "/support"}><Link css={css`${tw``} text-decoration: ${window.location.pathname.includes("/support") ? "underline !important" : "none" }`}>Support</Link></a> */}
          <MenuDropDown onClick={() => this.setState({ showDropDown: true})} onMouseEnter={() => this.setState({ showDropDown: true})} onMouseLeave={()=>{this.setState({ showDropDown: false})}} ><VendorContainer>
            <VendorLogo src={"https://s3-us-west-2.amazonaws.com/vendorlogos/swing_logo.png"} />
            <VendorName>Swing</VendorName>
            <DownArrow />
          </VendorContainer>
            {this.state.showDropDown && <DropDown>
              <a href={window.location.origin + "/profile"} onClick={()=>{this.setState({ showDropDown: false})}}><DropDownItem css={css`${tw`hover:bg-purple-500 hover:text-white`} background-color: ${window.location.pathname.includes("/profile") ? "#8a3df6" : "#FFFFFF" }; color: ${typeof window != `undefined` && window.location.pathname.includes("/profile") ? "white" : "black" }`}>Profile</DropDownItem></a>
              {/* <a href={window.location.origin + "/settings"} onClick={()=>{this.setState({ showDropDown: false})}}><DropDownItem css={css`${tw`hover:bg-purple-500 hover:text-white`} background-color: ${window.location.pathname.includes("/settings") ? "#8a3df6" : "#FFFFFF" }; color: ${typeof window != `undefined` && window.location.pathname.includes("/settings") ? "white" : "black" }`}>Settings</DropDownItem></a> */}
              <a href={window.location.origin + "/support"} onClick={()=>{this.setState({ showDropDown: false})}}><DropDownItem css={css`${tw`hover:bg-purple-500 hover:text-white`} background-color: ${window.location.pathname.includes("/support") ? "#8a3df6" : "#FFFFFF" }; color: ${typeof window != `undefined` && window.location.pathname.includes("/support") ? "white" : "black" }`}>Support</DropDownItem></a>
              <DropDownItem css={css`${tw`hover:bg-purple-500 hover:text-white cursor-pointer`}`} onClick={this.handleLogOut}>Log out</DropDownItem>
            </DropDown>}
          </MenuDropDown>
          </>}
          {this.props.partner && <>
          <a onClick={()=>navigate('/booking', { state: { } })}><Link css={css`${tw`cursor-pointer`} text-decoration: ${window.location.pathname == "/booking" || window.location.pathname == "/booking/" ? "underline !important" : "none" }`}>Rent a Car</Link></a>
          <a href={window.location.origin + "/partners/bookings"}><Link css={css`${tw``} text-decoration: ${window.location.pathname.includes("/bookings") ? "underline !important" : "none" }`}>Bookings</Link></a>
          <a href={window.location.origin + "/partners/driversAndCars"}><Link css={css`${tw``} text-decoration: ${window.location.pathname.includes("/driversAndCars") ? "underline !important" : "none" }`}>Drivers And Cars</Link></a>
          <a href={window.location.origin + "/partners/pricing"}><Link css={css`${tw``} text-decoration: ${window.location.pathname.includes("/pricing") ? "underline !important" : "none" }`}>Pricing</Link></a>
          <a href={window.location.origin + "/partners/payments"}><Link css={css`${tw``} text-decoration: ${window.location.pathname.includes("/payments") ? "underline !important" : "none" }`}>Payments</Link></a>
          {/* <a href={window.location.origin + "/partners/support"}><Link css={css`${tw``} text-decoration: ${window.location.pathname.includes("/support") ? "underline !important" : "none" }`}>Support</Link></a> */}
          <MenuDropDown onClick={() => this.setState({ showDropDown: true})} onMouseEnter={() => this.setState({ showDropDown: true})} onMouseLeave={()=>{this.setState({ showDropDown: false})}}><VendorContainer>
            <VendorLogo src={"https://s3-us-west-2.amazonaws.com/vendorlogos/swing_logo.png"} />
            <VendorName>Swing</VendorName>
            <DownArrow />
          </VendorContainer>
            {this.state.showDropDown && <DropDown>
              <a href={window.location.origin + "/partners/profile"} onClick={()=>{this.setState({ showDropDown: false})}}><DropDownItem css={css`${tw`hover:bg-purple-500 hover:text-white`} background-color: ${window.location.pathname.includes("/profile") ? "#8a3df6" : "#FFFFFF" }; color: ${typeof window != `undefined` && window.location.pathname.includes("/profile") ? "white" : "black" }`}>Profile</DropDownItem></a>
              {/* <a href={window.location.origin + "/partners/settings"} onClick={()=>{this.setState({ showDropDown: false})}}><DropDownItem css={css`${tw`hover:bg-purple-500 hover:text-white`} background-color: ${window.location.pathname.includes("/settings") ? "#8a3df6" : "#FFFFFF" }; color: ${typeof window != `undefined` && window.location.pathname.includes("/settings") ? "white" : "black" }`}>Settings</DropDownItem></a> */}
              <a href={window.location.origin + "/partners/support"} onClick={()=>{this.setState({ showDropDown: false})}}><DropDownItem css={css`${tw`hover:bg-purple-500 hover:text-white`} background-color: ${window.location.pathname.includes("/support") ? "#8a3df6" : "#FFFFFF" }; color: ${typeof window != `undefined` && window.location.pathname.includes("/support") ? "white" : "black" }`}>Support</DropDownItem></a>
              <DropDownItem css={css`${tw`hover:bg-purple-500 hover:text-white cursor-pointer`}`} onClick={this.handleLogOut}>Log out</DropDownItem>
            </DropDown>}
          </MenuDropDown>
          </>}
          {(this.props.business || this.props.partner) && <Hamburger onClick={this.props.openMenu}><img src={require('../svgs/menu-01.svg')} css={css`${tw`h-8`}`} alt="menu" /></Hamburger>}
        </ContainerTwo>}
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(Header);
