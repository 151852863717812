import React from "react"
import { connect } from "react-redux"
import tw from "tailwind.macro"
import styled from "@emotion/styled"
import ReactTable from "react-table";
import "react-table/react-table.css";
import { css } from "@emotion/core"
import { fetchProductPricingBusiness } from "../../actions/userAction"
import SliderModal from "../SliderModal"
import Overlay from "../../components/Overlay"
import Select from "react-dropdown-select"
import { types, outstation, airport, hourly } from "./StaticData"
import AccordionItem from "../AccordionItem"
import Loader from 'react-loader-spinner'
import { insertOrUpdateProductPricingBusiness } from "./Functions"

const Wrapper = tw.div`
  hidden md:flex lg:flex relative w-full flex-col bg-white p-4 md:px-8 lg:px-8
`
const LoaderContainer = styled.div`
  ${tw`flex justify-center h-full w-full bg-black absolute left-0 -mt-4 opacity-50`}
`
const Row = tw.div`
flex w-full justify-around items-center
`
const Column = tw.div`
block
`
const Title = tw.p`
flex text-gray-600
`
const Text = tw.p`
flex py-2 font-medium
`
const InputText = tw.input`
w-40 lg:w-56 text-sm lg:text-base font-medium bg-gray-300 px-1 lg:px-5 py-2 appearance-none outline-none shadow-none border-none
`
const RadioButton = styled.div`
${tw`h-8 w-8 rounded-full border-2 border-purple-500 cursor-pointer`}
min-width: 2rem;
min-height: 2rem;
max-height: 2rem;
max-width: 2rem;
`
const Button = styled.button`
  ${tw`py-1 px-2 text-center text-base font-normal text-white`}
  background-color: #8a3df6;
`

class PricingPortal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showDetails: false,
            showOverlay: false,
            productPricing: [],
            typeValue: null,
            locationId: null,
            measureLookupId: null,
            showAdd: false,
            showLoader: false,
            isActive: true
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.productPricing != this.props.productPricing){
            this.setState({ productPricing: this.props.productPricing })
        }
    }

    filterCaseInsensitive = (filter, row) => {
        console.log(filter);
        const id = filter.pivotId || filter.id;
        let value = filter.value;
        console.log(value);
        if (typeof row[id] == 'string') {
            console.log(id);
            if (row[id] !== undefined) {
                return String(row[id].toLowerCase()).includes(value.toLowerCase());
            }
            else {
                return true;
            }
        }
        else {

            if (row[id] !== undefined) {
                return String(row[id]).includes(value.toString());
            }
            else {
                return true
            }
        }
    }

    handleRowClicked = (data) => {
        console.log("data",data)
        window.document.getElementsByTagName("body")[0].style.overflow = "hidden"
        this.setState({
            showDetails: true,
            showOverlay: true,
            showAdd: false,
            categoryId: data.categoryId,
            subCategoryId: data.subCategoryId,
            categoryDesc: data.categoryDesc,
            subCategoryDesc: data.subCategoryDesc,
            minCost: data.minCost,
            maxCost: data.maxCost,
            actualCostPerKm: data.actualCostPerKm,
            actualCostPerHr: data.actualCostPerHr,
            extraCostPerKm: data.extraCostPerKm,
            extraCostPerHr: data.extraCostPerHr,
            minKms: data.minKms,
            minHrs: data.minHrs,
            maxKms: data.maxKms,
            maxHrs: data.maxKms,
            serviceTaxPercentage: data.serviceTaxPercentage,
            isActive: data.isActive,
            noOfSeats: data.noOfSeats,
            driverCharges: data.driverCharges,
            nightCharges: data.nightCharges,
            id: data.id
        })
    }

    handleClose = () => {
        window.document.getElementsByTagName("body")[0].style.overflow = "auto"
        this.setState({ showOverlay: false, showDetails: false })
    }

    handleFetch = () => {
        if (this.state.locationId == null) {
            alert("Please select a location")
            return
        }
        if (this.state.typeValue == null) {
            alert("Please select the type")
            return
        }
        if (this.state.measureLookupId == null) {
            alert("Please select a package")
            return
        }
        let data = {
            "accountId": this.props.accountId,
            "locationId": this.state.locationId,
            "measureLookupId": this.state.measureLookupId
        }
        this.props.dispatch(fetchProductPricingBusiness(data))
    }

    handleSliderOpen = (state) => {
        if (state == 'add') {
            this.setState({ showAdd: true, showDetails: true, showOverlay: true, minKms: null, minHrs: null })
        }
    }

    toggleActive = () => {
        this.setState({ isActive: !this.state.isActive })
    }

    handleChange = (state, value) => {
        this.setState({ [state]: value })
    }

    handleAddClick = () => {
        let state = this.state;
        let data={
            "locationId": Number(this.state.locationId),
            "categoryId": Number(state.categoryId),
            "subCategoryId": Number(state.subCategoryId),
            "measureLookupId": Number(state.measureLookupId),
            "actualCostPerKm": state.measureLookupId===100010014 || state.measureLookupId===100010015 || state.measureLookupId === 100010006 || state.measureLookupId === 100010007 ? 0 : Number(state.actualCostPerKm),
            "actualCostPerHr": state.measureLookupId === 100010001 || state.measureLookupId ===100010011 ? 0: Number(state.actualCostPerHr),
            "extraCostPerKm": Number(state.extraCostPerKm),
            "extraCostPerHr": Number(state.extraCostPerHr),
            "minCost": Number(state.minCost),
            "minKms": Number(state.minKms),
            "minHrs": Number(state.minHrs),
            "maxCost": Number(state.minCost),
            "maxKms": 10000,
            "maxHrs": 99999,
            "noOfSeats": Number(state.noOfSeats),
            "driverCharges": Number(state.driverCharges),
            "nightCharges": Number(state.nightCharges),
            "serviceTaxPercentage": Number(state.serviceTaxPercentage),
            "aggregatorPercentage": 10,
            "isActive": state.isActive ? 1 : 0,
            "userId": 7832,
            "accountId": this.props.accountId,
        }
        this.props.dispatch(insertOrUpdateProductPricingBusiness(data, this.addSuccess, this.addError))
    }

    addSuccess = (data) => {
        alert("Pricing added successfully")
    }

    addError = (data) => {
        alert("Failed to add pricing");
    }

    handleUpdateClick = () => {
        let state = this.state;
        let data={
            "locationId": Number(this.state.locationId),
            "categoryId": Number(state.categoryId),
            "subCategoryId": Number(state.subCategoryId),
            "measureLookupId": Number(state.measureLookupId),
            "actualCostPerKm": state.measureLookupId===100010014 || state.measureLookupId===100010015 || state.measureLookupId === 100010006 || state.measureLookupId === 100010007 ? 0 : Number(state.actualCostPerKm),
            "actualCostPerHr": state.measureLookupId === 100010001 || state.measureLookupId ===100010011 ? 0: Number(state.actualCostPerHr),
            "extraCostPerKm": Number(state.extraCostPerKm),
            "extraCostPerHr": Number(state.extraCostPerHr),
            "minCost": Number(state.minCost),
            "minKms": Number(state.minKms),
            "minHrs": Number(state.minHrs),
            "maxCost": Number(state.maxCost),
            "maxKms": Number(state.maxKms),
            "maxHrs": Number(state.maxHrs),
            "noOfSeats": Number(state.noOfSeats),
            "driverCharges": Number(state.driverCharges),
            "nightCharges": Number(state.nightCharges),
            "serviceTaxPercentage": Number(state.serviceTaxPercentage),
            "aggregatorPercentage": 10,
            "isActive": state.isActive ? 1 : 0,
            "userId": 7832,
            "accountId": this.props.accountId,
            "id": Number(state.id)
        }
        this.props.dispatch(insertOrUpdateProductPricingBusiness(data, this.updateSuccess, this.updateError))
    }

    updateSuccess = (data) => {
        alert("Pricing updated successfully")
        let input = {
            "accountId": this.props.accountId,
            "locationId": this.state.locationId,
            "measureLookupId": this.state.measureLookupId
        }
        this.props.dispatch(fetchProductPricingBusiness(input))
        window.document.getElementsByTagName("body")[0].style.overflow = "auto"
        this.setState({ showOverlay: false, showDetails: false })
    }

    updateError = (data) =>  {
        alert("Failed to update pricing")
    }

    render() {
        return (
            <Wrapper>
                <h1 css={css`${tw`font-bold text-black text-lg py-4`}`}>Pricing</h1>
                {/* <div css={css`${tw`flex w-full font-normal text-black text-base py-2 items-center`}`}>
                    <div css={css`${tw`font-medium text-white py-2 px-4 shadow-lg cursor-pointer`} background-color: #8a3df6;`} onClick={() => this.handleSliderOpen('add')}>Add Pricing</div>
                </div> */}
                <div css={css`${tw`block py-2`}`}>
                    <p css={css`${tw`inline-block font-normal text-base cursor-pointer text-black`}`} onClick={() => this.handleSliderOpen('add')}>Add Pricing</p>
                    <p css={css`${tw`inline-block ml-4 font-normal text-base cursor-pointer text-purple-500 underline`}`}>Update Pricing</p>
                </div>
                <div css={css`${tw`flex w-full font-normal text-black text-base py-4 items-center`}`}>
                    <p css={css`${tw`font-medium`}`}>Location</p>
                    <div css={css`${tw`ml-4 w-40 lg:w-56 text-sm lg:text-base font-medium bg-gray-300 appearance-none outline-none shadow-none border-none`}`}>
                        <Select options={this.props.locations} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                            if (val.length > 0) {
                                this.setState({ locationId: val[0].value })
                            }
                        }} />
                    </div>
                    <p css={css`${tw`font-medium ml-4`}`}>Type</p>
                    <div css={css`${tw`ml-4 w-40 lg:w-56 text-sm lg:text-base font-medium bg-gray-300 appearance-none outline-none shadow-none border-none`}`}>
                        <Select options={types} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                            if (val.length > 0) {
                                this.setState({ typeValue: val[0].value });
                            }
                        }} />
                    </div>
                    {this.state.typeValue != null && <>
                        <p css={css`${tw`font-medium ml-4`}`}>Package</p>
                        <div css={css`${tw`ml-4 w-40 lg:w-56 text-sm lg:text-base font-medium bg-gray-300 appearance-none outline-none shadow-none border-none`}`}>
                            <Select options={this.state.typeValue == 1 ? outstation : this.state.typeValue == 2 ? airport : hourly} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                                if (val.length > 0) {
                                    this.setState({ measureLookupId: val[0].value });
                                }
                            }} />
                        </div>
                    </>}
                    <div css={css`${tw`font-medium ml-4 text-white py-2 px-4 shadow-lg cursor-pointer`} background-color: #8a3df6;`} onClick={this.handleFetch}>Search</div>
                </div>
                <ReactTable
                    data={this.state.productPricing}
                    style={{ cursor: 'pointer', width: '100%' }}
                    filterable
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: (e, handleOriginal) => {
                                if (rowInfo != undefined) {
                                    // this.handleRowClicked(rowInfo)
                                }
                                console.log("A Td Element was clicked!");
                                console.log("it produced this event:", e);
                                console.log("It was in this column:", column);
                                console.log("It was in this row:", rowInfo);
                                console.log("It was in this table instance:", instance);

                                // IMPORTANT! React-Table uses onClick internally to trigger
                                // events like expanding SubComponents and pivots.
                                // By default a custom 'onClick' handler will override this functionality.
                                // If you want to fire the original onClick handler, call the
                                // 'handleOriginal' function.
                                if (handleOriginal) {
                                    handleOriginal();
                                }
                            }
                        };
                    }}
                    columns={[
                        {
                            Header: "Category",
                            accessor: "categoryDesc",
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Sub Category",
                            accessor: "subCategoryDesc",
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Driver Charges",
                            accessor: "driverCharges",
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Night Charges",
                            accessor: "nightCharges",
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Cost/Km",
                            accessor: "actualCostPerKm",
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Cost/Hr",
                            accessor: "actualCostPerHr",
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Extra Cost/Km",
                            accessor: "extraCostPerKm",
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Extra Cost/Hr",
                            accessor: "extraCostPerHr",
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Update",
                            Cell: (data) => (
                                <Button onClick={() => this.handleRowClicked(data.row._original)}>Select</Button>
                            ),
                            style: { textAlign: "center" },
                        },
                    ]}
                    className="-striped -highlight"
                    defaultPageSize={20}
                    defaultFilterMethod={this.filterCaseInsensitive}
                />
                {/* DO NOT MOVE OVERLAY COMPONENT */}
                <Overlay active={this.state.showOverlay} overlayClicked={this.handleClose} />
                {this.state.showDetails && <SliderModal active={this.state.showDetails} heading={this.state.showAdd ? "Add Pricing" : "Update Pricing"} close={this.handleClose}>
                    {this.state.showLoader && <LoaderContainer style={{ zIndex: 1000 }} css={css`${tw``} `}>
                        <div css={css`${tw`self-center`}`}>
                            <Loader
                                type="Puff"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                        </div>
                    </LoaderContainer>}
                    {this.state.showAdd && <>
                        <AccordionItem title={"Basic Details"} isActive fixed>
                            <Row>
                                <Column>
                                    <Title>Location</Title>
                                    <div css={css`${tw`w-40 lg:w-56 text-sm lg:text-base font-medium bg-gray-300 appearance-none outline-none shadow-none border-none`}`}>
                                        <Select options={this.props.locations} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                                            if (val.length > 0) {
                                                this.setState({ locationId: val[0].value })
                                            }
                                        }} />
                                    </div>
                                    <Column>
                                        <Title>Trip Type</Title>
                                        <div css={css`${tw`w-40 lg:w-56 text-sm lg:text-base font-medium bg-gray-300 appearance-none outline-none shadow-none border-none`}`}>
                                            <Select options={types} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                                                if (val.length > 0) {
                                                    this.setState({ typeValue: val[0].value, minKms: null, minHrs: null })
                                                }
                                            }} />
                                        </div>
                                    </Column>
                                </Column>
                                <Column>
                                    <Title>Package</Title>
                                    <div css={css`${tw`w-40 lg:w-56 text-sm lg:text-base font-medium bg-gray-300 appearance-none outline-none shadow-none border-none`}`}>
                                        <Select options={this.state.typeValue == 1 ? outstation : this.state.typeValue == 2 ? airport : hourly} disabled={this.state.typeValue == null ? true : false} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                                            if (val.length > 0) {
                                                this.setState({ measureLookupId: val[0].value })
                                                if(val[0].value == 100010006){
                                                    this.setState({ minKms: 40, minHrs: 4})
                                                }
                                                else if(val[0].value == 100010007){
                                                    this.setState({ minKms: 80, minHrs: 8})
                                                }
                                                else{
                                                    this.setState({ minKms: null, minHrs: null})
                                                }
                                            }
                                        }} />
                                    </div>
                                    <Column>
                                        <Title>Category</Title>
                                        <div css={css`${tw`w-40 lg:w-56 text-sm lg:text-base font-medium bg-gray-300 appearance-none outline-none shadow-none border-none`}`}>
                                            <Select options={this.props.categories} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                                                if (val.length > 0) {
                                                    this.setState({ categoryId: val[0].value })
                                                }
                                            }} />
                                        </div>
                                    </Column>
                                </Column>
                                <Column>
                                    <Title>Sub Category</Title>
                                    <div css={css`${tw`w-40 lg:w-56 text-sm lg:text-base font-medium bg-gray-300 appearance-none outline-none shadow-none border-none`}`}>
                                        <Select options={this.props.subCategories} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                                            if (val.length > 0) {
                                                this.setState({ subCategoryId: val[0].value })
                                            }
                                        }} />
                                    </div>
                                    <Column>
                                        <Title>Number of Seats</Title>
                                        <InputText onChange={(e) => this.handleChange("noOfSeats", e.target.value)} />
                                    </Column>
                                </Column>
                                <Column>
                                    <Title>Driver Charges</Title>
                                    <InputText onChange={(e) => this.handleChange("driverCharges", e.target.value)} />
                                    <Column>
                                        <Title>Night Charges</Title>
                                        <InputText onChange={(e) => this.handleChange("nightCharges", e.target.value)} />
                                    </Column>
                                </Column>
                            </Row>
                        </AccordionItem>
                        <AccordionItem title={"Vehicle Pricing"} isActive fixed>
                            <Row>
                                <Column>
                                    <Title>{this.state.typeValue == null || this.state.typeValue == 3 ? "Package Cost" : "Minimum Cost"}</Title>
                                    <InputText onChange={(e) => this.handleChange("minCost", e.target.value)} />
                                    <Column>
                                        <Title>{this.state.typeValue == null || this.state.typeValue == 2 || this.state.typeValue == 3 ? "Cost/Hr" : "Cost/Km"}</Title>
                                        <InputText onChange={(e) => this.handleChange((this.state.typeValue == null || this.state.typeValue == 2 ? "actualCostPerHr" : "actualCostPerKm"), e.target.value)} />
                                    </Column>
                                </Column>
                                <Column>
                                    <Title>Extra Cost/Kms</Title>
                                    <InputText onChange={(e) => this.handleChange("extraCostPerKm", e.target.value)} />
                                    <Column>
                                        <Title>Extra Cost/Hr</Title>
                                        <InputText onChange={(e) => this.handleChange("extraCostPerHr", e.target.value)} />
                                    </Column>
                                </Column>
                                <Column>
                                    <Title>Minimum Kms/day</Title>
                                    <InputText onChange={(e) => this.handleChange("minKms", e.target.value)} disabled={this.state.typeValue == 3 ? true : false} value={this.state.minKms == null ? '' : this.state.minKms} />
                                    <Column>
                                        <Title>Minimum Hrs/day (4, 8, 12, 24)</Title>
                                        <InputText onChange={(e) => this.handleChange("minHrs", e.target.value)} disabled={this.state.typeValue == 3 ? true : false} value={this.state.minHrs == null ? '' : this.state.minHrs} />
                                    </Column>
                                </Column>
                                <Column>
                                    <Title>Service Tax(%)</Title>
                                    <InputText onChange={(e) => this.handleChange("serviceTaxPercentage", e.target.value)} />
                                    <Column>
                                        <Title>Status</Title>
                                        <div css={css`${tw`flex items-center`}`}>
                                            <RadioButton css={css`${this.state.isActive ? tw`bg-purple-500` : tw`bg-transparent`}`} onClick={this.toggleActive} />
                                            <Text css={css`${tw`ml-1`}`}>{this.state.isActive ? "Active" : "Inactive"}</Text>
                                        </div>
                                    </Column>
                                </Column>
                            </Row>
                            <Row css={css`${tw`flex justify-end py-4`}`}>
                                <Button onClick={this.handleAddClick}>Add Price</Button>
                            </Row>
                        </AccordionItem>

                    </>}
                    {!this.state.showAdd && <>
                        <AccordionItem title={"Pricing"} isActive fixed>
                            <Row>
                            <Column>
                                    <Title>Category</Title>
                                    <Text>{this.state.categoryDesc}</Text>
                                    <Column>
                                        <Title>Sub Category</Title>
                                        <Text>{this.state.subCategoryDesc}</Text>
                                    </Column>
                                </Column>
                                <Column>
                                    <Title>{this.state.typeValue == null || this.state.typeValue == 3 ? "Package Cost" : "Minimum Cost"}</Title>
                                    <InputText onChange={(e) => this.handleChange("minCost", e.target.value)} value={this.state.minCost} />
                                    <Column>
                                        <Title>{this.state.typeValue == null || this.state.typeValue == 2 || this.state.typeValue == 3 ? "Cost/Hr" : "Cost/Km"}</Title>
                                        <InputText onChange={(e) => this.handleChange((this.state.typeValue == null || this.state.typeValue == 2 ? "actualCostPerHr" : "actualCostPerKm"), e.target.value)} value={this.state.typeValue == null || this.state.typeValue == 2 || this.state.typeValue == 3 ? this.state.actualCostPerHr : this.state.actualCostPerKm} />
                                    </Column>
                                </Column>
                                <Column>
                                    <Title>Extra Cost/Kms</Title>
                                    <InputText onChange={(e) => this.handleChange("extraCostPerKm", e.target.value)} value={this.state.extraCostPerKm} />
                                    <Column>
                                        <Title>Extra Cost/Hr</Title>
                                        <InputText onChange={(e) => this.handleChange("extraCostPerHr", e.target.value)} value={this.state.extraCostPerHr} />
                                    </Column>
                                </Column>
                                <Column>
                                    <Title>Minimum Kms/day</Title>
                                    <InputText onChange={(e) => this.handleChange("minKms", e.target.value)} value={this.state.minKms} />
                                    <Column>
                                        <Title>Minimum Hrs/day (4, 8, 12, 24)</Title>
                                        <InputText onChange={(e) => this.handleChange("minHrs", e.target.value)} value={this.state.minHrs} />
                                    </Column>
                                </Column> 
                            </Row>
                        </AccordionItem>
                        <AccordionItem title={"Pricing"} isActive fixed>
                            <Row>
                                <Column>
                                    <Title>Maximum Cost</Title>
                                    <InputText onChange={(e) => this.handleChange("maxCost", e.target.value)} value={this.state.maxCost} />
                                    <Column>
                                        <Title>Maximum Kms</Title>
                                        <InputText onChange={(e) => this.handleChange("maxKms", e.target.value)} value={this.state.maxKms} />
                                    </Column>
                                </Column>
                                <Column>
                                    <Title>Maximum Hrs</Title>
                                    <InputText onChange={(e) => this.handleChange("maxHrs", e.target.value)} value={this.state.maxHrs} />
                                    <Column>
                                        <Title>Number of Seats</Title>
                                        <InputText onChange={(e) => this.handleChange("noOfSeats", e.target.value)} value={this.state.noOfSeats} />
                                    </Column>
                                </Column>
                                <Column>
                                    <Title>Driver Charges</Title>
                                    <InputText onChange={(e) => this.handleChange("driverCharges", e.target.value)} value={this.state.driverCharges} />
                                    <Column>
                                        <Title>Night Charges</Title>
                                        <InputText onChange={(e) => this.handleChange("nightCharges", e.target.value)} value={this.state.nightCharges} />
                                    </Column>
                                </Column>
                                <Column>
                                    <Title>Service Tax(%)</Title>
                                    <InputText onChange={(e) => this.handleChange("serviceTaxPercentage", e.target.value)} value={this.state.serviceTaxPercentage} />
                                    <Column>
                                        <Title>Status</Title>
                                        <div css={css`${tw`flex items-center`}`}>
                                            <RadioButton css={css`${this.state.isActive ? tw`bg-purple-500` : tw`bg-transparent`}`} onClick={this.toggleActive} />
                                            <Text css={css`${tw`ml-1`}`}>{this.state.isActive ? "Active" : "Inactive"}</Text>
                                        </div>
                                    </Column>
                                </Column>
                            </Row>
                            <Row css={css`${tw`flex justify-end py-4`}`}>
                                <Button onClick={this.handleUpdateClick}>Update Price</Button>
                            </Row>
                        </AccordionItem>

                    </>}
                </SliderModal>}
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    locations: state.user.locations,
    makers: state.user.carsData.makers,
    models: state.user.carsData.models,
    categories: state.user.carsData.categories,
    subCategories: state.user.carsData.subCategories,
    productPricing: state.user.productPricing,
    accountId: state.user.userDetails.accountId
})

export default connect(mapStateToProps)(PricingPortal)