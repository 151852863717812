import tw from "tailwind.macro"
import React from "react"
import styled from "@emotion/styled"

const Wrapper = styled.div`
${tw`fixed w-full md:w-2/3 lg:w-2/3 h-full z-50 bg-black opacity-75 top-0 left-0`}
`
export default (props) => (
    <Wrapper style={{ display:(props.active ? 'block' : 'none')}} onClick={()=>{!props.disabled && props.overlayClicked()}}>

    </Wrapper>
)