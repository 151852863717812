import tw from "tailwind.macro"
import React from "react"
import SEO from "../components/seo"
import FooterWIthoutLinks from "../components/FooterWIthoutLinks"
import Header from "../components/Header"
import BookingForm from "../components/BookingForm"
import { connect } from "react-redux"
import Menu from "../components/Menu"
import Overlay from "../components/Overlay"
import styled from "@emotion/styled"

const Wrapper = styled.div`
  ${tw`flex items-center relative justify-center w-full flex-col bg-white`}
`

class Booking extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
      showOverlay: false,
      isPartner: false
    }
  }

  componentWillMount() {
  }

  componentDidMount(){
    if(typeof window != `undefined`){
      let businessData = JSON.parse(window.localStorage.getItem('businessData'))
      if(businessData != null && businessData.hasOwnProperty("partner")){
        this.setState({ isPartner: businessData.partner})
      }
    }
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu, showOverlay: !this.state.showOverlay })
  }

  render() {
    return (
      <Wrapper>
        <SEO title="Booking" />
        {!this.state.isPartner && <Header shadow business dark openMenu={this.toggleMenu} />}
        {this.state.isPartner && <Header shadow partner dark openMenu={this.toggleMenu} />}
        <BookingForm />
        <FooterWIthoutLinks />
        {/* DO NOT MOVE OVERLAY COMPONENT */}
        <Overlay active={this.state.showOverlay} overlayClicked={()=>{this.setState({ showOverlay: false, showMenu: false})}}/>
        {this.state.showMenu && !this.state.isPartner && <Menu business openMenu={this.toggleMenu} />}
        {this.state.showMenu && this.state.isPartner && <Menu partner openMenu={this.toggleMenu} />}
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(Booking);