import tw from "tailwind.macro"
import React from "react"
import styled from "@emotion/styled"
import { connect } from "react-redux"
import { registerBusiness } from "../actions/userAction"
// import Select from "react-dropdown-select"
// import { css } from '@emotion/core'

const Wrapper = styled.div`
${tw`flex relative text-left justify-center flex-col w-full mt-5 sm:mt-5 md:mt-10 lg:mt-10 z-40 px-10 sm:px-10 md:px-10 lg:px-20 xl:px-20`}
`
const FormBorder = tw.div`
border-2 rounded-lg px-4
`
const Heading = tw.h1`
text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl font-black pt-5 pb-5
`
const SubHeading = tw.h1`
text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl font-black text-gray-700 pt-10
`
const FormContainer = tw.div`
w-full py-5
`
const FormFieldContainer = tw.div`
lg:flex justify-start items-center content-center flex-row w-full py-2 sm:py-2 md:py-4 lg:py-4
`
const InputLabel = tw.p`
text-base sm:text-base md:text-lg lg:text-lg font-medium px-0 lg:px-5 py-2 w-full lg:w-2/5
`
const InputText = tw.input`
w-full lg:w-3/5 text-base sm:text-base md:text-lg lg:text-lg font-medium bg-gray-300 px-2 lg:px-5 py-2 appearance-none outline-none shadow-none border-none
`
const InputTextArea = tw.textarea`
w-full lg:w-3/5 text-base sm:text-base md:text-lg lg:text-lg font-medium bg-gray-300 px-2 lg:px-5 py-2 appearance-none outline-none shadow-none border-none
`
// const InputDropDown = tw.div`
// w-full lg:w-3/5 text-lg sm:text-xl md:text-xl lg:text-xl xl:text-xl font-medium bg-gray-300 appearance-none outline-none shadow-none border-none
// `
const ButtonContainer = tw.div`
flex py-5 w-full justify-end
`
const SubmitButton = styled.button`
${tw`w-full sm:w-1/2 md:w-1/4 lg:w-1/4 py-5 text-white text-lg lg:text-xl font-black self-center appearance-none outline-none shadow-none border-none text-center`}
  background-color: #8a3df6;
`
// const ParallaxImg = styled.img`
//   ${tw`absolute z-0`}
//   ${'' /* opacity: 0.20; */}
//   right: -50%;
// `
const SuccessLabel = tw.p`
text-lg sm:text-xl md:text-xl lg:text-xl xl:text-xl font-medium py-2
`
const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/

class RegistrationForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            contactName: "",
            contactPhone: "",
            contactEmail: "",
            companyName: "",
            companyAddress: "",
            companyCity: "",
            companyState: "",
            companyPincode: "",
            comments: "",
            errorFields: [],
            companyPhone:"",
            companyEmail:"",
            disableSubmit: false,
            isSuccess: false,
            successMsg:""
        };
    }

    onChange = (state, value) => {
        this.setState({ [state]: value })
    }

    handleSubmit = () => {
        this.setState({ disableSubmit: true })
        let errorFields = []
        if (this.state.contactName === "") {
            errorFields.push("contactName")
        }
        if (this.state.contactPhone.length < 10) {
            errorFields.push("contactPhone")
        }
        if (!(reg.test(this.state.contactEmail))) {
            errorFields.push("contactEmail")
        }
        if (this.state.companyName === "") {
            errorFields.push("companyName")
        }
        if (this.state.companyAddress === "") {
            errorFields.push("companyAddress")
        }
        if (this.state.companyCity === "") {
            errorFields.push("companyCity")
        }
        if (this.state.companyState === "") {
            errorFields.push("companyState")
        }
        // if (this.state.companyPhone.length < 10) {
        //     errorFields.push("companyPhone")
        // }
        // if (!(reg.test(this.state.companyEmail))) {
        //     errorFields.push("companyEmail")
        // }
        if (this.state.companyPincode.length < 6) {
            errorFields.push("companyPincode")
        }
        this.setState({ errorFields })
        if (errorFields.length > 0) {
            alert("Please fill all the required fields.")
            this.setState({ disableSubmit: false })
            return
        }
        else {
            let data = {
                "contactPerson": this.state.contactName,
                "mobile": this.state.contactPhone,
                "email": this.state.contactEmail,
                "businessName": this.state.companyName,
                "address": this.state.companyAddress,
                "city": this.state.companyCity,
                "state": this.state.companyState,
                "pinCode": Number(this.state.companyPincode),
                // "supportMobile": this.state.companyPhone,
                // "supportEmail": this.state.companyEmail
            }
            this.props.dispatch(registerBusiness(data, this.success, this.error))
        }
    }

    success = (status, response) => {
        console.log(response)
        this.setState({ disableSubmit: false })
        if(status === "Request sent"){
            this.setState({ isSuccess: true, successMsg: "Thank you! Your request has been received, our support team will get back to you ASAP." })
            // alert("Thank you! Your request has been received, our support team will get back to you ASAP.")
        }
        else if(status === "Request already received"){
            this.setState({ isSuccess: true, successMsg: "Thank you! We have already received a request with this phone number. Our support team will respond ASAP." })
            // alert("Thank you! We have already received a request with this phone number. Our support team will respond ASAP.")
        }
        else if(status === "Faild to send"){
            this.setState({ isSuccess: false, successMsg: ""})
            alert("Sorry! Could not take your request at the moment.")
        }
    }

    error = (error) => {
        console.log(error)
        this.setState({ disableSubmit: false , isSuccess: false, successMsg: "" })
        alert("Sorry! Could not take your request at the moment.")
    }

    render() {
        return (
            <Wrapper id="Form">
            {!this.state.isSuccess && <FormBorder>
                <Heading>Register your company in car rental program</Heading>
                <SubHeading>Contact information</SubHeading>
                <FormContainer>
                    <FormFieldContainer>
                        <InputLabel style={{ color: (this.state.errorFields.includes("contactName") ? 'red' : 'black') }}>Full Name of Primary contact</InputLabel>
                        <InputText type="text" onChange={(e) => { this.onChange("contactName", e.target.value) }} />
                    </FormFieldContainer>
                    <FormFieldContainer>
                        <InputLabel style={{ color: (this.state.errorFields.includes("contactPhone") ? 'red' : 'black') }}>Phone number of Primary contact</InputLabel>
                        <InputText type="number" onChange={(e) => { this.onChange("contactPhone", e.target.value) }} />
                    </FormFieldContainer>
                    <FormFieldContainer>
                        <InputLabel style={{ color: (this.state.errorFields.includes("contactEmail") ? 'red' : 'black') }}>Email address of Primary contact</InputLabel>
                        <InputText type="email" onChange={(e) => { this.onChange("contactEmail", e.target.value) }} />
                    </FormFieldContainer>
                </FormContainer>
                <SubHeading>Company information</SubHeading>
                <FormContainer>
                    <FormFieldContainer>
                        <InputLabel style={{ color: (this.state.errorFields.includes("companyName") ? 'red' : 'black') }}>Company Name</InputLabel>
                        <InputText type="text" onChange={(e) => { this.onChange("companyName", e.target.value) }} />
                    </FormFieldContainer>
                    {/* <FormFieldContainer>
                        <InputLabel style={{ color: (this.state.errorFields.includes("companyPhone") ? 'red' : 'black') }}>Company Phone number</InputLabel>
                        <InputText type="number" onChange={(e) => { this.onChange("companyPhone", e.target.value) }} />
                    </FormFieldContainer>
                    <FormFieldContainer>
                        <InputLabel style={{ color: (this.state.errorFields.includes("companyEmail") ? 'red' : 'black') }}>Company Email address</InputLabel>
                        <InputText type="email" onChange={(e) => { this.onChange("companyEmail", e.target.value) }} />
                    </FormFieldContainer> */}
                    <FormFieldContainer>
                        <InputLabel style={{ color: (this.state.errorFields.includes("companyAddress") ? 'red' : 'black') }}>Company Street Address</InputLabel>
                        <InputText type="text" onChange={(e) => { this.onChange("companyAddress", e.target.value) }} />
                    </FormFieldContainer>
                    <FormFieldContainer>
                        <InputLabel style={{ color: (this.state.errorFields.includes("companyCity") ? 'red' : 'black') }}>City</InputLabel>
                        <InputText type="text" onChange={(e) => { this.onChange("companyCity", e.target.value) }} />
                        {/* <InputDropDown>
                            <Select options={this.props.locations} clearable={this.state.companyCity != "" ? true : false} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem"}} onChange={(val) => {
                                if (val.length > 0) {
                                    this.setState({ companyCity: val[0].value })
                                }
                            }} />
                        </InputDropDown> */}
                    </FormFieldContainer>
                    <FormFieldContainer>
                        <InputLabel style={{ color: (this.state.errorFields.includes("companyState") ? 'red' : 'black') }}>State</InputLabel>
                        <InputText type="text" onChange={(e) => { this.onChange("companyState", e.target.value) }} />
                    </FormFieldContainer>
                    <FormFieldContainer>
                        <InputLabel style={{ color: (this.state.errorFields.includes("companyPincode") ? 'red' : 'black') }}>Pincode</InputLabel>
                        <InputText type="number" onChange={(e) => { this.onChange("companyPincode", e.target.value) }} />
                    </FormFieldContainer>
                    <FormFieldContainer>
                        <InputLabel>Comments (optional)</InputLabel>
                        <InputTextArea rows="4" onChange={(e) => { this.onChange("comments", e.target.value) }} />
                    </FormFieldContainer>
                </FormContainer>
                <ButtonContainer>
                    <SubmitButton onClick={this.handleSubmit} disabled={this.state.disableSubmit}>Submit</SubmitButton>
                </ButtonContainer>
                {/* <ParallaxImg src={require('../svgs/grey-mountains.svg')} alt="Mountains" /> */}
                </FormBorder>}
                {this.state.isSuccess && <FormBorder style={{ justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                    <Heading style={{ color: 'green'}}>Request received!</Heading>
                    <SuccessLabel>{this.state.successMsg}</SuccessLabel>
                </FormBorder>}
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    // locations: state.app.locations
})

export default connect(mapStateToProps)(RegistrationForm);
