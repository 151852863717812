import tw from "tailwind.macro"
import React from "react"
import SEO from "../components/seo"
import { connect } from "react-redux"
import Menu from "../components/Menu"
import Overlay from "../components/Overlay"
import styled from "@emotion/styled"
import SideBar from "../components/SideBAr"
import CarsComponent from "../components/CarsComponent"
import { navigate } from 'gatsby';


const Wrapper = tw.div`
  flex items-center relative justify-center w-full flex-col bg-white
`
const Container = styled.div`
 ${tw`flex w-full h-screen`}
 z-index: 0;
`
const ContainerOne = styled.div`
 ${tw`w-full md:w-2/3 lg:w-2/3 h-screen overflow-y-scroll`}
 ${'' /* background-image: linear-gradient(-180deg, #F0EEEA 5%, #DCD8D0 79%); */}
background: #e7ebef;
`
const ContainerTwo = styled.div`
${tw`hidden md:flex lg:flex w-0 md:w-1/3 lg:w-1/3 h-screen`}
background: #222222;
`

class Cars extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
      showOverlay: false
    }
  }

  componentDidMount() {
    if(!this.props.ride.pickup.hasOwnProperty("city") || (this.props.ride.pickup.hasOwnProperty("city") && this.props.ride.pickup.city === "")){
      navigate('/booking', { state:{} })
    }
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu, showOverlay: !this.state.showOverlay })
  }

  render() {
    return (
      <Wrapper>
        <SEO title="Cars" />
        {/* <Header shadow business dark openMenu={this.toggleMenu} /> */}
        <Container>
        <ContainerOne>
        <CarsComponent />
        </ContainerOne>
        <ContainerTwo>
        <SideBar />
        </ContainerTwo>
        </Container>
        {/* <Footer /> */}
        {/* DO NOT MOVE OVERLAY COMPONENT */}
        <Overlay active={this.state.showOverlay} overlayClicked={()=>{this.setState({ showOverlay: false, showMenu: false})}}/>
        {this.state.showMenu && <Menu business openMenu={this.toggleMenu} />}
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  ride: state.ride
})

export default connect(mapStateToProps)(Cars);