import React from "react"
import { connect } from "react-redux"
import SEO from "../components/seo"
import tw from "tailwind.macro"
import styled from "@emotion/styled"
import Header from "../components/Header"
import TripsPortal from "../components/TripsPortal"
import TripsMobile from "../components/TripsMobile"
import Footer from "../components/Footer"
import Menu from "../components/Menu"
import Overlay from "../components/Overlay"
import { isMobile } from "react-device-detect"
import Loader from 'react-loader-spinner'
import { css } from '@emotion/core'
import { fetchTripsForBusiness } from "../actions/userAction"


const Wrapper = tw.div`
  flex items-center relative justify-center w-full flex-col bg-white
`
const LoaderContainer = styled.div`
  ${tw`justify-center h-full w-full bg-black left-0 fixed opacity-50`}
  top: 5.0rem;
  @media (min-width: 992px){
    top: 6.5rem;
  }
`

class BookingList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
            showOverlay: false,
            isBookingsLoading: true
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.isBookingsLoading != this.props.isBookingsLoading){
          this.setState({ isBookingsLoading: this.props.isBookingsLoading})
        }
        if(prevProps.userDetails != this.props.userDetails){
          this.props.dispatch(fetchTripsForBusiness())
        }
      }

    toggleMenu = () => {
        this.setState({ showMenu: !this.state.showMenu, showOverlay: !this.state.showOverlay })
    }

    render() {
        if (typeof window != `undefined`) {
            if (this.state.isBookingsLoading) {
              window.document.getElementsByTagName("body")[0].style.overflow = "hidden";
            }
            else {
              window.document.getElementsByTagName("body")[0].style.overflow = "auto";
            }
          }
        return (
            <Wrapper>
                <SEO title="Bookings" />
                <Header shadow business dark openMenu={this.toggleMenu} />
                {this.state.isBookingsLoading && <LoaderContainer style={{zIndex:1000}} css={css`${tw``}; display: ${this.state.isBookingsLoading ? "flex !important" : "none !important"}; `}>
                    <div css={css`${tw`self-center`}`}>
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={100}
                            width={100}
                        />
                    </div>
                </LoaderContainer>}
                <TripsPortal />
                <TripsMobile />
                {/* <Footer /> */}
                {/* DO NOT MOVE OVERLAY COMPONENT */}
                <Overlay active={this.state.showOverlay} overlayClicked={() => { this.setState({ showOverlay: false, showMenu: false }) }} />
                {this.state.showMenu && <Menu business openMenu={this.toggleMenu} />}
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    isBookingsLoading: state.user.isBookingsLoading,
    userDetails: state.user.userDetails
})

export default connect(mapStateToProps)(BookingList)