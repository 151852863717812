import tw from "tailwind.macro"
import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { css } from '@emotion/core'
import Moment from "moment"

const Wrapper = styled.div`
 ${tw`content-center w-full bg-white border border-gray-500 border-solid rounded-lg shadow-2xl cursor-pointer p-4`}
 height: 11rem;
`

class DriverCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <Wrapper>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Name: {this.props.driver.name}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Mobile Number: {this.props.driver.mobileNo}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Address: {this.props.driver.address}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Driving License: {this.props.driver.licenseNo}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Status: {this.props.driver.isActive ? "Active" : "Inactive" }</p>
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(DriverCard);
