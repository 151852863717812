import tw from "tailwind.macro"
import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { css } from '@emotion/core'
import Moment from "moment"

const Wrapper = styled.div`
 ${tw`content-center w-full bg-white border border-gray-500 border-solid rounded-lg shadow-2xl cursor-pointer p-4`}
 ${'' /* height: 14.5rem; */}
`

class PricingCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <Wrapper onClick={()=>{this.props.cardPressed(this.props.pricing)}}>
            <button css={css`${tw`w-full font-medium text-center text-base py-2 bg-purple-500 text-white`}`}>Update</button>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Category: {this.props.pricing.categoryDesc}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Sub Category: {this.props.pricing.subCategoryDesc}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Driver Charges: {this.props.pricing.driverCharges}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Night Charges: {this.props.pricing.nightCharges}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Cost/Km: {this.props.pricing.actualCostPerKm}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Cost/Hr: {this.props.pricing.actualCostPerHr}</p>
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(PricingCard);
