import React from "react"
import tw from "tailwind.macro"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const Wrapper = styled.div`
${tw`p-2 md:p-4 lg:p-4 fixed self-center shadow-2xl`}
display: inline-block;
left:0;
right:0;
top:0;
margin-left: auto;
margin-right: auto;
width: 90%;
z-index: 100;
background: #fff;
border: 1px solid black;
@media(min-width: 768px){
    width: 50%;
}
`
const Heading = styled.div`
${tw`text-base md:text-lg lg:text-lg font-medium pb-2`}
`
const ChildrenContainer = styled.div`
${tw`text-sm md:text-base lg:text-base font-normal pb-12`}
`
const ButtonContainer = styled.div`
${tw`flex md:flex lg:flex absolute self-center justify-end`}
width: 80%;
bottom: 1rem;
right: 1rem;
`
const Button = styled.div`
${tw`py-2 px-4 border-none appearance-none outline-none cursor-pointer`}
`

export default (props) => (
    <Wrapper>
    <Heading>{props.heading}</Heading>
    <ChildrenContainer>
        {props.children}
    </ChildrenContainer>
    <ButtonContainer>
    {props.buttonOneText != undefined && props.buttonOneText != "" && <Button css={css`${tw`font-base text-base`}`} onClick={props.buttonOnePressed}>{props.buttonOneText}</Button>}
    {props.buttonTwoText != undefined && props.buttonTwoText != "" && <Button css={css`${tw`ml-5 font-medium text-base`}`} onClick={props.buttonTwoPressed}>{props.buttonTwoText}</Button>}
    </ButtonContainer>
    </Wrapper>
)