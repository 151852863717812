import tw from "tailwind.macro"
import React from "react"
import styled from "@emotion/styled"
import fancyCurves from "../svgs/fancy-curves-divider-down.svg"
import BackgroundImage from "../images/vitor-pinto-BsRXRLiwcVg-unsplash.jpg"
import { css } from '@emotion/core'

const Wrapper = styled.div`
  ${tw`flex items-center relative justify-center content-center flex-col h-screen py-16 sm:py-16 md:py-16 lg:py-0 lg:h-screen px-10 sm:px-10 md:px-10 lg:px-20 xl:px-20`}
  z-index: 1 !important;
  ::after{
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        bottom: -9.9rem;
        height: 10rem;
        background-image: url(${fancyCurves});
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 0;
        @media (min-width: 992px){
          bottom: -9.9rem;
            background-size: cover;
        }
    }
`
const ParallaxBgHolder = styled.div`
    ${tw`absolute h-screen w-full`};
    ${'' /* top: -30%; */}
    right: 0px;
    left: 0px;
    z-index: -1;
    background-image: url(${BackgroundImage});
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: cover;
    @media (min-width: 992px){
        ${'' /* top: -12%; */}
        background-position: center; 
        background-size: cover;
    }

    ::after{
        content: "";
        position: relative;
        display: block;
        z-index: 1;
        ${tw`h-screen w-full`};
        top: 0px;
        left: 0px;
        background-image: linear-gradient(to bottom, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.2) 85%, rgba(0,0,0,1) 100%);
        background-blend-mode: multiply;
        -webkit-background-blend-mode: multiply;
        -moz-background-blend-mode: multiply;
        -o-background-blend-mode: multiply;
    }
`
const Heading = tw.h1`
text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-black text-white
`
const ParagraphOne = tw.p`
  text-lg sm:text-xl md:text-xl lg:text-xl xl:text-xl font-medium py-4 text-gray-400
`
const Row = tw.div`
  w-full md:flex lg:flex md:flex-row lg:flex-row
`
const ColumnOne = tw.div`
  w-full lg:w-3/5 px-0 lg:px-5
`
// const ColumnTwoLargeScreen = tw.div`
//   w-full hidden md:block lg:block lg:w-2/5
// `
// const ColumnTwoSmallScreen = tw.div`
//   w-full block md:hidden lg:hidden lg:w-2/5
// `
const ButtonDiv = tw.div`
  py-2
`
const Button = styled.div`
  ${tw`w-full lg:w-1/2 py-5 text-white text-lg lg:text-xl font-black appearance-none outline-none shadow-none border-none text-center cursor-pointer`}
  background-color: #8a3df6;
`

export default (props) => (
  <Wrapper>
    <Row>
      <ColumnOne>
        <Heading>Car rentals for businesses</Heading>
        <ParagraphOne>Zyppys Car rental programs designed specifically to meet your company's transportation needs while saving you time and money.</ParagraphOne>
        {/* <ColumnTwoSmallScreen>
        <img src={require('../images/SUV-transparent.png')} alt="Car" />
      </ColumnTwoSmallScreen> */}
        <ButtonDiv>
            <Button onClick={props.scrollToForm}>Register</Button>
        </ButtonDiv>
      </ColumnOne>
      {/* <ColumnTwoLargeScreen>
        <img src={require('../images/SUV-transparent.png')} alt="Car" />
      </ColumnTwoLargeScreen> */}
    </Row>
    <ParallaxBgHolder />
  </Wrapper>
)
