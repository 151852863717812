import Moment from 'moment';

let initialState = {
  accountId:"1",
  geoLocationRequest:false,
  mountMap:false,
  cars:[],
  selectedCar:{},
  selectedHotel:{},
  user:{},
  routePlaces : [],
  minAmountForCar:0,
  isSelfDriven:false,
  isChauffeurDriven:false,
  isCarsAvailable:false,
  isCarAdded:false,
  isHotelAdded:false,
  isRecreationAdded : false,
  isBidSubmitted:false,
  showHotelsOnMap:false,
  noOfAdults : 2,
  hotelCity : '',
  noOfChild : 0,
  extraBeds : 0,
  noOfRooms : 1,
  hotelStartDate : Moment().format("DD-MM-YYYY"),
  hotelEndDate : Moment().add(1,'d').format("DD-MM-YYYY"),
  hotelCities:[],
  hotelsLoading : false,
  defaultHotels :[],
  isHotelsAvailable : false,
  hotels:[],
  stayDetails : {},
  stays:{},
  addedHotels : [],
  showCarPopover : true,
  showRecreationsOnMap : false,
  isRecreationsAvailable : false,
  recreations : [],
  recBookingDetails : {},
  selectedRecreation:{},
  addedRecreations : {},
  recBookings : {},
  hotelsDivMapped : false,
  eatAndDrinkPlaces : [],
  showEatAndDrinkPlaces : false,
  isEatAndDrinkPlacesAvailable : false,
  attractions : [],
  isAttractionsAvailable : false,
  showAttractions : false,
  isRental : true,
  isAirport : false,
  isUserLoggedIn: false,
  imageUrl: "",
}

let newStays,newAddedHotels,newAddedRecreations,newRecBookings;

export default function appReducer(state=initialState,action){
  debugger
  switch(action.type) {
    case 'GEO_LOCATION_REQUEST':
      return {
        ...state,
        geoLocationRequest:true
      };
    case 'GEO_LOCATION_SUCCESS':
      return {
        ...state,
        geoLocationRequest:false
      };
    case 'GEO_LOCATION_FAIL':
      return {
        ...state,
        geoLocationRequest:false
      };
    case 'UPDATE_MAP_COORDS':
      return {
        ...state,
        coords:action.data
      };
    case 'MOUNT_MAP':
      return {...state,
        mountMap:true
      };
    case 'UPDATE_CARS_DATA':
      return{
        ...state,
        cars:action.data,
      }
    case 'SELF_DRIVEN_TRUE':
      return {
        ...state,
        isSelfDriven:true
      }
    case 'SELF_DRIVEN_FALSE':
      return {
        ...state,
        isSelfDriven:false
      }
    case 'SET_CHAUFFEUR_DRIVEN_TRUE':
      return {
        ...state,
        isChauffeurDriven:true
      }
    case 'SET_CHAUFFEUR_DRIVEN_FALSE':
      return {
        ...state,
        isChauffeurDriven:false
      }
    case 'FETCH_VEHICLES_SUCCESS':
      return{
        ...state,
        isCarsAvailable:true
      }
    case 'FETCH_VEHICLES_FAIL':
      return{
        ...state,
        isCarsAvailable:false
      }
    case 'UPDATE_SELECTED_CAR':
      return{
        ...state,
        selectedCar:action.data
      }
    case 'UPDATE_ROUTE_PLACES' :
      return {
        ...state,
        routePlaces : action.data
      }
    case 'CAR_ADDED':
      return {
        ...state,
        isCarAdded : true
      }
    case 'CAR_POPOVER_TRUE':
      return {
        ...state,
        showCarPopover : true
      }
    case 'CAR_POPOVER_FALSE':
      return {
        ...state,
        showCarPopover : false
      }
    case 'CAR_REMOVED':
      return {
        ...state,
        isCarAdded : false
      }
    case 'EAT_AND_DRINK_PLACES_AVAILABLE':
      return {
        ...state,
        isEatAndDrinkPlacesAvailable : true
      }
    case 'EAT_AND_DRINK_PLACES_NOT_AVAILABLE':
      return {
        ...state,
        isEatAndDrinkPlacesAvailable : false
      }
    case 'UPDATE_EAT_AND_DRINK':
      return {
        ...state,
        eatAndDrinkPlaces : action.data
      }
    case 'ATTRACTIONS_AVAILABLE':
      return {
        ...state,
        isAttractionsAvailable : true
      }
    case 'ATTRACTIONS_NOT_AVAILABLE':
      return {
        ...state,
        isAttractionsAvailable : false
      }
    case 'UPDATE_ATTRACTIONS':
      return {
        ...state,
        attractions : action.data
      }
    case 'SHOW_ATTRACTIONS':
      return {
        ...state,
        showAttractions : true
      }
    case 'REMOVE_ATTRACTIONS':
      return {
        ...state,
        showAttractions : false
      }
    case 'SHOW_EAT_AND_DRINK':
      return {
        ...state,
        showEatAndDrinkPlaces : true
      }
    case 'REMOVE_EAT_AND_DRINK':
      return {
        ...state,
        showEatAndDrinkPlaces : false
      }
    case 'UPDATE_SELECTED_HOTEL_CITY':
      return {
        ...state,
        hotelCity : action.data
      }
    case 'UPDATE_NOOF_ADULTS' :
      return {
        ...state,
        noOfAdults : action.data
      }
    case 'UPDATE_NOOF_CHILD' :
      return {
        ...state,
        noOfChild : action.data
      }
    case 'UPDATE_EXTRA_BEDS' :
      return {
        ...state,
        extraBeds : action.data
      }
    case 'UPDATE_NOOF_ROOMS' :
      return {
        ...state,
        noOfRooms : action.data
      }
    case 'UPDATE_HOTEL_STARTDATE' :
      return {
        ...state,
        hotelStartDate : action.data
      }
    case 'UPDATE_HOTEL_ENDDATE' :
      return {
        ...state,
        hotelEndDate : action.data
      }
    case 'SHOW_HOTELS':
      return {
        ...state,
        showHotelsOnMap : true
      }
    case 'REMOVE_HOTELS':
      return {
        ...state,
        showHotelsOnMap : false
      }
    case 'HOTELS_LOADING_TRUE':
      return {
        ...state,
        hotelsLoading : true
      }
    case 'HOTELS_LOADING_FALSE':
      return {
        ...state,
        hotelsLoading : false
      }
    case 'UPDATE_HOTELS' :
      return {
        ...state,
        hotels : action.data
      }
    case 'HOTELS_AVAILABLE' :
      return {
        ...state,
        isHotelsAvailable : true
      }
    case 'HOTELS_NOT_AVAILABLE' :
      return {
        ...state,
        isHotelsAvailable : false
      }
    case 'UPDATE_DEFAULT_HOTELS' :
      return {
        ...state,
        defaultHotels : action.data
      }
    case 'HOTELS_DIV_MAPPED':
      return {
        ...state,
        hotelsDivMapped : true
      }
    case 'UPDATE_SELECTED_HOTEL':
      return{
        ...state,
        selectedHotel : action.data
      }
    case 'HOTEL_ADDED':
      return {
        ...state,
        isHotelAdded: true
      }
    case 'HOTEL_REMOVED':
      return {
        ...state,
        isHotelAdded: false
      }
    case 'UPDATE_STAY_DETAILS':
      return {
        ...state,
        stayDetails: action.data
      }
    case 'ADD_STAY' :
      newStays = {...state.stays};
      newStays[action.data.hotelId] = action.data;
      return {
         ...state,
         stays: newStays
       };
     case 'REMOVE_STAY' :
       newStays = {...state.stays};
       delete newStays[action.hotelId];
       return {
          ...state,
          stays : newStays
        };
    case 'ADD_HOTEL' :
      newAddedHotels = {...state.addedHotels};
      newAddedHotels[action.data.hotelId] = action.data;
      return {
         ...state,
         addedHotels : newAddedHotels
       };
   case 'REMOVE_HOTEL' :
     newAddedHotels = {...state.addedHotels};
     delete newAddedHotels[action.hotelId];
     return {
        ...state,
        addedHotels : newAddedHotels
      };
    case 'SHOW_RECREATIONS':
      return {
        ...state,
        showRecreationsOnMap : true
      }
    case 'REMOVE_RECREATIONS':
      return {
        ...state,
        showRecreationsOnMap : false
      }
    case 'RECREATIONS_LOADING_TRUE':
      return {
        ...state,
        recreationsLoading : true
      }
    case 'RECREATIONS_LOADING_FALSE':
      return {
        ...state,
        recreationsLoading : false
      }
    case 'UPDATE_RECREATIONS' :
      return {
        ...state,
        recreations : action.data
      };
    case 'RECREATIONS_AVAILABLE' :
      return {
        ...state,
        isRecreationsAvailable : true
      };
    case 'RECREATIONS_NOT_AVAILABALE' :
      return {
        ...state,
        isRecreationsAvailable : false
      };
    case 'UPDATE_REC_BOOKING_DETAILS':
      return {
        ...state,
        recBookingDetails : action.data
      }
    case 'UPDATE_SELECTED_REACTION':
      return {
        ...state,
        selectedRecreation : action.data
      }
    case 'RECREATION_ADDED':
      return {
        ...state,
        isRecreationAdded: true
      }
    case 'RECREATION_REMOVED':
      return {
        ...state,
        isRecreationAdded: false
      }
    case 'ADD_RECREATION' :
      newAddedRecreations = {...state.addedRecreations};
      newAddedRecreations[action.data.recId] = action.data;
      return {
         ...state,
         addedRecreations : newAddedRecreations
       };
    case 'REMOVE_RECREATION':
      newAddedRecreations = {...state.addedRecreations};
      delete newAddedRecreations[action.recId];
      return {
        ...state,
        addedRecreations : newAddedRecreations
      };
    case 'ADD_REC_BOOKING' :
      newRecBookings = {...state.recBookings};
      newRecBookings[action.data.recreation.recId] = action.data;
      return {
         ...state,
         recBookings: newRecBookings
       };
     case 'REMOVE_REC_BOOKING' :
       newRecBookings = {...state.recBookings};
       delete newRecBookings[action.recId];
       return {
          ...state,
          recBookings : newRecBookings
        };
    case 'UPDATE_USER':
      return{
        ...state,
        user:action.data
      }
    case 'UPDATE_MIN_AMT_FOR_CAR':
      return{
        ...state,
        minAmountForCar:action.data
      }
    case 'BID_SUBMITTED':
      return {
        ...state,
        isBidSubmitted:true
      }
    case 'UPDATE_BID_ID':
      return {
        ...state,
        bidId: action.data.bidId,
        accountSeqId: action.data.accountSeqId
      }
      case 'HOURLY_RENTAL_TRUE':
        return {
          ...state,
          isRental:true
        }
      case 'HOURLY_RENTAL_FALSE':
        return {
          ...state,
          isRental:false
        }
      case 'AIRPORT_TRANSFER_TRUE':
          return {
            ...state,
            isAirport:true
          }
      case 'AIRPORT_TRANSFER_FALSE':
          return {
            ...state,
            isAirport:false
          }
      case 'USER_LOGIN':
          return {
            ...state,
            isUserLoggedIn:action.data
          }  
      case 'ADD_FIRST_HOTEL_CITY':
      if(state.hotelCities.length === 0){
        return {
          ...state,
          hotelCities:[
            {
              seqId:1,
              name:action.data.name,
              latitude:action.data.latitude,
              longitude:action.data.longitude
            }
          ]
        }
      }
      else{
        return {
         ...state,
         hotelCities: state.hotelCities.map(
             (hotelCity, i) => i === 0 ? {seqId:1,name:action.data.name,latitude:action.data.latitude,longitude:action.data.longitude}
                                     : hotelCity
         )
      }
      }

      case 'ADD_HOTEL_CITY':
      if(state.measureLookupId === '100010001') {
        //if roundTrip true
        debugger
        return{
          ...state,
          hotelCities:[
            ...state.hotelCities.slice(0,state.hotelCities.length-1),
            {
              seqId:state.hotelCities.length,
              name:action.data.name,
              latitude:action.data.latitude,
              longitude:action.data.longitude
            },
            ...state.hotelCities.slice(state.hotelCities.length-1).map((hotelCity) => ({seqId:hotelCity.seqId+1,name:hotelCity.name,latitude:hotelCity.latitude,longitude:hotelCity.longitude}))
          ]
        }

      }
      return {
        ...state,
        hotelCities:[
          ...state.hotelCities,
          {
            seqId:state.hotelCities.length + 1,
            name:action.data.name,
            latitude:action.data.latitude,
            longitude:action.data.longitude
          }
        ]
      }


      case 'EDIT_HOTEL_CITY':
        return {
          ...state,
          hotelCities:state.hotelCities.map(function(hotelCity){
            debugger
            if(hotelCity.seqId === action.id){
              return {seqId:hotelCity.seqId,name:action.data.name,latitude:action.data.latitude,longitude:action.data.longitude};
            }
            else{
              return hotelCity;
            }
          }
          )
        }

      case 'REMOVE_HOTEL_CITY':
        return{
          ...state,
          hotelCities:[
            ...state.hotelCities.slice(0, action.id-1),
            ...state.hotelCities.slice(action.id).map((hotelCity) => ({seqId:hotelCity.seqId-1,name:hotelCity.name,latitude:hotelCity.latitude,longitude:hotelCity.longitude}))
          ]
        }

      case 'REMOVE_LAST_HOTEL_CITY':
        return {
          ...state,
          hotelCities:[
            ...state.hotelCities.slice(0,state.hotelCities.length-1)
          ]
        }
        case 'ACCOUNT_ID':
          return {
            ...state,
            accountId:action.data
          }
          case 'UPDATE_IMAGE_URL':
          return {
            ...state,
            imageUrl:action.data
          }
    default:
      return state;
  }

}
