import tw from "tailwind.macro"
import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { css } from '@emotion/core'
import Moment from "moment"

const Wrapper = styled.div`
 ${tw`content-center w-full bg-white border border-gray-500 border-solid rounded-lg shadow-2xl cursor-pointer p-4`}
 ${'' /* height: 14.5rem; */}
`

class VehicleCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <Wrapper>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Model: {this.props.car.model_description}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Manufacture Year: {this.props.car.make_year}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Registration Number: {this.props.car.reg_no}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Category: {this.props.car.category_description}</p>
            <p css={css`${tw`font-normal text-sm py-1`}`}>Sub Category: {this.props.car.sub_category_description}</p>
            {this.props.car.hasOwnProperty("location_name") && <p css={css`${tw`font-normal text-sm py-1`}`}>City: {this.props.car.location_name}</p>}
            {/* <p css={css`${tw`font-normal text-sm py-1`}`}>Gear System: {this.props.car.gear_system}</p> */}
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(VehicleCard);
