import { urls } from "../api/urls"
import axios from "axios"

export function updateRideDetails(data) {
    return {
        type: 'UPDATE_RIDE_DETAILS',
        data
    }
}

export function updateDriverDetails(data) {
    return {
        type: 'UPDATE_DRIVER_DETAILS',
        data
    }
}

export function updateVehicleDetails(data) {
    return {
        type: 'UPDATE_VEHICLE_DETAILS',
        data
    }
}

export function updateTripLoading(data) {
    return {
        type: 'UPDATE_TRIP_LOADING',
        data
    }
}

export function updateTripAvailable(data) {
    return {
        type: 'UPDATE_TRIP_AVAILABLE',
        data
    }
}


export function fetchBidDetailsBusiness(bidId) {
    return function (dispatch, getState) {
        dispatch(updateTripLoading(true))
        let url = urls.fetchBidDetailsBusiness
        let data = {
            "bidId": Number(bidId)
        }
        console.log("Input Data For Fetch Bid Details Business", data);
        let ride = {}
        axios.post(url, data)
            .then(response => {
                console.log("Fetch Bid Details Business Response Data", response)
                if (response.data.statusCD === 200 && response.data.responseData.length > 0) {
                    ride = response.data.responseData[0];
                    dispatch(checkAssignedVehicleForBusiness(ride.id))
                    dispatch(checkAssignedDriverForBusiness(ride.id))
                }
                else {
                }
                dispatch(updateRideDetails(ride))
                dispatch(updateTripLoading(false))
            })
            .catch(err => {
                console.log("Fetch Bid Details Business Error --->", err)
                dispatch(updateTripLoading(false))
            })
    }
}

export function checkAssignedVehicleForBusiness(id) {
    return function (dispatch, getState) {
        dispatch(updateVehicleDetails({}))
        let url = urls.checkAssignedVehicleForBusiness
        console.log("Input Data For Check Assigned Vehicle For Business", id);
        let vehicle = {}
        axios.post(url, Number(id),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                console.log("Check Assigned Vehicle For Business Response Data", response)
                if (response.data.statusCD === 200) {
                    vehicle = response.data.responseData
                }
                dispatch(updateVehicleDetails(vehicle))
            })
            .catch(err => {
                console.log("Check Assigned Vehicle For Business Business Error --->", err)
            })
    }
}

export function checkAssignedDriverForBusiness(id) {
    return function (dispatch, getState) {
        dispatch(updateDriverDetails({}))
        let url = urls.checkAssignedDriverForBusiness
        console.log("Input Data For Check Assigned Driver For Business", id);
        let driver = {}
        axios.post(url, Number(id),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                console.log("Check Assigned Driver For Business Response Data", response)
                if (response.data.statusCD === 200) {
                    driver = response.data.responseData
                }
                dispatch(updateDriverDetails(driver))
            })
            .catch(err => {
                console.log("Check Assigned Driver For Business Business Error --->", err)
            })
    }
}



