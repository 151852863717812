import React from "react"
import { connect } from "react-redux"
import tw from "tailwind.macro"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import SliderModal from "./SliderModal"
import Overlay from "../components/Overlay"
import Moment from "moment"
import TripCard from "../components/TripCard"
import { urls } from "../api/urls"
import axios from "axios"
import { fetchTripsForBusiness } from "../actions/userAction"

const Wrapper = styled.div`
  ${tw`flex md:hidden lg:hidden relative w-screen h-screen flex-col p-8`}
  ${'' /* background: #e7ebef; */}
`
const SearchImage = styled.img`
${tw`absolute h-5 opacity-50`}
right: 1rem;
top: 1rem;
`
const Button = styled.button`
  ${tw`py-1 px-2 text-center text-base font-normal text-white border border-solid border-purple-500 text-purple-500 hover:bg-purple-500 hover:text-white`}
  ${'' /* background-color: #8a3df6; */}
`

class TripsMobile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: 'upcoming',
            selectedRide: {},
            showDetails: false,
            showOverlay: false,
            bookings: [],
            isBookingsLoading: true,
            isBookingsAvailable: false,
            isisCancelled: false
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.bookings != this.props.bookings){
            this.setState({ bookings: this.props.bookings})
        }
        if(prevProps.isBookingsAvailable != this.props.isBookingsAvailable){
            this.setState({ isBookingsAvailable: this.props.isBookingsAvailable })
        }
        if(prevProps.isBookingsLoading != this.props.isBookingsLoading){
            this.setState({ isBookingsLoading: this.props.isBookingsLoading })
        }
    }

    filterCaseInsensitive = (filter, row) => {
        console.log(filter);
        const id = filter.pivotId || filter.id;
        let value = filter.value;
        console.log(value);
        if (typeof row[id] == 'string') {
            console.log(id);
            if (row[id] !== undefined) {
                return String(row[id].toLowerCase()).includes(value.toLowerCase());
            }
            else {
                return true;
            }
        }
        else {

            if (row[id] !== undefined) {
                return String(row[id]).includes(value.toString());
            }
            else {
                return true
            }
        }
    }

    handleCardClicked = (ride) => {
        window.document.getElementsByTagName("body")[0].style.overflow = "hidden"
        this.setState({ selectedRide: ride, showDetails: true, showOverlay: true })
    }

    handleClose = () => {
        window.document.getElementsByTagName("body")[0].style.overflow = "auto"
        this.setState({ showOverlay: false, showDetails: false })
        this.props.dispatch(fetchTripsForBusiness());
    }
    
    handleSearch = (value) => {
        console.log(value);
        if(isNaN(value)){
            window.document.getElementById("search").style.border = "thin solid #FF0000"
        }
        else{
            window.document.getElementById("search").style.border = "thin solid #00FF00"
            this.setState({ bookings: this.props.bookings.filter( b => b.liveApiBookingId.toString().includes(value))})
        }
    }

    handleCancelRide = () => {
        //eslint-disable-next-line
        if (confirm("Are you sure you want to cancel this ride?")) {
            let url = urls.cancelUrl;
            let input = {
                "bidId": this.state.selectedRide.liveApiBookingId,
                "cancelReason": ""
            }
            console.log("Input Data for Cancel Ride", input);
            let self = this
            axios.post(url, input)
                .then(response => {
                    console.log("Response from Cancel Ride", response)
                    if (response.data.statusCD == 200 && response.data.statusMSG == "Cancellation Successful") {
                        console.log("Cancel Ride Successful", response.data.responseData)
                        alert("Ride successfully cancelled")
                        this.setState({ isCancelled: true })
                    }
                    else {
                        console.log("Cancel Ride failed");
                        alert("Unable to cancel ride at this moment")
                    }
                })
                .catch(err => {
                    console.log("Cancel Ride failed", err);
                    alert("Unable to cancel ride at this moment")
                })
        }
    }

    render() {
        return (
            <Wrapper>
                <h1 css={css`${tw`font-bold text-black text-lg py-2`}`}>Bookings</h1>
                {this.state.isBookingsAvailable && !this.state.isBookingsLoading && <div css={css`${tw`flex py-2`}`}>
                    <p css={css`${tw`font-normal text-base cursor-pointer`} + ${this.state.status == "upcoming" ? tw` text-purple-500 underline` : tw` text-black`}`} onClick={() => {this.setState({ status: 'upcoming' })}}>Upcoming</p>
                    <p css={css`${tw`ml-4 font-normal text-base cursor-pointer`} + ${this.state.status == "past" ? tw` text-purple-500 underline` : tw` text-black`}`} onClick={() => {this.setState({ status: 'past' })}}>Past</p>
                </div>}
                {/* <div css={css`${tw`flex w-full py-2 relative`}`}>
                <input type="text" placeholder="#booking id" id="search" onChange={(e)=>this.handleSearch(e.target.value)} css={css`${tw`flex w-full p-2 rounded-full bg-white border-gray-500 border appearance-none outline-none shadow-2xl`}`} />
                <SearchImage src={require("../images/search.png")} />
                </div> */}
                {!this.state.isBookingsAvailable && !this.state.isBookingsLoading && <p css={css`${tw`font-medium text-center self-center text-black text-lg py-4`}`}>No bookings available</p>}
                {this.state.isBookingsAvailable && !this.state.isBookingsLoading && this.state.bookings.filter( r => (this.state.status == 'past' ? r.hasOwnProperty('rideStatusDesc') && r.rideStatusDesc != '' : !r.hasOwnProperty('rideStatusDesc'))).map(trip => (<div css={css`${tw`py-8`}`}>
                    <TripCard trip={trip} cardPressed={(ride)=>{this.handleCardClicked(ride)}} />
                    </div>
                ))}
                {/* DO NOT MOVE OVERLAY COMPONENT */}
                <Overlay active={this.state.showOverlay} overlayClicked={this.handleClose} />
                {this.state.showDetails && <SliderModal active={this.state.showDetails} heading={"#" + this.state.selectedRide.liveApiBookingId} close={this.handleClose}>
                {!this.state.selectedRide.hasOwnProperty("rideStatusDesc") && <Button css={css`${tw``}`} onClick={this.handleCancelRide}>Cancel Trip</Button>}
                    <div css={css`${tw`flex flex-wrap w-full items-start`}`}>
                        <div css={css`${tw`lg:w-1/2 md:w-1/2 w-full mt-2`}`}>
                            <h4 css={css`${tw`mt-4 text-lg font-bold`}`}>Ride Details</h4>
                            <ul css={css`${tw`leading-loose text-black mt-4`}`}>
                                <li css={css`${tw`font-medium text-base`}`}>Ride Status : {this.state.status == 'past' ? (!this.state.isCancelled ? this.state.selectedRide.rideStatusDesc : "Cancelled") : (!this.state.isCancelled ? 'Upcoming' : "Cancelled")}</li>
                                <li css={css`${tw`text-base opacity-75`}`}>Trip Type: {this.state.selectedRide.tripType} </li>
                                <li css={css`${tw`text-base opacity-75`}`}>From: {this.state.selectedRide.fromCity}</li>
                                <li css={css`${tw`text-base opacity-75`}`}>To: {this.state.selectedRide.toCity} </li>
                                <li css={css`${tw`text-base opacity-75`}`}>Start Date: {Moment(this.state.selectedRide.rideStartDate, "YYYY-MM-DD HH:mm:ss").format("DD MMM, YYYY hh:mm A")} </li>
                                <li css={css`${tw`text-base opacity-75`}`}>End Date: {Moment(this.state.selectedRide.rideEndDate, "YYYY-MM-DD HH:mm:ss").format("DD MMM, YYYY hh:mm A")}</li>
                                <li css={css`${tw`text-base opacity-75`}`}>Vehicle: {this.state.selectedRide.carType} </li>
                            </ul>
                        </div>
                        <div css={css`${tw`lg:w-1/2 md:w-1/2 w-full mt-2`}`}>
                            <h4 css={css`${tw`mt-4 text-lg font-bold`}`}>Traveller Details </h4>
                            <ul css={css`${tw`leading-loose text-black mt-4`}`}>
                                <li css={css`${tw`font-medium text-base`}`}>Name: {this.state.selectedRide.travelerName}</li>
                                <li css={css`${tw`font-medium text-base`}`}>Phone: <a href={"tel:+91" + this.state.selectedRide.travelerContactNo}>{this.state.selectedRide.travelerContactNo}</a></li>
                                <li css={css`${tw`font-medium text-base`}`}>Email: <a href={"mailto:" + this.state.selectedRide.travelerEmailId}>{this.state.selectedRide.travelerEmailId}</a></li>
                            </ul>
                        </div>
                        {this.state.selectedRide.hasOwnProperty("totalBillAmount") && <div css={css`${tw`lg:w-1/2 md:w-1/2 w-full mt-2`}`}>
                            <h4 css={css`${tw`mt-4 text-lg font-bold`}`}>Price BreakDown </h4>
                            <ul css={css`${tw`leading-loose text-black mt-4`}`}>
                                <li css={css`${tw`font-medium text-base`}`}>Total Amount: {"₹" + Math.round(this.state.selectedRide.totalBillAmount).toLocaleString()}</li>
                                <li css={css`${tw`font-normal text-base`}`}>Ride Amount: {this.state.selectedRide.hasOwnProperty("rideAmount") && "₹" + Math.round(this.state.selectedRide.rideAmount).toLocaleString()}</li>
                                {this.state.selectedRide.hasOwnProperty("driverCharges") && <li css={css`${tw`font-normal text-base`}`}>Driver Charges: {"₹" + Math.round(this.state.selectedRide.driverCharges).toLocaleString()}</li>}
                                {this.state.selectedRide.hasOwnProperty("nightCharges") && <li css={css`${tw`font-normal text-base`}`}>Night Charges: {"₹" + Math.round(this.state.selectedRide.nightCharges).toLocaleString()}</li>}
                                {this.state.selectedRide.hasOwnProperty("serviceTax") && <li css={css`${tw`font-normal text-base`}`}>GST: {"₹" + Math.round(this.state.selectedRide.serviceTax).toLocaleString()}</li>}
                            </ul>
                        </div>}
                    </div>
                </SliderModal>}
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    bookings: state.user.bookings,
    isBookingsAvailable: state.user.isBookingsAvailable,
    isBookingsLoading: state.user.isBookingsLoading
})

export default connect(mapStateToProps)(TripsMobile)