import tw from "tailwind.macro"
import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { css } from '@emotion/core'
import SubHeader from "./SubHeader"
import CarsListOverlay from "../components/CarsListOverlay"
import { navigate } from "gatsby"
import {updateUser,updateMinAmountForCar,bidSubmitted,updateBidId,userLogIn} from '../actions/appActions';
import { urls } from "../api/urls"
import axios from 'axios';

const Wrapper = tw.div`
 flex flex-col p-4 h-screen w-full
`
const DetailsPopup = styled.div`
${tw`fixed mx-auto z-50 overflow-hidden p-8 pb-12`}
background: white;
border-radius: 2rem 2rem 2rem 2rem;
bottom: 2%;
@media(min-width: 768px){
            min-width: 40%;
            max-width: 40%;
            left: 12%;
            
        }
        width: 100%;
        left:0%;

`
const HeaderWrapper = tw.div`
flex cursor-pointer
`
const Text = tw.p`
   text-gray-600 font-medium text-base md:text-lg lg:text-lg
`
const FormWrapper = tw.div`
w-full
`
const FieldContainer = tw.div`
w-full py-4 px-4 
`
const Label = tw.label`
block text-sm font-normal text-gray-700
`
const Input = tw.input`
outline-none block text-black font-medium text-xl w-full h-10
`
const OTPInput = styled.input`
${tw`outline-none block border pl-2 border-gray-400 text-xl w-full h-12 text-center text-2xl appearance-none`}
letter-spacing: 1.5em;
`
const RadioButton = styled.span`
${tw`h-8 w-8 rounded-full border-2 border-purple-500`}
min-width: 2rem;
min-height: 2rem;
`
const RoundTripLabel = styled.div`
${tw`inline-block w-full items-center px-2 py-2 text-sm font-normal text-gray-700`}
`

const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/

class CustomerInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            phone: '',
            email: '',
            couponCode: '',
            referredBy: '',
            showOtp: false,
            otp: '',
            isOtpAuthenticated: false,
            action: 'proceed',
            bidId: '',
            isOrderConfirmed: false,
            terms: false,
            countryCode: "+91",
            isSubmitting: false
        }
    }

    handleChange = (state, value) => {
        this.setState({ [state]: value })
        if(state === "phone" && this.state.showOtp){
            this.setState({ showOtp: false, otp: "" })
        }
    }

    onButtonClick = () => {
        if(this.state.otp == ''){
          if(this.state.name == ""){
            alert("Please Enter the name");
            return;
          }
          else if(this.state.phone.length != 10){
            alert("Please Enter a valid phone number");
            return;
          }
          else if(!(reg.test(this.state.email))){
            alert("Please Enter a valid Email");
            return;
          }
          // else if(this.state.terms == false){
          //   alert("Please agree to Terms");
          //   return;
          // }
          this.props.dispatch(updateUser({name:this.state.name,email:this.state.email.toLowerCase(),phone:this.state.phone,couponCode:this.state.couponCode,referredBy:this.state.referredBy}));
          this.props.dispatch(userLogIn(true));
          // this.setState({showOtp : true});
          // this.sendOtp();
          if(!this.state.isSubmitting){
          this.setState({ isSubmitting: true })
          this.submitBid();
          }
        }
        // else if(this.state.otp != "" && !this.state.isOtpAuthenticated) {
        //   this.authenticateOtp();
        // }
        // else if(this.state.isOrderConfirmed == true) {
        //   // window.location.href = 'https://www.instamojo.com/@zyppys?purpose='+this.state.bidId+'&amount='+this.state.minAmount+'+&data_name='+this.state.name+'&data_email='+this.state.email+'&data_phone='+this.state.phone+'';
        // }
      }

      sendOtp = () => {
        debugger
        let url = urls.sendOtpUrl;
        let otpInput = {
          "user":{
              "name":this.state.name,
              "emailId":this.state.email,
              "mobileNo":Number(this.state.phone),
              "countryCode": Number(this.state.countryCode)
          },
          "loginType": "CUSTOMER"
        }
        axios.post(url,otpInput)
          .then(function(response){
          });
      }

      authenticateOtp = () => {
        let self = this;
        let url = urls.authOtpUrl;
        let authinput = {
          "otp" : this.state.otp,
          "loginType":"CUSTOMER"
        }
    
        axios.post(url,authinput)
          .then(function(response){
            if(response.data.statusCD == 200){
              self.setState({isOtpAuthenticated : true},function() {
                self.props.dispatch(userLogIn(true));
                self.submitBid();
              });
              console.log(response);
            }
            else if(response.data.statusCD == 420){
              alert("Invalid OTP");
            }
          })
          .catch(function(error) {
            console.log(error);
            alert("Oops! Looks like there's a network error. Please try again.");
          })
      }

      submitBid(){
        debugger
        let bidId;
        let self = this;
        let newStops = [];  
        let url = urls.submitUrl;
        let submitData;
        let ride = this.props.ride;
        let app = this.props.app;
        let selectedCar = this.props.selectedCar;
        let stops = this.props.ride.stops;

          submitData = {
             "bid": {
               "bidAmount": selectedCar.base_fare
             },
             "pricingRequestBean": {
               "pickupLocation": {
                 "latitude": ride.pickup.cityLat,
                 "longitude": ride.pickup.cityLng
               },
               "destinationLocation": {
                 "latitude": ride.dest.cityLat,
                 "longitude": ride.dest.cityLng
               },
               "stops": this.props.ride.stops,
               "user":{
                  "name":this.state.name,
                  "mobileNo":this.state.phone,
                  "emailId":this.state.email
               },           
               "ride": {
                "isPortal": true,
                 "pickupCoordinates": ride.pickup.locLat+","+ride.pickup.locLng,
                 "pickupLocality": ride.pickup.loc,
                 "destinationCoordinates": ride.dest.locLat+","+ride.dest.locLng,
                 "destinationLocality": ride.dest.loc,
                 "rideStartDate": ride.chauffeurStartDate +" "+ ride.startTime,
                 "rideEndDate": ride.chauffeurEndDate +" "+ ride.endTime,
                 "distance": ride.rideDistance,
                 "categoryId": selectedCar.category_id,
                 "subCategoryId": selectedCar.sub_category_id,
                 "accountId":selectedCar.account_id,
                 "travelerName": this.state.name,
                 "travelerContactNo": this.state.phone,
                 "travelerEmailId": this.state.email,
               },
             }
           }
        if(this.props.isChauffeurDriven && !this.props.isAirport && !this.props.isRental && ride.measureLookupId == '100010011' && selectedCar.category != "SELF DRIVE"){
            submitData.pricingRequestBean.ride.measureLookupId = ride.measureLookupId;
            submitData.pricingRequestBean.ride.rideEndDate = ride.chauffeurEndDate + ' 23:59:00'
        }
        else if (this.props.isChauffeurDriven && !this.props.isAirport && !this.props.isRental) {
            submitData.pricingRequestBean.ride.distance = Number(selectedCar.package_kms);
            submitData.pricingRequestBean.ride.measureLookupId = ride.measureLookupId;
        }
        else if(this.props.isAirport){
            let isAirportPickup = ride.pickup.loc.includes("Airport") || ride.pickup.loc.includes("airport") ? true : false;
            let isAirportDrop = ride.dest.loc.includes("Airport") || ride.dest.loc.includes("airport") ? true : false;
            submitData.pricingRequestBean.ride.measureLookupId = isAirportPickup ? 100010014 : (isAirportDrop ? 100010015 :1111188111);
            submitData.pricingRequestBean.destinationLocation.latitude= isAirportDrop ? ride.dest.locLat  :ride.dest.cityLat;
            submitData.pricingRequestBean.destinationLocation.longitude= isAirportDrop ? ride.dest.locLng  :ride.dest.cityLng;
            submitData.pricingRequestBean.ride.distance = ride.measureLookupId == "100010001" ? parseInt(ride.rideDistance / 2) : ride.rideDistance;     
          }
        else if(this.props.isRental){
            let measureLookup;
            submitData.pricingRequestBean.pickupLocation.latitude=ride.pickup.locLat;
            submitData.pricingRequestBean.pickupLocation.longitude=ride.pickup.locLng;
            submitData.pricingRequestBean.destinationLocation.latitude=ride.dest.locLat;
            submitData.pricingRequestBean.destinationLocation.longitude=ride.dest.locLng;
            delete submitData.pricingRequestBean.ride.distance; 
            if(ride.rentalType === 4){
                measureLookup = 100010009
                // 2 hrs
            }
            else if(ride.rentalType === 5){
                measureLookup = 100010006
                // 4 hrs
            }
            else if(ride.rentalType === 6){
                measureLookup = 100010007
                // 8hours
            }
            else{
                measureLookup = null
            }
            submitData.pricingRequestBean.ride.measureLookupId = measureLookup;
        }
        else {
          submitData.pricingRequestBean.ride.measureLookupId = selectedCar.measure_lookup_id;
      }
        console.log(submitData);
        console.log(JSON.stringify(submitData));
         axios.post(url,submitData)
          .then(response => {
            self.setState({ isSubmitting: false})
            if(response.data.statusCD == 200){
              debugger
              console.log(response);
              self.props.dispatch(bidSubmitted());
              let minAmount = parseInt(response.data.responseData.bid.bidAmount * .2);
              self.props.dispatch(updateMinAmountForCar(minAmount));
              self.props.dispatch(updateBidId({bidId:response.data.responseData.bid.id, accountSeqId: response.data.responseData.pricingRequestBean.ride.accountSeqId}));
              self.setState({isOrderConfirmed : true,bidId:response.data.responseData.bid.id,minAmount});
              navigate("/confirmed", { state: {} })
            }
            else{
                console.log(response)
                alert("Couldn't place order at the moment")
            }
          })
          .catch(error => {
            self.setState({ isSubmitting: false})
            alert("Couldn't place order at the moment")
               console.log(error)
            })
      }
    
    

    render() {
        return (
            <Wrapper>
                <SubHeader />
                {/* DO NOT MOVE OVERLAY COMPONENT */}
                <CarsListOverlay active={true} disabled />
                <DetailsPopup>
                    <HeaderWrapper onClick={() => { navigate("/cars", { state: {selectedVendor: this.props.selectedCar} }) }}>
                        <img css={css`${tw`mr-4 mb-1`}`} src={require("../svgs/angle-back.svg")} alt="back-arrow" />
                        <Text>Traveller Details</Text>
                    </HeaderWrapper>
                    <FormWrapper>
                        <FieldContainer>
                            <Label>Full Name</Label>
                            <Input type="text" placeholder="Firstname Lastname" onChange={(e) => { this.handleChange("name", e.target.value) }} />
                        </FieldContainer>
                        <FieldContainer>
                            <Label>Phone</Label>
                            <Input type="number" placeholder="9999999999" onChange={(e) => { this.handleChange("phone", e.target.value) }} />
                        </FieldContainer>
                        <FieldContainer>
                            <Label>Email</Label>
                            <Input type="email" placeholder="john@email.com" onChange={(e) => { this.handleChange("email", e.target.value) }} />
                        </FieldContainer>
                        {/* {this.state.showOtp && <FieldContainer>
                            <Label>OTP</Label>
                            <OTPInput type="number" max="9999" placeholder="0000" onChange={(e) => { this.handleChange("otp", e.target.value) }} />
                            <p css={css`${tw`mt-4 text-sm cursor-pointer`}`} onClick={() => this.sendOtp()}>Resend OTP?</p>
                        </FieldContainer>} */}
                        {/* <FieldContainer css={css`${tw`flex items-center`}`}>
                            <RadioButton css={css`${this.state.terms ? tw`bg-purple-500` : tw`bg-transparent`}`} onClick={() => { this.handleChange("terms", !this.state.terms) }} />
                            <RoundTripLabel>I Agree to the&nbsp; <a href="http://www.zyppys.co.in/about/terms" css={css`${tw`font-medium underline`}`} target="_blank">Terms & Conditions</a></RoundTripLabel>
                        </FieldContainer> */}
                        <div
                            onClick={this.onButtonClick}
                            css={css`${tw`inline-block w-full mx-auto text-center rounded-full absolute z-10 py-4 px-4 text-base text-white font-medium cursor-pointer overflow-hidden truncate shadow-2xl`}; background-image: linear-gradient(115deg, #AA0BCA 0%, #7D0EDE 39%); bottom: 0%; left: 0%; right: 0%;`}>

                            {this.state.isSubmitting ? "Booking..." : "Book"}
                        </div>
                    </FormWrapper>
                </DetailsPopup>
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    ride: state.ride,
    app: state.app,
    selectedCar : state.app.selectedCar,
    isChauffeurDriven:state.app.isChauffeurDriven,
    isSelfDriven:state.app.isSelfDriven,
    isRental : state.app.isRental,
    isAirport : state.app.isAirport,
    accountId : state.app.accountId
})

export default connect(mapStateToProps)(CustomerInfo);
