import tw from "tailwind.macro"
import React from "react"
import SEO from "../../components/seo"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import { connect } from "react-redux"
import Menu from "../../components/Menu"
import Overlay from "../../components/Overlay"
import styled from "@emotion/styled"
import SettingsForm from "../../components/SettingsForm"

const Wrapper = styled.div`
  ${tw`flex items-center relative justify-center w-full flex-col bg-white`}
`

class Settings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
      showOverlay: false
    }
  }

  componentWillMount() {
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu, showOverlay: !this.state.showOverlay })
  }

  render() {
    return (
      <Wrapper>
        <SEO title="Settings" />
        <Header shadow partner dark openMenu={this.toggleMenu} />
        <SettingsForm />
        {/* <Footer /> */}
        {/* DO NOT MOVE OVERLAY COMPONENT */}
        <Overlay active={this.state.showOverlay} overlayClicked={()=>{this.setState({ showOverlay: false, showMenu: false})}}/>
        {this.state.showMenu && <Menu partner openMenu={this.toggleMenu} />}
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(Settings);