import React from "react"
import { connect } from "react-redux"
import tw from "tailwind.macro"
import styled from "@emotion/styled"
import ReactTable from "react-table";
import "react-table/react-table.css";
import { css } from "@emotion/core"
import { fetchPartnerBookings } from "../../actions/userAction"
import SliderModal from "../SliderModal"
import Overlay from "../../components/Overlay"
import Moment from "moment"
import AccordionItem from "../AccordionItem"
import Popup from 'reactjs-popup'
import { cancelRide, startRide, endRide, assignRide } from "./Functions"
import { fetchBidDetailsBusiness } from "../../actions/partnerActions"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-dropdown-select"
import Loader from 'react-loader-spinner'

const Wrapper = tw.div`
  hidden md:flex lg:flex relative w-full flex-col bg-white p-4 md:px-8 lg:px-8
`
const Button = styled.button`
  ${tw`py-1 px-2 text-center text-base font-normal text-white`}
  background-color: #8a3df6;
`
const Row = tw.div`
flex w-full justify-around items-center
`
const Column = tw.div`
block
`
const Title = tw.p`
flex text-gray-600
`
const SubTitle = tw.p`
text-gray-600 text-xs
`
const Text = tw.p`
flex py-2 font-medium
`
const SubText = tw.p`
break-words py-2 text-xs text-gray-700 font-medium
`
const LoaderContainer = styled.div`
  ${tw`flex justify-center h-full w-full bg-black absolute left-0 -mt-4 opacity-50`}
`

class BookingsPortal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: 'upcoming',
            selectedRide: {},
            showDetails: false,
            showOverlay: false,
            bookings: [],
            openPopup: false,
            driver: {},
            vehicle: {},
            startDate: Moment(),
            endDate: Moment(),
            odometerStartReading: null,
            odometerEndReading: null,
            showRideStart: false,
            showRideEnd: false,
            showLoader: false,
            licenseNo: null,
            regNo: null
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.driver != this.props.driver) {
            this.setState({ driver: this.props.driver })
        }
        if (prevProps.vehicle != this.props.vehicle) {
            this.setState({ vehicle: this.props.vehicle })
        }
        if (prevProps.ride != this.props.ride) {
            this.setState({ selectedRide: this.props.ride, showDetails: true, showOverlay: true })
        }
    }

    filterCaseInsensitive = (filter, row) => {
        console.log(filter);
        const id = filter.pivotId || filter.id;
        let value = filter.value;
        console.log(value);
        if (typeof row[id] == 'string') {
            console.log(id);
            if (row[id] !== undefined) {
                return String(row[id].toLowerCase()).includes(value.toLowerCase());
            }
            else {
                return true;
            }
        }
        else {

            if (row[id] !== undefined) {
                return String(row[id]).includes(value.toString());
            }
            else {
                return true
            }
        }
    }

    handleRowClicked = (rowInfo) => {
        window.document.getElementsByTagName("body")[0].style.overflow = "hidden"
        console.log("Selected Ride", rowInfo.original)
        this.props.dispatch(fetchBidDetailsBusiness(rowInfo.original.bidId))
    }

    handleClose = () => {
        window.document.getElementsByTagName("body")[0].style.overflow = "auto"
        this.props.dispatch(fetchPartnerBookings());
        this.setState({ showOverlay: false, showDetails: false })
    }

    popupOpen = (state) => {
        if (state === "start") {
            this.setState({ openPopup: true, showRideStart: true, showRideEnd: false });
        }
        else if (state === "end") {
            this.setState({ openPopup: true, showRideStart: false, showRideEnd: true });
        }
        else {
            this.setState({ openPopup: true });
        }
    }

    closeModal = () => {
        this.setState({ openPopup: false })
    }

    handleCancelRide = () => {
        //eslint-disable-next-line
        if (confirm("Are you sure you want to cancel this ride?")) {
            this.props.dispatch(cancelRide(this.state.selectedRide.bidId))
        }
    }

    handleStartDateChange = (date) => {
        this.setState({ startDate: Moment(date) })
    }

    handleEndDateChange = (date) => {
        this.setState({ endDate: Moment(date) })
    }

    handleStartClick = () => {
        if(this.state.odometerStartReading == null){
            alert("Please enter the start odometer reading")
            return
        }
        let data = {
            "bidId": Number(this.state.selectedRide.bidId),
            "odometerStartReading": Number(this.state.odometerStartReading),
            "rideStartDate": Moment(this.state.startDate,"DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY HH:mm:ss")
        }
        this.props.dispatch(startRide(data,this.startSuccess, this.startError))
    }

    startSuccess = () => {
        this.setState({ openPopup: false })
        alert("Ride successfully started")
    }

    startError = () => {
        alert("Cannot start ride at this moment")
    }

    handleEndClick = () => {
        if(this.state.odometerEndReading == null){
            alert("Please enter the end odometer reading")
            return
        }
        let data = {
            "bidId": Number(this.state.selectedRide.bidId),
            "odometerEndReading": Number(this.state.odometerEndReading),
            "actualRideEndDate": Moment(this.state.endDate,"DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY HH:mm:ss")
        }
        this.props.dispatch(endRide(data,this.endSuccess, this.endError))
    }

    endSuccess = () => {
        this.setState({ openPopup: false })
        alert("Ride successfully ended")
    }

    endError = () => {
        alert("Cannot end ride at this moment")
    }

    handleAssignClick = () => {
        if(this.state.licenseNo == null){
            alert("Please select a driver")
            return
        }
        if(this.state.regNo == null){
            alert("Please select a car")
            return
        }
        let data = {
            "bidId": Number(this.state.selectedRide.bidId),
            "regNo": this.state.regNo.toString(),
            "licenseNo": this.state.licenseNo.toString()
        }
        this.props.dispatch(assignRide(data))
    }

    render() {
        return (
            <Wrapper>
                <h1 css={css`${tw`font-bold text-black text-lg py-2`}`}>Bookings</h1>
                <div css={css`${tw`flex py-2`}`}>
                    <p css={css`${tw`font-normal text-base cursor-pointer`} + ${this.state.status == "upcoming" ? tw` text-purple-500 underline` : tw` text-black`}`} onClick={() => { this.setState({ status: 'upcoming' }) }}>Upcoming</p>
                    <p css={css`${tw`ml-4 font-normal text-base cursor-pointer`} + ${this.state.status == "past" ? tw` text-purple-500 underline` : tw` text-black`}`} onClick={() => { this.setState({ status: 'past' }) }}>Past</p>
                </div>
                <ReactTable
                    data={this.props.partnerBookings.filter(r => (this.state.status == 'past' ? r.hasOwnProperty('rideStatusDesc') && r.rideStatusDesc != '' : !r.hasOwnProperty('rideStatusDesc')))}
                    style={{ cursor: 'pointer', width: '100%' }}
                    filterable
                    sorted={[{ // the sorting model for the table
                        id: 'bidId',
                        desc: true
                    },
                    ]}
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: (e, handleOriginal) => {
                                if (rowInfo != undefined) {
                                    this.handleRowClicked(rowInfo)
                                }
                                console.log("A Td Element was clicked!");
                                console.log("it produced this event:", e);
                                console.log("It was in this column:", column);
                                console.log("It was in this row:", rowInfo);
                                console.log("It was in this table instance:", instance);

                                // IMPORTANT! React-Table uses onClick internally to trigger
                                // events like expanding SubComponents and pivots.
                                // By default a custom 'onClick' handler will override this functionality.
                                // If you want to fire the original onClick handler, call the
                                // 'handleOriginal' function.
                                if (handleOriginal) {
                                    handleOriginal();
                                }
                            }
                        };
                    }}
                    columns={[
                        {
                            Header: "Booking Id",
                            accessor: "bidId",
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Start Date",
                            id: 1,
                            accessor: (data) => (Moment(data.rideStartDate, "YYYY-MM-DD HH:mm:ss").format("DD MMM, YYYY hh:mm A")),
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "City",
                            accessor: "fromCity",
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Traveller Mobile",
                            accessor: "travelerContactNo",
                            style: { textAlign: "center" },
                        },

                        {
                            Header: "Trip Type",
                            accessor: "tripType",
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Vehicle",
                            accessor: "categoryDesc",
                            style: { textAlign: "center" },
                        },
                        {
                            Header: "Status",
                            id: 2,
                            show: this.state.status == 'past',
                            accessor: (data) => (this.state.status == 'past' ? data.rideStatusDesc : 'Upcoming'),
                            style: { textAlign: "center" },
                        },
                    ]}
                    className="-striped -highlight"
                    defaultPageSize={20}
                    defaultFilterMethod={this.filterCaseInsensitive}
                />
                {/* DO NOT MOVE OVERLAY COMPONENT */}
                <Overlay active={this.state.showOverlay} overlayClicked={this.handleClose} />
                {this.state.showDetails && <SliderModal active={this.state.showDetails} heading={"#" + this.state.selectedRide.bidId} close={this.handleClose}>
                {this.state.showLoader && <LoaderContainer style={{ zIndex: 1000 }} css={css`${tw``} `}>
                <div css={css`${tw`self-center`}`}>
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={100}
                            width={100}
                        />
                    </div>
                    </LoaderContainer>}
                    <div css={css`${tw``}`}>
                        {this.state.selectedRide.hasOwnProperty("rideStatusDesc") && this.state.selectedRide.rideStatusDesc.toLowerCase().includes("confirmed") && <Button css={css`${tw``}`} onClick={()=>this.popupOpen("start")}>Start Trip</Button>}
                        {this.state.selectedRide.hasOwnProperty("rideStatusDesc") && this.state.selectedRide.rideStatusDesc.toLowerCase().includes("started") && <Button css={css`${tw`ml-4`}`} onClick={()=>this.popupOpen("end")}>End Trip</Button>}
                        {/* <Button css={css`${tw`ml-4`}`}>Reassign Trip</Button> */}
                        {!this.state.selectedRide.hasOwnProperty("rideStatusDesc") && <button css={css`${tw`ml-4 py-1 px-2 text-center text-base font-normal border border-solid border-purple-500 text-purple-500 hover:bg-purple-500 hover:text-white`}`} onClick={this.handleCancelRide}>Cancel Trip</button>}
                    </div>
                    <AccordionItem title="Traveller Details" isActive fixed>
                        <Row>
                            <Column>
                                <Title>Name</Title>
                                <Text>{this.state.selectedRide.travelerName} </Text>
                            </Column>
                            <Column>
                                <Title>Phone</Title>
                                <a css={css`${tw`flex py-2 font-medium`}`} href={"tel:+91" + this.state.selectedRide.travelerContactNo}>{this.state.selectedRide.travelerContactNo} </a>
                            </Column>
                            <Column>
                                <Title>Email</Title>
                                <a css={css`${tw`flex py-2 font-medium`}`} href={"mailto:" + this.state.selectedRide.travelerEmailId}>{this.state.selectedRide.travelerEmailId} </a>
                            </Column>
                        </Row>
                    </AccordionItem>
                    <AccordionItem title="Trip Details" isActive fixed>
                        <Row>
                            <Column>
                                <Title>From</Title>
                                <Text>{this.state.selectedRide.fromCity}</Text>
                                <Column>
                                    <SubTitle>Pickup Point</SubTitle>
                                    <SubText>{this.state.selectedRide.pickupLocality.substr(this.state.selectedRide.pickupLocality.indexOf(",") + 1)}</SubText>
                                </Column>
                            </Column>
                            <Column>
                                <Text>{this.state.selectedRide.measureLookupDesc}</Text>
                            </Column>
                            {!this.state.selectedRide.measureLookupDesc.includes('hour') && <Column>
                                <Title>To</Title>
                                <Text>{this.state.selectedRide.toCity}</Text>
                                <Column>
                                    <SubTitle>Dropoff Point</SubTitle>
                                    <SubText>{this.state.selectedRide.destinationLocality.substr(this.state.selectedRide.destinationLocality.indexOf(",") + 1)}</SubText>
                                </Column>
                            </Column>}
                            <Column>
                                <Title>Travel Dates</Title>
                                <Text>{Moment(this.state.selectedRide.rideStartDate, "DD-MM-YYYY HH:mm:ss").format("MMM DD") + " - " + Moment(this.state.selectedRide.rideEndDate, "DD-MM-YYYY HH:mm:ss").format("MMM DD")}</Text>
                                <Column>
                                    <SubTitle>Start Time</SubTitle>
                                    <SubText>{Moment(this.state.selectedRide.rideStartDate, "DD-MM-YYYY HH:mm:ss").format("hh:mm A")}</SubText>
                                </Column>
                            </Column>
                            <Column>
                                <Title>Status</Title>
                                <p css={css`${tw`font-medium ml-0 border-2 border-blue-700 p-8 font-medium`}`}>{this.state.selectedRide.hasOwnProperty("rideStatusDesc") ? this.state.selectedRide.rideStatusDesc : 'Upcoming'}</p>
                            </Column>
                        </Row>
                    </AccordionItem>
                    <AccordionItem title="Package Details" isActive fixed>
                        <Row>
                            <Column>
                                <Title>Total Amount</Title>
                                <Text>{this.state.selectedRide.hasOwnProperty("totalBillAmount") ? "₹ " + Math.round(this.state.selectedRide.totalBillAmount).toLocaleString() : "₹ 0"}</Text>
                                <Column>
                                    <Title>Base Fare</Title>
                                    <Text>{this.state.selectedRide.hasOwnProperty("rideAmount") ? "₹ " + Math.round(this.state.selectedRide.rideAmount).toLocaleString() : "₹ 0"}</Text>
                                </Column>
                            </Column>
                            <Column>
                                <Title>Total KMs</Title>
                                <Text>{this.state.selectedRide.hasOwnProperty("packageKms") ? Math.round(this.state.selectedRide.packageKms).toLocaleString() : 0}</Text>
                                <Column>
                                    <Title>Driver Charges</Title>
                                    <Text>{this.state.selectedRide.hasOwnProperty("driverCharges") ? "₹ " + Math.round(this.state.selectedRide.driverCharges).toLocaleString() : "₹ 0"}</Text>
                                </Column>
                            </Column>
                            <Column>
                                <Title>Vehicle</Title>
                                <Text>{this.state.selectedRide.categoryDesc}</Text>
                                <Column>
                                    <Title>GST</Title>
                                    <Text>{this.state.selectedRide.hasOwnProperty("serviceTax") ? "₹ " + Math.round(this.state.selectedRide.serviceTax).toLocaleString() : "₹ 0"}</Text>
                                </Column>
                            </Column>
                            <Column>
                                <Title>Price/Km</Title>
                                <Text>{this.state.selectedRide.hasOwnProperty("actualCostPerKm") ? "₹ " + Math.round(this.state.selectedRide.actualCostPerKm).toLocaleString() : "₹ 0"}</Text>
                                <Column>
                                    <Title>Extra Cost/Km</Title>
                                    <Text>{this.state.selectedRide.hasOwnProperty("extraCostPerKm") ? "₹ " + Math.round(this.state.selectedRide.extraCostPerKm).toLocaleString() : "₹ 0"}</Text>
                                </Column>
                            </Column>
                            <Column>
                                <Title>Extra Cost/Hr</Title>
                                <Text>{this.state.selectedRide.hasOwnProperty("extraCostPerHr") ? "₹ " + Math.round(this.state.selectedRide.extraCostPerHr).toLocaleString() : "₹ 0"}</Text>
                                <Column>
                                    <Title>Days</Title>
                                    <Text>{this.state.selectedRide.hasOwnProperty("noOfDays") ? this.state.selectedRide.noOfDays : 0}</Text>
                                </Column>
                            </Column>
                        </Row>
                    </AccordionItem>
                    <Row>
                        <AccordionItem title="Vehicle, Driver Details & Options" isActive fixed>
                            {this.state.driver.hasOwnProperty("mobileNo") && this.state.vehicle.hasOwnProperty("regNo") && <Row>
                                <Column>
                                    <Title>Driver Name</Title>
                                    <Text>{this.state.driver.name}</Text>
                                    <Column>
                                        <Title>Driver Phone</Title>
                                        <a css={css`${tw`flex py-2 font-medium`}`} href={"tel:+91" + this.state.driver.mobileNo}>{this.state.driver.mobileNo}</a>
                                    </Column>
                                </Column>
                                <Column>
                                    <Title>Vehicle #</Title>
                                    <Text>{this.state.vehicle.regNo}</Text>
                                </Column>
                            </Row>}
                            {!this.state.driver.hasOwnProperty("mobileNo") && !this.state.vehicle.hasOwnProperty("regNo") && !this.state.selectedRide.bidStatusDesc.toLowerCase().includes("cancelled") && <Row css={css`${tw`-mt-4 pb-2`}`}>
                                <Column css={css`${tw`w-1/2`}`}>
                                    <Title>Driver</Title>
                                    <div css={css`${tw`w-full text-base font-medium appearance-none outline-none shadow-none border-none pb-2`}`}>
                                        <Select options={this.props.drivers} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} labelField="name" onChange={(val) => {
                                            if (val.length > 0) {
                                                this.setState({ licenseNo: val[0].licenseNo })
                                            }
                                        }} />
                                    </div>
                                    <Column>
                                        <Title>Vehicle</Title>
                                        <div css={css`${tw`w-full text-base font-medium appearance-none outline-none shadow-none border-none`}`}>
                                            <Select options={this.props.cars} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} labelField="model_description" onChange={(val) => {
                                                if (val.length > 0) {
                                                    this.setState({ regNo: val[0].reg_no })
                                                }
                                            }} />
                                        </div>
                                    </Column>
                                </Column>
                                <Column>
                                    <Button onClick={this.handleAssignClick}>Assign Driver</Button>
                                </Column>
                            </Row>}
                            {!this.state.driver.hasOwnProperty("mobileNo") && !this.state.vehicle.hasOwnProperty("regNo") && this.state.selectedRide.bidStatusDesc.toLowerCase().includes("cancelled") && <Row css={css`${tw`py-10`}`}>
                                <Column>
                                    <Text>No Data</Text>
                                </Column>
                            </Row>}
                        </AccordionItem>
                        <AccordionItem title="Final Bill Details" isActive fixed>
                            <Row>
                                <Column>
                                    <Title>Od. Start Reading</Title>
                                    <Text>{this.state.selectedRide.hasOwnProperty("odometerStartReading") ? this.state.selectedRide.odometerStartReading : 0}</Text>
                                    <Column>
                                        <Title>Od. End Reading</Title>
                                        <Text>{this.state.selectedRide.hasOwnProperty("odometerEndReading") ? this.state.selectedRide.odometerEndReading : 0}</Text>
                                    </Column>
                                </Column>
                                <Column>
                                    <Title>Total Amount</Title>
                                    <Text>{this.state.selectedRide.hasOwnProperty("totalBillAmount") ? "₹ " + Math.round(this.state.selectedRide.totalBillAmount).toLocaleString() : "₹ 0"}</Text>
                                    <Column>
                                        <Title>Driver Charges</Title>
                                        <Text>{this.state.selectedRide.hasOwnProperty("driverCharges") ? "₹ " + Math.round(this.state.selectedRide.driverCharges).toLocaleString() : "₹ 0"}</Text>
                                    </Column>
                                </Column>
                                <Column>
                                    <Title>Total KMs</Title>
                                    <Text>{this.state.selectedRide.hasOwnProperty("packageKms") ? Math.round(this.state.selectedRide.packageKms).toLocaleString() : 0}</Text>
                                    <Column>
                                        <Title>GST</Title>
                                        <Text>{this.state.selectedRide.hasOwnProperty("serviceTax") ? "₹ " + Math.round(this.state.selectedRide.serviceTax).toLocaleString() : "₹ 0"}</Text>
                                    </Column>
                                </Column>
                            </Row>
                        </AccordionItem>
                    </Row>
                </SliderModal>}
                <Popup
                    open={this.state.openPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div css={css`${tw`border-2`}`}>
                        <div className="modal" style={{ position: 'absolute', right: 10 }}>
                            <a style={{ cursor: 'pointer' }} className="close" onClick={this.closeModal}>
                                <div style={{ textAlign: 'center', color: 'gray', cursor: 'pointer' }}>Close</div>
                            </a>
                        </div>
                        {this.state.showRideStart && <div css={css`${tw`p-2`}`}>
                            <div css={css`${tw`flex w-full items-center`}`}>
                                <p css={css`${tw`text-base text-black w-1/3`}`}>Start Date:</p>
                                <DatePicker
                                    selected={Moment(this.state.startDate).toDate()}
                                    onChange={date => this.handleStartDateChange(date)}
                                    showTimeSelect
                                    timeFormat="hh:mm a"
                                    timeIntervals={30}
                                    timeCaption="Time"
                                    dateFormat="MMMM dd, yyyy hh:mm aa"
                                    customInput={<CalendarInput />}
                                    tabIndex={1}
                                    css={css`${tw`shadow-lg p-2 rounded-lg`}`}
                                />
                            </div>
                            <div css={css`${tw`flex items-center w-full mt-4`}`}>
                                <p css={css`${tw`text-base text-black w-1/3`}`}>Odometer Start Reading:</p>
                                <input type="text" css={css`${tw`border border-gray-600 p-2 text-base`}`} onChange={(e) => this.setState({ odometerStartReading: e.target.value })} /></div>
                            <div css={css`${tw`mt-4 flex w-full`}`}>
                                <Button css={css`${tw`rounded-none px-4`}`} onClick={this.handleStartClick}>Start</Button>
                            </div>
                        </div>}
                        {this.state.showRideEnd && <div css={css`${tw`p-2`}`}>
                            <div css={css`${tw`flex w-full items-center`}`}>
                                <p css={css`${tw`text-base text-black w-1/3`}`}>End Date:</p>
                                <DatePicker
                                    selected={Moment(this.state.endDate).toDate()}
                                    onChange={date => this.handleEndDateChange(date)}
                                    showTimeSelect
                                    timeFormat="hh:mm a"
                                    timeIntervals={30}
                                    timeCaption="Time"
                                    dateFormat="MMMM dd, yyyy h:mm aa"
                                    customInput={<CalendarInput />}
                                    tabIndex={1}
                                    css={css`${tw`shadow-lg p-2 rounded-lg`}`}
                                />
                            </div>
                            <div css={css`${tw`flex items-center w-full mt-4`}`}>
                                <p css={css`${tw`text-base text-black w-1/3`}`}>Odometer End Reading:</p>
                                <input type="text" css={css`${tw`border border-gray-600 p-2 text-base`}`} onChange={(e) => this.setState({ odometerEndReading: e.target.value })} /></div>
                            <div css={css`${tw`mt-4 flex w-full`}`}>
                                <Button css={css`${tw`rounded-none px-4`}`} onClick={this.handleEndClick}>End</Button>
                            </div>
                        </div>}
                    </div>
                </Popup>
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    partnerBookings: state.user.partnerBookings,
    driver: state.partner.driver,
    vehicle: state.partner.vehicle,
    ride: state.partner.ride,
    drivers: state.user.drivers,
    cars: state.user.cars
})

export default connect(mapStateToProps)(BookingsPortal)

const CalendarInput = ({ value, onClick }) => (
    <div css={css`${tw`p-2 text-center border border-gray-600 text-base`}`} className="example-custom-input" onClick={onClick}>
        {value}
    </div>
);