// const api = "https://api.zyppys.in/" //PRODUCTION
// const oldApi = "https://api.zyppys.in/" //PRODUCTION
const oldApi = "https://uat.zyppys.in/" //TEST
const api = "https://uatbusiness.zyppys.in/" //TEST
const newApi = "http://ec2-54-68-246-225.us-west-2.compute.amazonaws.com:8086/" //Test

export const urls = {
    registerBusiness: oldApi + 'registerBusiness',
    fetchLocations: oldApi + 'fetchLocations',
    locationByLatLongUrl: oldApi + 'fetchLocationByLatLong',
    vehiclesUrl: api + 'fetchVehiclePricingBusiness',
    oldVehiclesUrl: oldApi + 'fetchVehicleDetails',
    hotelsUrl: oldApi + 'fetchHotelDetails',
    recreationsUrl: oldApi + 'fetchRecreationDetails',
    onRoutePlacesUrl: oldApi + 'fetchOnRoutePlaces',
    sendOtpUrl: oldApi + 'sendOTP',
    authOtpUrl: oldApi + 'authenticateOTP',
    oldSubmitUrl: oldApi + 'submitBidDetails',
    submitUrl: api + 'submitBidDetailsBusiness',
    vehiclesPricing: oldApi + 'fetchVehiclePricing',
    getAccountDetails: oldApi + 'getAccountDetails',
    cancelUrl: oldApi + 'cancelRide',
    fetchBids: oldApi + 'fetchBids',
    fetchBidsForBusiness: api + 'fetchBidsForBusiness',
    fetchRideAmountsWithPaymentsForBusiness: api + 'fetchRideAmountsWithPaymentsForBusiness',
    fetchBusinessProfile: api + 'fetchBusinessProfile',
    fetchPartnerData: api + 'fetchPartnerData',
    fetchBidDetailsBusiness: api + 'fetchBidDetailsBusiness',
    checkAssignedVehicleForBusiness: api + 'checkAssignedVehicleForBusiness',
    checkAssignedDriverForBusiness: api + 'checkAssignedDriverForBusiness',
    fetchProductNamesBusiness: api + 'fetchProductNamesBusiness',
    addDriverForBusiness: api + 'addDriverForBusiness',
    addVehicleForBusiness: api + 'addVehicleForBusiness',
    fetchProductBusiness: api + 'fetchProductBusiness',
    startRideForBusiness: api + 'startRideForBusiness',
    endRideForBusiness: api + 'endRideForBusiness',
    assignPartnerAndVehicleBusiness: api + 'assignPartnerAndVehicleBusiness',
    fetchDriversForBusiness: api + 'fetchDriversForBusiness',
    insertOrUpdateProductPricingBusiness: api + 'insertOrUpdateProductPricingBusiness',
    fetchProductPricingBusiness: api + 'fetchProductPricingBusiness',
    fetchVendorBidsBusiness: api + 'fetchVendorBidsBusiness',
    loginBusinessUser: api + 'loginBusinessUser'
}