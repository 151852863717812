export const storeKey = async (key, data) => {
    try {
      const serialisedKey = JSON.stringify(data);
      console.log(serialisedKey);
      await localStorage.setItem(key, serialisedKey);
      console.log('key saved');
    }
    catch (err) {
      //do nothing
      console.log('key failed');
  
    }
  }
  
  
  export const retrieveKey = async (key) => {
    try {
      const serialisedKey = await localStorage.getItem(key);
      if (serialisedKey === null) {
        return undefined;
      }
      return JSON.parse(serialisedKey);
    }
    catch (err) {
      return undefined;
    }
  }
  
  
  export const remove = async (key) => {
    try {
      let keys = ['isLoggedIn'];
      localStorage.multiRemove(keys, (err) => {
        // keys k1 & k2 removed, if they existed
        // do most stuff after removal (if you want)
        console.log("Keys Removed");
      });
    }
    catch (err) {
      console.log("error in removing key" + err);
    }
  }
  
  