import React from 'react';
import $ from 'jquery';
import { css, Global } from '@emotion/core'
import styled from "@emotion/styled"
import tw from "tailwind.macro"
import {connect} from 'react-redux';


let x = "autocomplete";
let autocomplete;
let infowindow;
let infowindowContent;
let input;
let restrictOptions = {
  componentRestrictions: {country: "in"}
};



class Stop extends React.Component{
	constructor(props) {
		super(props);
    this.state = {
      loading : false
    }
    this.toggleLoading = this.toggleLoading.bind(this);
    this.useGeolocation = this.useGeolocation.bind(this);
    this.formatTime = this.formatTime.bind(this);
    this.changePlace = this.changePlace.bind(this);
    this.getLatLngOfCity = this.getLatLngOfCity.bind(this);
	}


  getLatLngOfCity(city,actionType,id) {
    debugger
    let self = this;
    let geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({'address':city},function(result,status){
      if (status == window.google.maps.GeocoderStatus.OK) {
        debugger
        let res = result[0];
        if(id != 1){
          self.props.updateDestCity({city:city,cityLat:res.geometry.location.lat(),cityLng:res.geometry.location.lng()});
        }
        if(actionType == 'addHotelCity'){
          self.props.addHotelCity({name:city,latitude:res.geometry.location.lat(),longitude:res.geometry.location.lng()});
        }
        else if(actionType == 'editHotelCity'){
          self.props.editHotelCity(id,{name:city,latitude:res.geometry.location.lat(),longitude:res.geometry.location.lng()});
        }
        else if(actionType == 'firstHotelCity'){
          self.props.addFirstHotelCity({name:city,latitude:res.geometry.location.lat(),longitude:res.geometry.location.lng()});
        }
      }
    })
  }

  componentDidMount(){
    debugger
    let y = x + this.props.id;
    if(this.props.text != null && this.props.text != 'Where do you want to go?')
    {
      window.document.getElementById(this.props.id).value = this.props.text
    }
		input = window.document.getElementById(this.props.id);
		y = new window.google.maps.places.Autocomplete(input,restrictOptions);
		y.addListener('place_changed', ()=>this.changePlace(y));
		infowindow = new window.google.maps.InfoWindow();
		infowindowContent = window.document.getElementById('infowindow-content');
		infowindow.setContent(infowindowContent);

    // For Deleting Stops

    $(".stop-input").on("focus", function(){
      // $(this).
      // alert("Focused on a stop input");
    });

	}

  componentWillReceiveProps(nextProps){
    debugger
    if(nextProps.text != undefined){
        window.document.getElementById(this.props.id).value = nextProps.text;
    }
    // else{
    //   document.getElementById(this.props.id).value = nextProps.text;
    // }
    // if(this.props.text != nextProps.text){
    //   document.getElementById(this.props.id).value = nextProps.text;
    // }
  }

	changePlace(y) {
		debugger
    let city = ''
		let place = y.getPlace();
    let flag = 0;
    let storableLocation = {};
    let types = ['locality','administrative_area_level_2','administrative_area_level_1']
    for(let i=0;i<3;i++){
      for(let j=0;j<place.address_components.length;j++){
        if(place.address_components[j].types[0] == types[i]){
          city = place.address_components[j].long_name;
          flag = 1;
          break;
        }
      }
      if(flag == 1){
        break;
      }
    }
    if(city == "Bengaluru" || city=="Bengaluru Rural" || city=="Bengaluru Urban" || city == "Bangalore Rural"|| city=="Bangalore Urban"){
      city = "Bangalore";
    }
    if(city == "Secunderabad"){
      city = "Hyderabad";
    }
    if(this.props.type=='pickup'){
      this.props.getLeadTimeByCity(city);
      this.props.addFirstStop({city:city,name:place.name+' , '+place.formatted_address,latitude:place.geometry.location.lat(),longitude:place.geometry.location.lng()});
      this.getLatLngOfCity(city,'firstHotelCity',1)
    }
    else if(this.props.text != ''){
      if(this.props.editStop(this.props.id,{city:city,name:place.name+' , '+place.formatted_address,latitude:place.geometry.location.lat(),longitude:place.geometry.location.lng()})){
        this.getLatLngOfCity(city,'editHotelCity',this.props.id);
      };
    }
    else{
      if(city == ''){
        city = place.name+' , '+place.formatted_address
      }
      if(this.props.addStop({city:city,name:place.name+' , '+place.formatted_address,latitude:place.geometry.location.lat(),longitude:place.geometry.location.lng()})){
        // if(city != place.name+' , '+place.formatted_address){
          this.getLatLngOfCity(city,'addHotelCity',this.props.id);
        // }
      }
    }

		if (!place.geometry) {
			window.alert("No details available for input: '" + place.name + "'");
			return;
		}
		var address = '';
		if (place.address_components) {
			address = [
				(place.address_components[0] && place.address_components[0].short_name || ''),
				(place.address_components[1] && place.address_components[1].short_name || ''),
				(place.address_components[2] && place.address_components[2].short_name || '')
			].join(' ');
		}


	}

  formatTime(time){
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = time.match(/:(\d+)/)[1];
    var AMPM = time.match(/\s(.*)$/)[1];
    if(AMPM == "PM" && hours<12) hours = hours+12;
    if(AMPM == "AM" && hours==12) hours = hours-12;
    var sHours = hours.toString();
    if(sHours.length == 1){
      sHours = "0"+sHours;
    }
    var sMinutes = minutes.toString();
    let seconds = "00";
    let formattedTime = sHours+":"+sMinutes+":"+seconds;
    return formattedTime;
  }

  toggleLoading(){
    this.setState({loading: true});
  }

  useGeolocation(){
    debugger
    this.props.useGeolocation();
    this.toggleLoading();
  }


	render(){
        if(this.props.id >=3 && this.props.id != 4){
            window.document.getElementById(0).value = ""
        }
    return(
      <DestinationInput>
      <Global styles={styles} />
        <div className={`destination-dot ${this.props.type}`} css={css`${tw`absolute`}`} >{this.props.remove?<span css={css`${tw`font-normal`}`}>X</span>:""}</div>
         <div css={css`${tw`w-full`}`}>
           {this.props.disable ? <input id={this.props.id} type="text" name="destination" placeholder={this.props.placeholder} css={css`${tw`outline-none py-2 pl-14 w-full h-full appearance-none truncate cursor-not-allowed`}`} disabled></input> : <input id={this.props.id} type="text" name="destination" placeholder={this.props.placeholder} css={css`${tw`outline-none py-6 pl-16 w-full h-full appearance-none truncate`}`}/>}
         </div>
         {/* {this.props.type == "pickup" ? <div onClick={this.useGeolocation} css={css`${tw`cursor-pointer inline-flex items-center justify-center absolute right-0 h-full w-1/6 bg-purple-500 items-center inline-flex justify-center text-center`}`}>
            {this.props.geoLocationRequest ? "..." : <img src={require("../svgs/location.svg")} css={css`${tw`h-5 md:h-6 lg:h-6`}`} alt="location" />}
         </div> : ""} */}
       </DestinationInput>
    )
  }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(Stop);

const DestinationInput = styled.li`
${tw`bg-white relative flex flex-wrap w-full mb-2 items-stretch shadow-lg overflow-hidden pointer-events-auto`}
box-shadow: 0 11px 19px 0 rgba(0,0,0,0.10);
  ${'' /* border-radius: 0.25rem; */}
`
const styles = css`
.destination-dot{
    top: 30.33%;
    left: 0.85rem;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 100px;
    background: #E282F5;
    padding: 0.35rem 0.5rem;
    span{
      opacity: 0;
      transform: scale(0);
    ${'' /* animation: scaleIn 0.2s ease-in-out;
      padding: 0.5rem 0.25rem; */}
    }
    color: white;
    text-align: center;
    &.pickup{
      background: #7D0EDE;
      box-shadow: 0 6px 8px -3px rgba(#7D0EDE,0.7);
    }
    &.middle{
      background: #AA0BCA;
      box-shadow: 0 6px 8px -3px rgba(154,73,201,0.70);
    }
    &.final{
      background: #AA0BCA;
      box-shadow: 0 6px 8px -3px rgba(154,73,201,0.70);
    }
    &.start{
  
    }
    &.end{
  
    }
  }
  .destination-dot:hover{
    cursor: pointer;
    span{
      opacity: 0;
      animation: scaleIn 0.2s ease-in-out forwards;
    }
  }
  `;
