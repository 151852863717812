import tw from "tailwind.macro"
import React from "react"
import { css } from '@emotion/core'
import "../fonts/fonts.css"
import styled from "@emotion/styled"
import { connect } from "react-redux"
import Moment from "moment"
import { navigate } from "gatsby"

const Wrapper = styled.div`
  ${tw`flex flex-row fixed top-0 left-0 items-center justify-between w-full md:w-2/3 lg:w-2/3 z-40 px-4`}
  ${'' /* background: #f0eeea; */}
  background: #e7ebef;
`
const ContainerOne = tw.div`
  block items-center px-5 lg:px-10 py-5
`
const ContainerTwo = tw.div`
  flex items-center justify-end px-5 lg:px-10 py-5
`
const Text = tw.p`
   text-black font-bold text-base md:text-lg lg:text-lg
`
const Button = styled.div`
  ${tw`px-4 py-1 text-white text-base lg:text-lg font-semibold rounded appearance-none outline-none shadow-none border-none text-center`}
  background-color: #8a3df6;
`
const Link = styled.div`
  ${tw`flex px-4 py-1 text-black text-xs lg:text-lg font-medium rounded appearance-none outline-none shadow-none border-none text-center cursor-pointer`}
`
const Hamburger = styled.div`
${tw`flex md:hidden lg:hidden px-1 py-1 bg-white shadow-full rounded-full cursor-pointer`}
`
const DestinationWrapper = styled.div`
${tw`flex md:hidden lg:hidden text-xs font-light`}
`


class SubHeader extends React.Component {
    constructor(props){
        super(props)
        this.state = {
        }
    }

    render(){
        let ride = this.props.ride;
        let app = this.props.app;
        return(
<Wrapper css={css`${this.props.shadow ? tw`shadow-lg` : tw``}`}>
    <ContainerOne>
    <div css={css`${tw`flex cursor-pointer`}`} onClick={()=>{ navigate("/booking",{ state:{} })}}>
    <img css={css`${tw`mr-4 mb-1`}`} src={require("../svgs/angle-back.svg")} alt="back-arrow"/>
      <Text>{app.isRental ? "Hourly Rental" : app.isAirport ? "Airport Transfer" : "Outstation"}</Text>
      </div>
          <DestinationWrapper>From {ride.pickup.city} {Moment(ride.chauffeurStartDate,"DD-MM-YYYY").format("DD, MMM")} {Moment(ride.startTime,'HH:mm:ss').format('hh:mm A')} {!app.isRental && !app.isAirport && "To " + ride.dest.city}</DestinationWrapper>
    </ContainerOne>
    <ContainerTwo>
   <Link onClick={()=>{ navigate("/booking",{ state:{} })}}>Modify</Link>
    </ContainerTwo>
  </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    ride: state.ride,
    app: state.app
})

export default connect(mapStateToProps)(SubHeader);
