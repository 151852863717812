import tw from "tailwind.macro"
import React from "react"
import styled from "@emotion/styled"
import { css } from '@emotion/core'
import { connect } from "react-redux"
import { navigate } from 'gatsby';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { addStop, carsLoadingToTrue, removeLastStop, updateChauffeurStartDate, updateChauffeurEndDate, updateStartTime, updateEndTime, updateTripType, fetchVehiclesForChauffeurDriven, setSelfDrivenToFalse, setChauffeurDrivenToTrue, updateCarsData, updateRentalType } from '../actions/rideActions';
import Select from "react-dropdown-select"
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'

const DateTimeWrapper = styled.div`
${tw`w-full py-2 justify-center items-center content-center self-center`}
`
const DateTimeContainer = styled.div`
${tw`flex items-center content-center justify-start w-full px-2 py-2 text-base md:text-lg lg:text-lg font-normal`}
`
const DateTimeLabel = styled.div`
${tw`flex items-center w-1/3 md:w-1/4 lg:w-1/4 px-2 py-2 text-base md:text-lg lg:text-lg font-bold text-purple-500`}
`
const TripWrapper = styled.div`
${tw`w-full py-2 justify-center items-center content-center self-center`}
`
const TripContainer = styled.div`
${tw`flex w-full py-2 px-2 justify-start items-center content-center self-center`}
`
const RadioButton = styled.span`
${tw`h-8 w-8 rounded-full border-2 border-purple-500 cursor-pointer`}
min-width: 2rem;
min-height: 2rem;
`
const RoundTripLabel = styled.div`
${tw`flex items-center px-2 py-2 text-base md:text-lg lg:text-lg font-bold text-purple-500`}
`

let packageList = [
    {
        "label": "4hrs, 40kms",
        "value": 5
    },
    {
        "label": "8hrs, 80kms",
        "value": 6
    }
]

let startTime, endTime, startDTime, absMinTime, endDTime;

// alert(Moment("23:30","HH:mm"));


function convertTime12to24(time12h) {
    debugger

    let [time, modifier] = time12h.split(' ');
    // alert(time+""+modifier);
    if (modifier == "AM") {
        let [hrs, mins] = time.split(":");
        if (hrs == '12') {
            hrs = '00';
        }
        return hrs + ':' + mins + ":00";
    }
    else if (modifier == "PM") {
        let [hrs, mins] = time.split(":");
        if (Number(hrs) < 12) {
            hrs = Number(hrs) + 12;
        }
        return hrs + ':' + mins + ":00";
    }
}

class DateTime extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            roundTrip: false,
            absMinTime: '',
            startDTime: '',
            endDTime: '',
            package: true
        }
        this.toggleTrip = this.toggleTrip.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
        this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
        this.packageChange = this.packageChange.bind(this);
    }

    componentWillMount() {
        let today = Moment();
        let minutes = 30 - (today.minute() % 30);
        if (!this.props.isAirport && !this.props.isRental) {
            absMinTime = Moment().add(this.props.ride.pickup.leadTime, 'h').add(minutes, 'm');
            startDTime = Moment().add(this.props.ride.pickup.leadTime, 'h').add(minutes, 'm');
        }
        if (this.props.isAirport) {
            absMinTime = Moment().add(this.props.ride.pickup.leadTime, 'h').add(4, 'h').add(minutes, 'm');
            startDTime = Moment().add(this.props.ride.pickup.leadTime, 'h').add(4, 'h').add(minutes, 'm');
        }
        if (this.props.isRental) {
            absMinTime = Moment().add(this.props.ride.pickup.leadTime, 'h').add(4, 'h').add(minutes, 'm');
            startDTime = Moment().add(this.props.ride.pickup.leadTime, 'h').add(4, 'h').add(minutes, 'm');
        }
        this.props.dispatch(updateChauffeurStartDate(startDTime.format("DD-MM-YYYY")));
        startTime = absMinTime.format("HH:mm");
        this.props.dispatch(updateStartTime(startTime + ":00"));
        endDTime = Moment(absMinTime).hours(23).minutes(30);
        this.props.dispatch(updateChauffeurEndDate(endDTime.format("DD-MM-YYYY")));
        endTime = endDTime.format("HH:mm");
        this.props.dispatch(updateEndTime(endTime + ":00"));
        this.setState({ absMinTime, startDTime, endDTime });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isAirport != this.props.isAirport || prevProps.isRental != this.props.isRental || prevProps.ride.pickup.leadTime != this.props.ride.pickup.leadTime) {
            let today = Moment();
            let minutes = 30 - (today.minute() % 30);
            if (!this.props.isAirport && !this.props.isRental) {
                absMinTime = Moment().add(this.props.ride.pickup.leadTime, 'h').add(minutes, 'm');
                startDTime = Moment().add(this.props.ride.pickup.leadTime, 'h').add(minutes, 'm');
            }
            if (this.props.isAirport) {
                absMinTime = Moment().add(this.props.ride.pickup.leadTime, 'h').add(4, 'h').add(minutes, 'm');
                startDTime = Moment().add(this.props.ride.pickup.leadTime, 'h').add(4, 'h').add(minutes, 'm');
            }
            if (this.props.isRental) {
                absMinTime = Moment().add(this.props.ride.pickup.leadTime, 'h').add(4, 'h').add(minutes, 'm');
                startDTime = Moment().add(this.props.ride.pickup.leadTime, 'h').add(4, 'h').add(minutes, 'm');
            }
            this.props.dispatch(updateChauffeurStartDate(startDTime.format("DD-MM-YYYY")));
            startTime = absMinTime.format("HH:mm");
            this.props.dispatch(updateStartTime(startTime + ":00"));
            endDTime = Moment(absMinTime).hours(23).minutes(30);
            this.props.dispatch(updateChauffeurEndDate(endDTime.format("DD-MM-YYYY")));
            endTime = endDTime.format("HH:mm");
            this.props.dispatch(updateEndTime(endTime + ":00"));
            this.setState({ absMinTime, startDTime, endDTime });
        }
    }

    handleStartDateChange(date) {
        debugger
        let startTimeFromDate = Moment(date, "DD-MM-YYYY HH:mm A").format("hh:mm A")
        let [hrs, mins] = startTimeFromDate.split(':');
        this.handleStartTimeChange(startTimeFromDate)
        this.setState({ startDTime: Moment(date) }, function () {
            endTime = Moment(this.state.startDTime).hours(23).minutes(30).format("HH:mm");
            this.props.dispatch(updateEndTime(endTime + ":00"));
            if (Number(hrs) == 11) {
                this.setState({ endDTime: Moment(this.state.startDTime).add(1,'day').hours(23).minutes(30) }, function () {
                    this.props.dispatch(updateChauffeurEndDate(this.state.endDTime.format("DD-MM-YYYY")));
                })
            }
            else {
                this.setState({ endDTime: Moment(this.state.startDTime).hours(23).minutes(30) }, function () {
                    this.props.dispatch(updateChauffeurEndDate(this.state.endDTime.format("DD-MM-YYYY")));
                })
            }
            this.props.dispatch(updateChauffeurStartDate(this.state.startDTime.format("DD-MM-YYYY")));
        });

    }

    handleEndDateChange(date) {
        let endTimeFromDate = Moment(date, "DD-MM-YYYY HH:mm A").format("hh:mm A")
        this.handleEndTimeChange(endTimeFromDate)
        this.setState({ endDTime: Moment(date) }, function () {
            this.props.dispatch(updateChauffeurEndDate(this.state.endDTime.format("DD-MM-YYYY")));
        })
    }

    handleStartTimeChange(value) {
        let time = convertTime12to24(value);
        this.props.dispatch(updateStartTime(time));
        let [hrs, mins, secs] = time.split(':');
        console.log("hrs",hrs)
        this.setState({ startTime: time, startDTime: this.state.startDTime.hours(hrs).minutes(mins) }, function () {
            endTime = Moment(this.state.startDTime).hours(23).minutes(30).format("HH:mm");
            this.props.dispatch(updateEndTime(endTime + ":00"));
            if(Number(hrs) === 23){
                this.props.dispatch(updateChauffeurEndDate(Moment(this.state.startDTime).add(1,'day').hours(23).minutes(30).format("DD-MM-YYYY")));
                this.setState({ endDTime: Moment(this.state.startDTime).add(1,'day').hours(23).minutes(30) })
            }
            else{
            this.props.dispatch(updateChauffeurEndDate(Moment(this.state.startDTime).hours(23).minutes(30).format("DD-MM-YYYY")));
            this.setState({ endDTime: Moment(this.state.startDTime).hours(23).minutes(30) })
            }
        });
        // this.props.dispatch(updateStartTime(time));
    }

    handleEndTimeChange(value) {
        // let hrs,mins;
        let time = convertTime12to24(value);
        this.props.dispatch(updateEndTime(time));
        this.setState({ endTime: time });
        // this.props.dispatch(updateEndTime(time));
    }

    packageChange(value) {
        this.setState({ package: value })
        this.props.dispatch(updateRentalType(value));
    }

    toggleTrip() {
        debugger
        let payLoad;
        let stops = this.props.ride.stops;
        if (this.props.ride.measureLookupId == '100010001') {//if roundTrip
            this.props.dispatch(removeLastStop());
            this.props.dispatch(updateTripType("One Way"));
        }
        else {
            payLoad = stops[0];
            this.props.dispatch(addStop(payLoad));
            this.props.dispatch(updateTripType("Round Trip"));
        }
    }

    onButtonClick(e) {
        debugger
        if (this.props.isRental && this.state.package == null) {
            alert("Please choose a package");
        }
        else {
            if (this.state.startDTime.isSame(this.state.endDTime, 'd')) {
                if (Moment(document.getElementById("startTime").value, "hh:mm A").isAfter(Moment(document.getElementById("endTime").value, "hh:mm A"))) {
                    alert("Please Select End Time after Start Time");
                    return;
                }
                else {
                    // this.props.history.push('/cars');
                    this.props.dispatch(updateCarsData([]));
                    this.props.dispatch(setChauffeurDrivenToTrue());
                    this.props.dispatch(setSelfDrivenToFalse());
                }
            }
            else {
                //   this.props.history.push('/cars');
                this.props.dispatch(updateCarsData([]));
                this.props.dispatch(setChauffeurDrivenToTrue());
                this.props.dispatch(setSelfDrivenToFalse());
            }
            let startTime = convertTime12to24(document.getElementById("startTime").value);
            this.props.dispatch(updateStartTime(startTime));
            let endTime = convertTime12to24(document.getElementById("endTime").value);
            this.props.dispatch(updateEndTime(endTime));
            this.props.dispatch(fetchVehiclesForChauffeurDriven());
        }
    }

    render() {
        let startDay= Moment(this.state.startDTime,"DD-MM-YYYY HH:mm:ss").format("DD");
        let endDay= Moment(this.state.endDTime,"DD-MM-YYYY HH:mm:ss").format("DD");
        let startHour = Moment(this.state.startDTime,"DD-MM-YYYY HH:mm:ss").format("HH");
        let minTime = setHours(setMinutes(Moment(this.state.endDTime).toDate(), 0), 0);
        let maxTime = setHours(setMinutes(Moment(this.state.endDTime).toDate(), 30), 23);
        if(Number(startDay) === Number(endDay)){
            minTime = setHours(setMinutes(Moment(this.state.endDTime).toDate(), 0), Number(startHour) + 1)
        }
        return (
            <DateTimeWrapper>
                <DateTimeContainer>
                    <DateTimeLabel>Start</DateTimeLabel>
                    <DatePicker
                        selected={Moment(this.state.startDTime).toDate()}
                        onChange={date => this.handleStartDateChange(date)}
                        selectsStart
                        startDate={Moment(this.state.startDTime).toDate()}
                        endDate={Moment(this.state.endDTime).toDate()}
                        showTimeSelect
                        timeFormat="hh:mm a"
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        // withPortal
                        customInput={<CalendarInput />}
                        tabIndex={1}
                        css={css`${tw`shadow-lg p-2 rounded-lg`}`}
                        calendarContainer={MyContainer}
                    />
                </DateTimeContainer>
                {this.props.ride.stops.length >= 2 && this.props.ride.measureLookupId == '100010001' && !this.props.isRental && !this.props.isAirport &&
                    <DateTimeContainer>
                        <DateTimeLabel>Return</DateTimeLabel>
                        <DatePicker
                            selected={Moment(this.state.endDTime).toDate()}
                            onChange={date => this.handleEndDateChange(date)}
                            selectsEnd
                            startDate={Moment(this.state.startDTime).toDate()}
                            endDate={Moment(this.state.endDTime).toDate()}
                            minDate={Moment(this.state.startDTime).toDate()}
                            showTimeSelect
                            minTime={minTime}
                            maxTime={maxTime}
                            timeFormat="hh:mm a"
                            timeIntervals={30}
                            timeCaption="Time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            // withPortal
                            customInput={<CalendarInput />}
                            tabIndex={1}
                            css={css`${tw`shadow-lg p-2 rounded-lg`}`}
                            calendarContainer={MyContainer}
                        />
                    </DateTimeContainer>}
                <TripWrapper>
                    {this.props.ride.stops.length >= 2 && !this.props.isRental && !this.props.isAirport && <TripContainer>
                        <RadioButton css={css`${this.props.ride.measureLookupId == '100010001' ? tw`bg-purple-500` : tw`bg-transparent`}`} onClick={this.toggleTrip} />
                        <RoundTripLabel>Book for a Round Trip?</RoundTripLabel>
                    </TripContainer>}
                    {this.props.isRental && <TripContainer>
                        <DateTimeLabel>Package</DateTimeLabel>
                        <Select options={packageList} searchable={false} placeholder="Choose package" css={css`${tw`text-base md:text-lg lg:text-lg font-normal bg-white shadow-lg p-2 rounded-lg border-none text-center`}`} style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }} onChange={(val) => {
                            if (val.length > 0) {
                                this.packageChange(val[0].value)
                            }
                        }} />
                    </TripContainer>}
                </TripWrapper>
            </DateTimeWrapper>
        )
    }

}

const mapStateToProps = (state) => ({
    app: state.app,
    ride: state.ride,
    isRental: state.app.isRental,
    isAirport: state.app.isAirport,
})

export default connect(mapStateToProps)(DateTime);

const CalendarInput = ({ value, onClick }) => (
    <div css={css`${tw`shadow-lg p-2 rounded-lg text-center`}`} className="example-custom-input" onClick={onClick}>
      {value}
    </div>
  );

  const MyContainer = ({ className, children }) => (
    <div className={className} style={{ marginLeft: '-35%'}}>
        <div style={{ position: "relative", display: "flex" }}>{children}</div>
    </div>
)