import tw from "tailwind.macro"
import React from "react"
import SEO from "../components/seo"
// import SectionOne from "../components/SectionOne"
import SectionTwo from "../components/SectionTwo"
import SectionThree from "../components/SectionThree"
import RegistrationForm from "../components/RegistrationForm"
import Footer from "../components/Footer"
import Header from "../components/Header"
import { connect } from "react-redux"

const Wrapper = tw.div`
  flex items-center relative justify-center w-full flex-col bg-white
`

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentWillMount() {
    if (typeof window === `undefined`) {
      return;
    }
  }

  componentDidMount() {
    if (typeof window === `undefined`) {
      return;
    }
  }

  scrollToForm = () => {
    let element = window.document.getElementById("Form")
    element.scrollIntoView()
  }

  render() {
    return (
      <Wrapper >
        <SEO title="Home" />
        <Header showLogin />
        {/* <SectionOne /> */}
        <SectionTwo scrollToForm={this.scrollToForm} />
        <SectionThree />
        <RegistrationForm />
        <Footer />
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(Index);